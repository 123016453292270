import React, { FC, useEffect, useState, Fragment } from 'react';
import { Collapse, Divider, message, Typography } from 'antd';
import {
  DynamicData,
  DynamicFieldType,
  DynamicModelType,
  Entity,
  useDynamicModelsLazyQuery,
} from 'graphql/types';
import { DynamicDataParser } from 'utils/dynamicDataParser';
import { OneToMany } from './OneToMany/OneToMany';
import { ManyToMany } from './ManyToMany/ManyToMany';
import { BooleanDynamicComponent } from './BooleanDynamicComponent';
import { StringDynamicComponent } from './StringDynamicComponent';
import { ArrayedDynamicContainer } from './ArrayedDynamicContainer';
import { IMageDynamicComponent } from './IMageDynamicComponent';
import { HTMLDynamicComponent } from './HTMLDynamicComponent';
import s from './styles.module.css';

type Props = {
  entity: Entity;
  dynamicData: Array<DynamicData>;
  update: (d: Array<DynamicData>) => void;
  noBuffer?: boolean;
};

export const DynamicFieldsComponent: FC<Props> = ({
  entity,
  dynamicData,
  update,
  noBuffer,
}) => {
  const [getData, { error, data }] = useDynamicModelsLazyQuery({
    variables: { entity },
    fetchPolicy: 'no-cache',
  });

  const [dynamicDataParser, setDynamicDataParser] =
    useState<DynamicDataParser | null>(null);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data?.dynamicModels) {
      setDynamicDataParser(
        new DynamicDataParser(dynamicData, data!.dynamicModels)
      );
    }
  }, [data, dynamicData]);

  if (!data?.dynamicModels || !data?.dynamicModels.length) {
    return !noBuffer ? <div className={s.buffer} /> : null;
  }
  return (
    <div className="mb-4">
      <Divider />
      <Typography.Title level={5}>Dynamic models</Typography.Title>
      <Collapse>
        {data.dynamicModels.map((model, index, arr) => (
          <Collapse.Panel key={model.id} header={model.title || model.name}>
            {model.type === DynamicModelType.OneToMany && (
              <OneToMany
                model={model}
                dynamicDataParser={dynamicDataParser as DynamicDataParser}
                onUpdate={update}
              />
            )}
            {model.type === DynamicModelType.ManyToMany && (
              <ManyToMany
                model={model}
                dynamicDataParser={dynamicDataParser as DynamicDataParser}
                onUpdate={update}
              />
            )}
            {model.type === DynamicModelType.Inline && (
              <>
                {model.fields
                  .concat()
                  .sort((x, y) => (x.primaryField > y.primaryField ? -1 : 1))
                  .map(field => (
                    <Fragment key={field.id}>
                      {field.name !== '0average' && (
                        <div key={field.id} className={s.field}>
                          {field.isArray && (
                            <ArrayedDynamicContainer
                              dynamicDataParser={dynamicDataParser}
                              dynamicData={dynamicData}
                              onUpdate={update}
                              field={field}
                              model={model}
                            />
                          )}
                          {!field.isArray && (
                            <div className={s.componentWrapper}>
                              {field.type === DynamicFieldType.Boolean && (
                                <BooleanDynamicComponent
                                  model={model}
                                  field={field}
                                  onUpdate={update}
                                  dynamicDataParser={dynamicDataParser}
                                />
                              )}
                              {field.type === DynamicFieldType.Image && (
                                <IMageDynamicComponent
                                  model={model}
                                  field={field}
                                  onUpdate={update}
                                  dynamicDataParser={dynamicDataParser}
                                />
                              )}
                              {field.type === DynamicFieldType.Html && (
                                <HTMLDynamicComponent
                                  model={model}
                                  field={field}
                                  onUpdate={update}
                                  dynamicDataParser={dynamicDataParser}
                                />
                              )}
                              {(field.type === DynamicFieldType.String ||
                                field.type === DynamicFieldType.Number) && (
                                <StringDynamicComponent
                                  model={model}
                                  field={field}
                                  onUpdate={update}
                                  dynamicDataParser={dynamicDataParser}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </Fragment>
                  ))}
              </>
            )}
            {index === arr.length - 1 && (
              <div style={{ paddingBottom: 'var(--space-4)' }} />
            )}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};
