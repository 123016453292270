import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
export type GetAdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdsQuery = (
  { __typename?: 'Query' }
  & { ads: Array<(
    { __typename?: 'Ad' }
    & Pick<Ad, 'id' | 'background' | 'link' | 'align' | 'actionColor'>
  )> }
);

export type CreateAdMutationVariables = Exact<{
  input: AdInput;
}>;


export type CreateAdMutation = (
  { __typename?: 'Mutation' }
  & { createAd: (
    { __typename?: 'Ad' }
    & Pick<Ad, 'id' | 'background' | 'link' | 'align' | 'actionColor'>
  ) }
);

export type UpdateAdMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AdInput;
}>;


export type UpdateAdMutation = (
  { __typename?: 'Mutation' }
  & { updateAd: (
    { __typename?: 'Ad' }
    & Pick<Ad, 'id' | 'background' | 'link' | 'align' | 'actionColor'>
  ) }
);

export type DeleteAdMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAd'>
);

export type AuthorFragmentFragment = (
  { __typename?: 'Author' }
  & Pick<Author, 'id' | 'name' | 'lastName'>
  & { user: (
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'email'>
  ), avatar?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'value' | 'id' | 'size'>
  )>>, dynamic?: Maybe<Array<(
    { __typename?: 'DynamicData' }
    & Pick<DynamicData, 'data'>
    & { model: (
      { __typename?: 'DynamicModel' }
      & Pick<DynamicModel, 'id' | 'name'>
    ) }
  )>>, posts?: Maybe<Array<(
    { __typename?: 'BlogPost' }
    & Pick<BlogPost, 'id' | 'title'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'title' | 'shortDescription'>
    )> }
  )>> }
);

export type GetAuthorListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
}>;


export type GetAuthorListQuery = (
  { __typename?: 'Query' }
  & { authors: (
    { __typename?: 'AuthorsResponse' }
    & Pick<AuthorsResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Author' }
      & AuthorFragmentFragment
    )> }
  ) }
);

export type GetAuthorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAuthorQuery = (
  { __typename?: 'Query' }
  & { author: (
    { __typename?: 'Author' }
    & AuthorFragmentFragment
  ) }
);

export type CreateAuthorMutationVariables = Exact<{
  input: AuthorInput;
}>;


export type CreateAuthorMutation = (
  { __typename?: 'Mutation' }
  & { createAuthor: (
    { __typename?: 'Author' }
    & AuthorFragmentFragment
  ) }
);

export type UpdateAuthorMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AuthorInput;
}>;


export type UpdateAuthorMutation = (
  { __typename?: 'Mutation' }
  & { updateAuthor: (
    { __typename?: 'Author' }
    & AuthorFragmentFragment
  ) }
);

export type DeleteAuthorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAuthorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAuthor'>
);

export type CategoryFragmentSuperLightFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'title' | 'active' | 'sortItemsCountTotal' | 'sortItemsCountVisible'>
  & { skills?: Maybe<Array<(
    { __typename?: 'Skill' }
    & Pick<Skill, 'title' | 'id'>
  )>>, icon?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'size' | 'value'>
  )>> }
);

export type CategoryFragmentLightFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'title' | 'slug' | 'slugCustom' | 'shortDescription' | 'longDescription' | 'color' | 'active' | 'sortItemsCountTotal' | 'sortItemsCountVisible'>
  & { faq?: Maybe<Array<(
    { __typename?: 'CategoryFAQ' }
    & Pick<CategoryFaq, 'id' | 'question' | 'answer'>
  )>>, dynamic?: Maybe<Array<(
    { __typename?: 'DynamicData' }
    & Pick<DynamicData, 'data'>
    & { model: (
      { __typename?: 'DynamicModel' }
      & Pick<DynamicModel, 'id' | 'name'>
    ) }
  )>>, relatedCategories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'title'>
  )>, icon?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'size' | 'value'>
  )>>, image?: Maybe<(
    { __typename?: 'Picture' }
    & ImageFragmentFragment
  )>, seoCustom?: Maybe<(
    { __typename?: 'Seo' }
    & SeoFragmentFragment
  )>, skills?: Maybe<Array<(
    { __typename?: 'Skill' }
    & Pick<Skill, 'id' | 'title'>
  )>>, fontAwesomeIcon?: Maybe<(
    { __typename?: 'FontAwesomeIcon' }
    & Pick<FontAwesomeIcon, 'name' | 'prefix' | 'color'>
  )> }
);

export type CategoryFragmentFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'title' | 'slug' | 'shortDescription' | 'longDescription' | 'color' | 'active' | 'sortItemsCountTotal' | 'sortItemsCountVisible'>
  & { seo?: Maybe<(
    { __typename?: 'Seo' }
    & Pick<Seo, 'image'>
  )>, seoCustom?: Maybe<(
    { __typename?: 'Seo' }
    & SeoFragmentFragment
  )>, dynamic?: Maybe<Array<(
    { __typename?: 'DynamicData' }
    & Pick<DynamicData, 'data'>
    & { model: (
      { __typename?: 'DynamicModel' }
      & Pick<DynamicModel, 'id' | 'name'>
    ) }
  )>>, relatedCategories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'title'>
  )>, icon?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'size' | 'value'>
  )>>, image?: Maybe<(
    { __typename?: 'Picture' }
    & ImageFragmentFragment
  )>, items?: Maybe<Array<(
    { __typename?: 'Item' }
    & ItemFragmentFragment
  )>>, skills?: Maybe<Array<(
    { __typename?: 'Skill' }
    & Pick<Skill, 'id' | 'title'>
  )>>, fontAwesomeIcon?: Maybe<(
    { __typename?: 'FontAwesomeIcon' }
    & Pick<FontAwesomeIcon, 'name' | 'prefix' | 'color'>
  )> }
);

export type SliderCategoriesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type SliderCategoriesQuery = (
  { __typename?: 'Query' }
  & { sliderCategories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'title' | 'slug'>
    & { icon?: Maybe<Array<(
      { __typename?: 'PictureDictionary' }
      & Pick<PictureDictionary, 'id' | 'size' | 'value'>
    )>>, image?: Maybe<(
      { __typename?: 'Picture' }
      & ImageFragmentFragment
    )> }
  )> }
);

export type GetCategoriesSuperLightListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  iconFilter?: Maybe<WithWithout>;
  thumbFilter?: Maybe<WithWithout>;
  sortBy?: Maybe<SortBy>;
}>;


export type GetCategoriesSuperLightListQuery = (
  { __typename?: 'Query' }
  & { categories: (
    { __typename?: 'CategoriesResponse' }
    & Pick<CategoriesResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'slug'>
      & CategoryFragmentSuperLightFragment
    )> }
  ) }
);

export type GetCategoriesListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  iconFilter?: Maybe<WithWithout>;
  thumbFilter?: Maybe<WithWithout>;
  sortBy?: Maybe<SortBy>;
  descriptionFilter?: Maybe<WithWithout>;
  items?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  skills?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  sortByDirection?: Maybe<SortDirection>;
  zeroItems?: Maybe<WithWithout>;
  onlyIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetCategoriesListQuery = (
  { __typename?: 'Query' }
  & { categories: (
    { __typename?: 'CategoriesResponse' }
    & Pick<CategoriesResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Category' }
      & CategoryFragmentFragment
    )> }
  ) }
);

export type GetCurrentCategoryQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
}>;


export type GetCurrentCategoryQuery = (
  { __typename?: 'Query' }
  & { skills: (
    { __typename?: 'SkillsResponse' }
    & { data: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'id' | 'title'>
    )> }
  ), category: (
    { __typename?: 'Category' }
    & { items?: Maybe<Array<(
      { __typename?: 'Item' }
      & ItemFragmentFragment
    )>> }
    & CategoryFragmentLightFragment
  ) }
);

export type CreateCategoryMutationVariables = Exact<{
  input: CategoryInput;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'Category' }
    & CategoryFragmentFragment
  ) }
);

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CategoryInput;
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: (
    { __typename?: 'Category' }
    & CategoryFragmentFragment
  ) }
);

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCategory'>
);

export type SortCategoriesItemsMutationVariables = Exact<{
  id: Scalars['ID'];
  items: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SortCategoriesItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'categorySortItems'>
);

export type SaveSliderCategoriesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SaveSliderCategoriesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sliderCategories'>
);

export type UpscaleRelatedItems350CropTo700MutationVariables = Exact<{
  categoryID: Scalars['ID'];
}>;


export type UpscaleRelatedItems350CropTo700Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upscaleRelatedItems350CropTo700'>
);

export type AddCategoryFaqMutationVariables = Exact<{
  input: CategoryFaqInput;
}>;


export type AddCategoryFaqMutation = (
  { __typename?: 'Mutation' }
  & { addCategoryFAQ: (
    { __typename?: 'CategoryFAQ' }
    & Pick<CategoryFaq, 'id' | 'question' | 'answer'>
  ) }
);

export type UpdateCategoryFaqMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CategoryFaqInput;
}>;


export type UpdateCategoryFaqMutation = (
  { __typename?: 'Mutation' }
  & { updateCategoryFAQ: (
    { __typename?: 'CategoryFAQ' }
    & Pick<CategoryFaq, 'id' | 'question' | 'answer'>
  ) }
);

export type DeleteCategoryFaqMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCategoryFaqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCategoryFAQ'>
);

export type GetCommonShortListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommonShortListQuery = (
  { __typename?: 'Query' }
  & { skills: (
    { __typename?: 'SkillsResponse' }
    & { data: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'id' | 'title'>
    )> }
  ), categories: (
    { __typename?: 'CategoriesResponse' }
    & { data: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'title'>
    )> }
  ), companies: (
    { __typename?: 'CompaniesResponse' }
    & { data: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'title'>
    )> }
  ) }
);

export type CompanyFragmentFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'title' | 'slug'>
  & { icon?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'value' | 'size'>
  )>>, link?: Maybe<(
    { __typename?: 'Link' }
    & Pick<Link, 'url' | 'alternativeUrl' | 'forceNoFollow' | 'forceNoIndex' | 'forceNoOpenner' | 'targetBlank'>
  )>, items?: Maybe<Array<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'title'>
  )>>, seo?: Maybe<(
    { __typename?: 'Seo' }
    & Pick<Seo, 'title' | 'description'>
  )>, social?: Maybe<(
    { __typename?: 'Social' }
    & Pick<Social, 'twitter' | 'facebook'>
  )> }
);

export type GetCompaniesListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  iconFilter?: Maybe<WithWithout>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
}>;


export type GetCompaniesListQuery = (
  { __typename?: 'Query' }
  & { companies: (
    { __typename?: 'CompaniesResponse' }
    & Pick<CompaniesResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'slug' | 'id' | 'title'>
      & { icon?: Maybe<Array<(
        { __typename?: 'PictureDictionary' }
        & Pick<PictureDictionary, 'value'>
      )>>, link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'url'>
      )>, items?: Maybe<Array<(
        { __typename?: 'Item' }
        & Pick<Item, 'id' | 'title'>
      )>>, seo?: Maybe<(
        { __typename?: 'Seo' }
        & Pick<Seo, 'title'>
      )> }
    )> }
  ) }
);

export type GetCompanyQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
}>;


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & CompanyFragmentFragment
  ) }
);

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type UpdateCompanyMutationVariables = Exact<{
  slug: Scalars['String'];
  input: CompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & CompanyFragmentFragment
  ) }
);

export type CreateCompanyMutationVariables = Exact<{
  input: CompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'Company' }
    & CompanyFragmentFragment
  ) }
);

export type GetCsvDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCsvDataQuery = (
  { __typename?: 'Query' }
  & { categories: (
    { __typename?: 'CategoriesResponse' }
    & { data: Array<(
      { __typename?: 'Category' }
      & CategoryFragmentLightFragment
    )> }
  ), items: (
    { __typename?: 'ItemsResponse' }
    & { data: Array<(
      { __typename?: 'Item' }
      & ItemFragmentFragment
    )> }
  ), dynamicModels: Array<(
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'name' | 'id' | 'type'>
    & { fields: Array<(
      { __typename?: 'DynamicFieldModel' }
      & Pick<DynamicFieldModel, 'type' | 'name' | 'id'>
    )>, variants?: Maybe<Array<(
      { __typename?: 'DynamicVariant' }
      & Pick<DynamicVariant, 'id' | 'value'>
    )>> }
  )> }
);

export type DashboardOverviewQueryVariables = Exact<{
  days: Scalars['Int'];
}>;


export type DashboardOverviewQuery = (
  { __typename?: 'Query' }
  & { overview: Array<(
    { __typename?: 'GaDashboard' }
    & Pick<GaDashboard, 'date' | 'sessions' | 'avgSessionDuration' | 'pageviews' | 'bounceRate'>
  )> }
);

export type DashboardGlanceQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
}>;


export type DashboardGlanceQuery = (
  { __typename?: 'Query' }
  & { glance: (
    { __typename?: 'GaDashboard' }
    & Pick<GaDashboard, 'date' | 'sessions' | 'avgSessionDuration' | 'pageviews' | 'bounceRate'>
  ) }
);

export type DashboardReportsQueryVariables = Exact<{
  dateStart: Scalars['Date'];
  dateEnd: Scalars['Date'];
}>;


export type DashboardReportsQuery = (
  { __typename?: 'Query' }
  & { keywords: Array<(
    { __typename?: 'Keyword' }
    & Pick<Keyword, 'title' | 'impression' | 'clicks' | 'position'>
  )>, content: Array<(
    { __typename?: 'Content' }
    & Pick<Content, 'pageName' | 'searchTraffic' | 'impressions' | 'avgCTR' | 'position' | 'pageId' | 'pageType'>
  )>, topPages: Array<(
    { __typename?: 'TopPage' }
    & Pick<TopPage, 'title' | 'visitors'>
  )>, countries: Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'title' | 'searchTraffic' | 'position' | 'impressions' | 'avgCTR'>
  )>, deviceBreakdown: (
    { __typename?: 'DeviceBreakdown' }
    & Pick<DeviceBreakdown, 'desktop' | 'tablet' | 'mobile'>
  ), visitors: (
    { __typename?: 'Visitors' }
    & Pick<Visitors, 'new' | 'returned'>
  ) }
);

export type FeatureFragmentFragment = (
  { __typename?: 'Feature' }
  & Pick<Feature, 'id' | 'title' | 'description'>
  & { icon?: Maybe<Array<(
    { __typename?: 'FeatureIcon' }
    & Pick<FeatureIcon, 'size' | 'url'>
  )>>, fontAwesomeIcon?: Maybe<(
    { __typename?: 'FontAwesomeIcon' }
    & Pick<FontAwesomeIcon, 'name' | 'prefix' | 'color'>
  )> }
);

export type CategoryFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoryFeaturesQuery = (
  { __typename?: 'Query' }
  & { categories: (
    { __typename?: 'CategoriesResponse' }
    & { data: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'title'>
      & { features?: Maybe<Array<(
        { __typename?: 'Feature' }
        & FeatureFragmentFragment
      )>> }
    )> }
  ) }
);

export type AddFeatureMutationVariables = Exact<{
  input: FeatureInput;
}>;


export type AddFeatureMutation = (
  { __typename?: 'Mutation' }
  & { addFeature: (
    { __typename?: 'Feature' }
    & Pick<Feature, 'categoryID'>
    & FeatureFragmentFragment
  ) }
);

export type UpdateFeatureMutationVariables = Exact<{
  id: Scalars['ID'];
  input: FeatureUpdateInput;
}>;


export type UpdateFeatureMutation = (
  { __typename?: 'Mutation' }
  & { updateFeature: (
    { __typename?: 'Feature' }
    & FeatureFragmentFragment
  ) }
);

export type DeleteFeatureMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFeatureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFeature'>
);

export type ImageFragmentFragment = (
  { __typename?: 'Picture' }
  & Pick<Picture, 'id'>
  & { Sizes: Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'size' | 'value' | 'hasWatermark'>
  )>, positions?: Maybe<(
    { __typename?: 'Positions' }
    & Pick<Positions, 'x' | 'y' | 'width' | 'height'>
  )> }
);

export type GetImageOriginUrlQueryVariables = Exact<{
  imageID: Scalars['ID'];
}>;


export type GetImageOriginUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getImageOriginUrl'>
);

export type UploadImageMutationVariables = Exact<{
  input: UploadInput;
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage: (
    { __typename?: 'Picture' }
    & ImageFragmentFragment
  ) }
);

export type UploadCropImageMutationVariables = Exact<{
  input: UploadCropInput;
}>;


export type UploadCropImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadCropImage: (
    { __typename?: 'Picture' }
    & ImageFragmentFragment
  ) }
);

export type ScreenshotCreateMutationVariables = Exact<{
  url: Scalars['String'];
  sizes?: Maybe<Array<Resize> | Resize>;
  fullPage?: Maybe<Scalars['Boolean']>;
}>;


export type ScreenshotCreateMutation = (
  { __typename?: 'Mutation' }
  & { screenshotCreate: (
    { __typename?: 'Picture' }
    & { positions?: Maybe<(
      { __typename?: 'Positions' }
      & Pick<Positions, 'x' | 'y' | 'width' | 'height'>
    )> }
    & ImageFragmentFragment
  ) }
);

export type IconCreateMutationVariables = Exact<{
  url: Scalars['String'];
  sizes: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type IconCreateMutation = (
  { __typename?: 'Mutation' }
  & { iconCreate: Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'size' | 'value'>
  )> }
);

export type RegenerateLikesMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type RegenerateLikesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateCategoryLikes'>
);

export type ItemFragmentFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'id' | 'title' | 'slug' | 'slugCustom' | 'shortDescription' | 'longDescription' | 'free' | 'price' | 'active' | 'iconSetManually' | 'approved' | 'approvedAt' | 'twitter' | 'facebook' | 'youtube' | 'createdAt' | 'creatorID' | 'categoryApproved' | 'username' | 'platform' | 'pros' | 'cons' | 'tags'>
  & { mainCategory?: Maybe<(
    { __typename?: 'mainCategoryItem' }
    & Pick<MainCategoryItem, 'id' | 'title'>
  )>, faq?: Maybe<Array<(
    { __typename?: 'ItemFAQ' }
    & Pick<ItemFaq, 'id' | 'question' | 'answer' | 'itemID'>
  )>>, features?: Maybe<Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'id' | 'title'>
  )>>, promo?: Maybe<(
    { __typename?: 'Promo' }
    & Pick<Promo, 'offer' | 'code' | 'expire'>
  )>, reviews: Array<(
    { __typename?: 'ItemReview' }
    & Pick<ItemReview, 'title' | 'description' | 'authorName' | 'authorAvatar' | 'authorCompany' | 'authorPosition' | 'date' | 'companyLink'>
  )>, thumbnail?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'size' | 'value'>
  )>>, link?: Maybe<(
    { __typename?: 'Link' }
    & Pick<Link, 'url' | 'alternativeUrl' | 'forceNoFollow' | 'forceNoIndex' | 'forceNoOpenner' | 'targetBlank'>
  )>, icon?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'value' | 'size'>
  )>>, images?: Maybe<Array<(
    { __typename?: 'Picture' }
    & ImageFragmentFragment
  )>>, company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'title'>
  )>, skills?: Maybe<Array<(
    { __typename?: 'Skill' }
    & Pick<Skill, 'id' | 'title'>
    & { icon?: Maybe<Array<(
      { __typename?: 'PictureDictionary' }
      & Pick<PictureDictionary, 'size' | 'value'>
    )>> }
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'title' | 'slug'>
    & { features?: Maybe<Array<(
      { __typename?: 'Feature' }
      & Pick<Feature, 'id' | 'title' | 'categoryID'>
    )>> }
  )>>, seoCustom?: Maybe<(
    { __typename?: 'Seo' }
    & SeoFragmentFragment
  )>, dynamic?: Maybe<Array<(
    { __typename?: 'DynamicData' }
    & Pick<DynamicData, 'data' | 'filledData'>
    & { model: (
      { __typename?: 'DynamicModel' }
      & Pick<DynamicModel, 'id' | 'name' | 'type'>
      & { variants?: Maybe<Array<(
        { __typename?: 'DynamicVariant' }
        & Pick<DynamicVariant, 'id' | 'value'>
      )>>, fields: Array<(
        { __typename?: 'DynamicFieldModel' }
        & Pick<DynamicFieldModel, 'id' | 'isArray' | 'name' | 'primaryField' | 'type'>
      )> }
    ) }
  )>> }
);

export type GetItemsSuperLightQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetItemsSuperLightQuery = (
  { __typename?: 'Query' }
  & { items: (
    { __typename?: 'ItemsResponse' }
    & Pick<ItemsResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'title' | 'shortDescription' | 'longDescription' | 'slug' | 'pros' | 'cons'>
      & { seo?: Maybe<(
        { __typename?: 'Seo' }
        & Pick<Seo, 'title' | 'description'>
      )> }
    )> }
  ) }
);

export type GetItemsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
  iconFilter?: Maybe<WithWithout>;
  thumbFilter?: Maybe<WithWithout>;
  descriptionFilter?: Maybe<WithWithout>;
  active?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  skills?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  companies?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  uploadedByUser?: Maybe<Scalars['Boolean']>;
}>;


export type GetItemsQuery = (
  { __typename?: 'Query' }
  & { items: (
    { __typename?: 'ItemsResponse' }
    & Pick<ItemsResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Item' }
      & ItemFragmentFragment
    )> }
  ) }
);

export type GetCurrentItemQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
}>;


export type GetCurrentItemQuery = (
  { __typename?: 'Query' }
  & { item: (
    { __typename?: 'Item' }
    & ItemFragmentFragment
  ) }
);

export type GetDeletedItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeletedItemsQuery = (
  { __typename?: 'Query' }
  & { deletedItems: Array<(
    { __typename?: 'Item' }
    & ItemFragmentFragment
  )> }
);

export type CreateItemMutationVariables = Exact<{
  input: ItemInput;
}>;


export type CreateItemMutation = (
  { __typename?: 'Mutation' }
  & { createItem: (
    { __typename?: 'Item' }
    & ItemFragmentFragment
  ) }
);

export type UpdateItemMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ItemInput;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem: (
    { __typename?: 'Item' }
    & ItemFragmentFragment
  ) }
);

export type DeleteItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteItem'>
);

export type PreviewItemMutationVariables = Exact<{
  input: ItemInput;
}>;


export type PreviewItemMutation = (
  { __typename?: 'Mutation' }
  & { preview?: Maybe<(
    { __typename?: 'ItemPreviewResponse' }
    & Pick<ItemPreviewResponse, 'slug'>
  )> }
);

export type UpdateFaviconsMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateFaviconsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFavicons'>
);

export type RecreateScreenShotsMutationVariables = Exact<{
  categoryID: Scalars['ID'];
  sizes: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RecreateScreenShotsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recreateScreenShots'>
);

export type RestoreItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestoreItemMutation = (
  { __typename?: 'Mutation' }
  & { restoreItem: (
    { __typename?: 'Item' }
    & ItemFragmentFragment
  ) }
);

export type AddItemFaqMutationVariables = Exact<{
  input: ItemFaqInput;
}>;


export type AddItemFaqMutation = (
  { __typename?: 'Mutation' }
  & { addItemFAQ: (
    { __typename?: 'ItemFAQ' }
    & Pick<ItemFaq, 'id' | 'question' | 'answer'>
  ) }
);

export type UpdateItemFaqMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ItemFaqInput;
}>;


export type UpdateItemFaqMutation = (
  { __typename?: 'Mutation' }
  & { updateItemFAQ: (
    { __typename?: 'ItemFAQ' }
    & Pick<ItemFaq, 'id' | 'question' | 'answer'>
  ) }
);

export type DeleteItemFaqMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteItemFaqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteItemFAQ'>
);

export type SubmitToolApproveMutationVariables = Exact<{
  id: Scalars['ID'];
  value: Scalars['Boolean'];
  reasons?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SubmitToolApproveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitToolApprove'>
);

export type SubmitToolCategoryApprovalMutationVariables = Exact<{
  id: Scalars['ID'];
  value: Scalars['Boolean'];
}>;


export type SubmitToolCategoryApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitToolCategoryApproval'>
);

export type GetLabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLabelsQuery = (
  { __typename?: 'Query' }
  & { labels: Array<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name' | 'icon'>
  )> }
);

export type CreateLabelMutationVariables = Exact<{
  input: LabelInput;
}>;


export type CreateLabelMutation = (
  { __typename?: 'Mutation' }
  & { createLabel: (
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name' | 'icon'>
  ) }
);

export type UpdateLabelMutationVariables = Exact<{
  id: Scalars['ID'];
  input: LabelInput;
}>;


export type UpdateLabelMutation = (
  { __typename?: 'Mutation' }
  & { updateLabel: (
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name' | 'icon'>
  ) }
);

export type DeleteLabelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLabelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLabel'>
);

export type ActiveModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveModelsQuery = (
  { __typename?: 'Query' }
  & { activeModels: (
    { __typename?: 'ActiveModels' }
    & Pick<ActiveModels, 'company' | 'item' | 'skill' | 'category' | 'page' | 'sort' | 'blog'>
  ) }
);

export type CompanyFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyFieldsQuery = (
  { __typename?: 'Query' }
  & { companyFields: (
    { __typename?: 'CompanyFields' }
    & Pick<CompanyFields, 'icon' | 'link' | 'social' | 'seo'>
  ) }
);

export type BlogFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type BlogFieldsQuery = (
  { __typename?: 'Query' }
  & { dynamicModels: Array<(
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'name' | 'id'>
  )> }
);

export type ItemFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemFieldsQuery = (
  { __typename?: 'Query' }
  & { itemFields: (
    { __typename?: 'ItemFields' }
    & Pick<ItemFields, 'shortDescription' | 'longDescription' | 'free' | 'icon' | 'images' | 'platform' | 'company' | 'skills' | 'categories' | 'seo' | 'link' | 'price' | 'reviews' | 'tags' | 'prosCons' | 'twitter' | 'facebook' | 'youtube'>
  ), dynamicModels: Array<(
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'name' | 'id' | 'title'>
    & { fields: Array<(
      { __typename?: 'DynamicFieldModel' }
      & Pick<DynamicFieldModel, 'name' | 'type' | 'id'>
    )> }
  )> }
);

export type SkillFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type SkillFieldsQuery = (
  { __typename?: 'Query' }
  & { skillFields: (
    { __typename?: 'SkillFields' }
    & Pick<SkillFields, 'icon'>
  ) }
);

export type PageFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type PageFieldsQuery = (
  { __typename?: 'Query' }
  & { dynamicModels: Array<(
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'name' | 'title' | 'id'>
  )> }
);

export type AuthorFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthorFieldsQuery = (
  { __typename?: 'Query' }
  & { dynamicModels: Array<(
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'name' | 'id'>
  )> }
);

export type CategoryFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoryFieldsQuery = (
  { __typename?: 'Query' }
  & { categoryFields: (
    { __typename?: 'CategoryFields' }
    & Pick<CategoryFields, 'shortDescription' | 'longDescription' | 'icon' | 'image' | 'skills' | 'color' | 'disableChildrenItemsIfCategoryDisabled'>
  ), dynamicModels: Array<(
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'name' | 'title' | 'id'>
  )> }
);

export type WatermarkQueryVariables = Exact<{ [key: string]: never; }>;


export type WatermarkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'watermark'>
);

export type ToggleActiveModelsMutationVariables = Exact<{
  input: InputActiveModels;
}>;


export type ToggleActiveModelsMutation = (
  { __typename?: 'Mutation' }
  & { toggleActiveModels: (
    { __typename?: 'ActiveModels' }
    & Pick<ActiveModels, 'company' | 'item' | 'skill' | 'category' | 'page' | 'sort' | 'blog'>
  ) }
);

export type ToggleCompanyFieldsMutationVariables = Exact<{
  input: InputCompanyFields;
}>;


export type ToggleCompanyFieldsMutation = (
  { __typename?: 'Mutation' }
  & { toggleCompanyFields: (
    { __typename?: 'CompanyFields' }
    & Pick<CompanyFields, 'icon' | 'link' | 'social' | 'seo'>
  ) }
);

export type ToggleAuthorFieldsMutationVariables = Exact<{
  input: InputCompanyFields;
}>;


export type ToggleAuthorFieldsMutation = (
  { __typename?: 'Mutation' }
  & { toggleCompanyFields: (
    { __typename?: 'CompanyFields' }
    & Pick<CompanyFields, 'icon' | 'link' | 'social' | 'seo'>
  ) }
);

export type ToggleItemFieldsMutationVariables = Exact<{
  input: InputItemFields;
}>;


export type ToggleItemFieldsMutation = (
  { __typename?: 'Mutation' }
  & { toggleItemFields: (
    { __typename?: 'ItemFields' }
    & Pick<ItemFields, 'shortDescription' | 'longDescription' | 'free' | 'icon' | 'images' | 'platform' | 'company' | 'seo' | 'skills' | 'categories' | 'link' | 'price' | 'reviews' | 'tags' | 'prosCons'>
  ) }
);

export type ToggleSkillFieldsMutationVariables = Exact<{
  input: InputSkillFields;
}>;


export type ToggleSkillFieldsMutation = (
  { __typename?: 'Mutation' }
  & { toggleSkillFields: (
    { __typename?: 'SkillFields' }
    & Pick<SkillFields, 'icon'>
  ) }
);

export type ToggleCategoryFieldsMutationVariables = Exact<{
  input: InputCategoryFields;
}>;


export type ToggleCategoryFieldsMutation = (
  { __typename?: 'Mutation' }
  & { toggleCategoryFields: (
    { __typename?: 'CategoryFields' }
    & Pick<CategoryFields, 'shortDescription' | 'longDescription' | 'icon' | 'image' | 'skills' | 'color' | 'disableChildrenItemsIfCategoryDisabled'>
  ) }
);

export type UploadWatermarkMutationVariables = Exact<{
  input: Scalars['Upload'];
}>;


export type UploadWatermarkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadWatermark'>
);

export type CreateDynamicModelMutationVariables = Exact<{
  entity: Entity;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: DynamicModelType;
}>;


export type CreateDynamicModelMutation = (
  { __typename?: 'Mutation' }
  & { createDynamicModel: (
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'id' | 'name' | 'type' | 'title'>
    & { fields: Array<(
      { __typename?: 'DynamicFieldModel' }
      & Pick<DynamicFieldModel, 'id' | 'type'>
    )> }
  ) }
);

export type CreateDynamicFieldMutationVariables = Exact<{
  type: DynamicFieldType;
  name: Scalars['String'];
  isArray: Scalars['Boolean'];
  modelID: Scalars['ID'];
  primaryField: Scalars['Boolean'];
}>;


export type CreateDynamicFieldMutation = (
  { __typename?: 'Mutation' }
  & { createDynamicField: (
    { __typename?: 'DynamicFieldModel' }
    & Pick<DynamicFieldModel, 'id' | 'name' | 'type'>
  ) }
);

export type UpdateDynamicFieldMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  type: DynamicFieldType;
  isArray: Scalars['Boolean'];
  primaryField: Scalars['Boolean'];
}>;


export type UpdateDynamicFieldMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicField: (
    { __typename?: 'DynamicFieldModel' }
    & Pick<DynamicFieldModel, 'id' | 'name' | 'type'>
  ) }
);

export type DeleteDynamicFieldMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDynamicFieldMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicField'>
);

export type UpdateDynamicModelMutationVariables = Exact<{
  id: Scalars['ID'];
  entity: Entity;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: DynamicModelType;
}>;


export type UpdateDynamicModelMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicModel: (
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'id' | 'title' | 'name'>
  ) }
);

export type DeleteDynamicModelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDynamicModelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCascadeDynamicModel'>
);

export type CreateDynamicVariantMutationVariables = Exact<{
  modelID: Scalars['ID'];
  value: Scalars['JSON'];
}>;


export type CreateDynamicVariantMutation = (
  { __typename?: 'Mutation' }
  & { createDynamicVariant: (
    { __typename?: 'DynamicVariant' }
    & Pick<DynamicVariant, 'id' | 'value'>
  ) }
);

export type UpdateDynamicVariantMutationVariables = Exact<{
  id: Scalars['ID'];
  value: Scalars['JSON'];
}>;


export type UpdateDynamicVariantMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicVariant: (
    { __typename?: 'DynamicVariant' }
    & Pick<DynamicVariant, 'id' | 'value'>
  ) }
);

export type DeleteDynamicVariantMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDynamicVariantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDynamicVariant'>
);

export type DynamicModelsQueryVariables = Exact<{
  entity?: Maybe<Entity>;
}>;


export type DynamicModelsQuery = (
  { __typename?: 'Query' }
  & { dynamicModels: Array<(
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'id' | 'entity' | 'name' | 'title' | 'type'>
    & { fields: Array<(
      { __typename?: 'DynamicFieldModel' }
      & Pick<DynamicFieldModel, 'id' | 'name' | 'type' | 'isArray' | 'primaryField'>
    )>, variants?: Maybe<Array<(
      { __typename?: 'DynamicVariant' }
      & Pick<DynamicVariant, 'id' | 'value'>
    )>> }
  )> }
);

export type DynamicModelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DynamicModelQuery = (
  { __typename?: 'Query' }
  & { dynamicModel: (
    { __typename?: 'DynamicModel' }
    & Pick<DynamicModel, 'id' | 'name' | 'entity' | 'type' | 'title'>
    & { fields: Array<(
      { __typename?: 'DynamicFieldModel' }
      & Pick<DynamicFieldModel, 'id' | 'name' | 'isArray' | 'type' | 'primaryField'>
    )>, variants?: Maybe<Array<(
      { __typename?: 'DynamicVariant' }
      & Pick<DynamicVariant, 'id' | 'value'>
    )>> }
  ) }
);

export type UpdateDynamicVariantsMutationVariables = Exact<{
  input: Array<DynamicVariantInput> | DynamicVariantInput;
}>;


export type UpdateDynamicVariantsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDynamicVariants'>
);

export type PageFragmentFragment = (
  { __typename?: 'Page' }
  & Pick<Page, 'id' | 'title' | 'slug' | 'content'>
  & { dynamic?: Maybe<Array<(
    { __typename?: 'DynamicData' }
    & Pick<DynamicData, 'data'>
    & { model: (
      { __typename?: 'DynamicModel' }
      & Pick<DynamicModel, 'id' | 'name'>
    ) }
  )>> }
);

export type GetPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPagesQuery = (
  { __typename?: 'Query' }
  & { pages: Array<(
    { __typename?: 'Page' }
    & PageFragmentFragment
  )> }
);

export type GetPageQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
}>;


export type GetPageQuery = (
  { __typename?: 'Query' }
  & { page: (
    { __typename?: 'Page' }
    & PageFragmentFragment
  ) }
);

export type PageCreateMutationVariables = Exact<{
  input: PageInput;
}>;


export type PageCreateMutation = (
  { __typename?: 'Mutation' }
  & { pageCreate: (
    { __typename?: 'Page' }
    & PageFragmentFragment
  ) }
);

export type PageUpdateMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  input: PageInput;
}>;


export type PageUpdateMutation = (
  { __typename?: 'Mutation' }
  & { pageUpdate: (
    { __typename?: 'Page' }
    & PageFragmentFragment
  ) }
);

export type PageDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PageDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pageDelete'>
);

export type BlogPostFragmentFragment = (
  { __typename?: 'BlogPost' }
  & Pick<BlogPost, 'id' | 'date' | 'title' | 'slug' | 'content' | 'shortDescription' | 'active'>
  & { author: (
    { __typename?: 'Author' }
    & Pick<Author, 'id' | 'name'>
  ), thumb?: Maybe<(
    { __typename?: 'Picture' }
    & Pick<Picture, 'id'>
    & { Sizes: Array<(
      { __typename?: 'PictureDictionary' }
      & Pick<PictureDictionary, 'id' | 'size' | 'value'>
    )> }
  )>, comments: Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'text'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    ) }
  )>, categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'title'>
  )>, labels: Array<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name'>
  )>, dynamic?: Maybe<Array<(
    { __typename?: 'DynamicData' }
    & Pick<DynamicData, 'data'>
    & { model: (
      { __typename?: 'DynamicModel' }
      & Pick<DynamicModel, 'id' | 'name'>
    ) }
  )>> }
);

export type GetBlogPostsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  thumbFilter?: Maybe<WithWithout>;
  sortByDirection?: Maybe<SortDirection>;
  active?: Maybe<Scalars['Boolean']>;
}>;


export type GetBlogPostsQuery = (
  { __typename?: 'Query' }
  & { blogPosts: (
    { __typename?: 'BlogPostsResponse' }
    & Pick<BlogPostsResponse, 'count'>
    & { data: Array<(
      { __typename?: 'BlogPost' }
      & BlogPostFragmentFragment
    )> }
  ) }
);

export type GetBlogPostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBlogPostQuery = (
  { __typename?: 'Query' }
  & { blogPost: (
    { __typename?: 'BlogPost' }
    & BlogPostFragmentFragment
  ) }
);

export type CreateBlogPostMutationVariables = Exact<{
  input: BlogPostInput;
}>;


export type CreateBlogPostMutation = (
  { __typename?: 'Mutation' }
  & { createBlogPost: (
    { __typename?: 'BlogPost' }
    & BlogPostFragmentFragment
  ) }
);

export type UpdateBlogPostMutationVariables = Exact<{
  id: Scalars['ID'];
  input: BlogPostInput;
}>;


export type UpdateBlogPostMutation = (
  { __typename?: 'Mutation' }
  & { updateBlogPost: (
    { __typename?: 'BlogPost' }
    & BlogPostFragmentFragment
  ) }
);

export type DeleteBlogPostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBlogPostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBlogPost'>
);

export type CheckRebuildStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckRebuildStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'staticRebuildStatus'>
);

export type GetPipelineDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPipelineDataQuery = (
  { __typename?: 'Query' }
  & { fetchPipelineData?: Maybe<(
    { __typename?: 'PipelineData' }
    & Pick<PipelineData, 'id' | 'status' | 'created_at' | 'updated_at'>
  )> }
);

export type RebuildRunMutationVariables = Exact<{ [key: string]: never; }>;


export type RebuildRunMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'staticRebuildRun'>
);

export type GlobalSearchQueryQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GlobalSearchQueryQuery = (
  { __typename?: 'Query' }
  & { items: (
    { __typename?: 'ItemsResponse' }
    & Pick<ItemsResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'slug' | 'title'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'url' | 'alternativeUrl'>
      )>, icon?: Maybe<Array<(
        { __typename?: 'PictureDictionary' }
        & Pick<PictureDictionary, 'value' | 'id' | 'size'>
      )>> }
    )> }
  ), categories: (
    { __typename?: 'CategoriesResponse' }
    & Pick<CategoriesResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'slug' | 'title'>
      & { icon?: Maybe<Array<(
        { __typename?: 'PictureDictionary' }
        & Pick<PictureDictionary, 'value' | 'id' | 'size'>
      )>> }
    )> }
  ), skills: (
    { __typename?: 'SkillsResponse' }
    & Pick<SkillsResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'id' | 'title'>
      & { icon?: Maybe<Array<(
        { __typename?: 'PictureDictionary' }
        & Pick<PictureDictionary, 'value' | 'id' | 'size'>
      )>> }
    )> }
  ), blogPosts: (
    { __typename?: 'BlogPostsResponse' }
    & Pick<BlogPostsResponse, 'count'>
    & { data: Array<(
      { __typename?: 'BlogPost' }
      & Pick<BlogPost, 'id' | 'slug' | 'active' | 'title'>
      & { thumb?: Maybe<(
        { __typename?: 'Picture' }
        & { Sizes: Array<(
          { __typename?: 'PictureDictionary' }
          & Pick<PictureDictionary, 'id' | 'size' | 'value'>
        )> }
      )> }
    )> }
  ) }
);

export type SeoFragmentFragment = (
  { __typename?: 'Seo' }
  & Pick<Seo, 'title' | 'description' | 'image'>
);

export type SeoQueryVariables = Exact<{ [key: string]: never; }>;


export type SeoQuery = (
  { __typename?: 'Query' }
  & { seo: (
    { __typename?: 'GlobalSeo' }
    & Pick<GlobalSeo, 'image'>
    & { seo: (
      { __typename?: 'Seo' }
      & Pick<Seo, 'title' | 'description'>
    ), templates?: Maybe<(
      { __typename?: 'SeoTemplate' }
      & { item?: Maybe<(
        { __typename?: 'Seo' }
        & Pick<Seo, 'title' | 'description'>
      )>, category?: Maybe<(
        { __typename?: 'Seo' }
        & Pick<Seo, 'title' | 'description'>
      )> }
    )> }
  ) }
);

export type SeoUpdateMutationVariables = Exact<{
  seo: SeoInput;
  image?: Maybe<Scalars['String']>;
  templates?: Maybe<SeoTemplateInput>;
}>;


export type SeoUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'seoUpdate'>
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { defaultSizeSettings: (
    { __typename?: 'DefaultSizesSettings' }
    & Pick<DefaultSizesSettings, 'oneByOneCrop'>
    & { sizes: Array<(
      { __typename?: 'SizesSettings' }
      & Pick<SizesSettings, 'width' | 'aspectRatio' | 'parentType' | 'lock' | 'retina'>
    )> }
  ) }
);

export type SaveSettingsMutationVariables = Exact<{
  input: InputDefaultSizesSettings;
}>;


export type SaveSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultSizeSettings'>
);

export type SkillFragmentFragment = (
  { __typename?: 'Skill' }
  & Pick<Skill, 'id' | 'title'>
  & { fontAwesomeIcon?: Maybe<(
    { __typename?: 'FontAwesomeIcon' }
    & Pick<FontAwesomeIcon, 'name' | 'prefix' | 'color'>
  )>, icon?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'value' | 'size' | 'id'>
  )>> }
);

export type GetSkillListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  iconFilter?: Maybe<WithWithout>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
}>;


export type GetSkillListQuery = (
  { __typename?: 'Query' }
  & { skills: (
    { __typename?: 'SkillsResponse' }
    & Pick<SkillsResponse, 'count'>
    & { data: Array<(
      { __typename?: 'Skill' }
      & SkillFragmentFragment
    )> }
  ) }
);

export type GetSkillQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSkillQuery = (
  { __typename?: 'Query' }
  & { skill: (
    { __typename?: 'Skill' }
    & SkillFragmentFragment
  ) }
);

export type CreateSkillMutationVariables = Exact<{
  input: SkillInput;
}>;


export type CreateSkillMutation = (
  { __typename?: 'Mutation' }
  & { createSkill: (
    { __typename?: 'Skill' }
    & SkillFragmentFragment
  ) }
);

export type UpdateSkillMutationVariables = Exact<{
  id: Scalars['ID'];
  input: SkillInput;
}>;


export type UpdateSkillMutation = (
  { __typename?: 'Mutation' }
  & { updateSkill: (
    { __typename?: 'Skill' }
    & SkillFragmentFragment
  ) }
);

export type DeleteSkillMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSkillMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSkill'>
);

export type GetSortCategoriesSizeQueryVariables = Exact<{
  type: ScreenSizeType;
  onlyIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetSortCategoriesSizeQuery = (
  { __typename?: 'Query' }
  & { screenSizeV5: Array<Maybe<Array<(
    { __typename?: 'Category' }
    & CategoryFragmentFragment
  )>>> }
);

export type UpdateScreenSizeMutationVariables = Exact<{
  type: ScreenSizeType;
  input: Array<Maybe<Array<SortCategoryV4> | SortCategoryV4>> | Maybe<Array<SortCategoryV4> | SortCategoryV4>;
}>;


export type UpdateScreenSizeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateScreenSizeV5'>
);

export type SyncItemsInSortScreenMutationVariables = Exact<{
  type: ScreenSizeType;
}>;


export type SyncItemsInSortScreenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncItemsInSortScreenV2'>
);

export type GetStaffsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type GetStaffsQuery = (
  { __typename?: 'Query' }
  & { staffs: Array<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'email'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'model' | 'actions'>
    )> }
  )> }
);

export type GetStaffQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetStaffQuery = (
  { __typename?: 'Query' }
  & { staff: (
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'email'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'model' | 'actions'>
    )> }
  ) }
);

export type CreateStaffMutationVariables = Exact<{
  input: StaffInput;
}>;


export type CreateStaffMutation = (
  { __typename?: 'Mutation' }
  & { createStaff: (
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'email'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'model' | 'actions'>
    )> }
  ) }
);

export type UpdateStaffMutationVariables = Exact<{
  id: Scalars['ID'];
  input: StaffInput;
}>;


export type UpdateStaffMutation = (
  { __typename?: 'Mutation' }
  & { updateStaff: (
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'email'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'model' | 'actions'>
    )> }
  ) }
);

export type DeleteStaffMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteStaffMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStaff'>
);

export type SetStaffPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type SetStaffPasswordMutation = (
  { __typename?: 'Mutation' }
  & { staffSetPassword: (
    { __typename?: 'AuthResponse' }
    & Pick<AuthResponse, 'token'>
    & { me: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    ) }
  ) }
);

export type SubmittedToolFragment = (
  { __typename?: 'SubmittedTool' }
  & Pick<SubmittedTool, 'id' | 'itemID' | 'title' | 'slug' | 'tags' | 'linkURL' | 'active' | 'status' | 'shortDescription' | 'longDescription' | 'free' | 'press' | 'comment' | 'approved' | 'facebook' | 'twitter' | 'youtube' | 'price' | 'launchTime' | 'approvedAt' | 'isDraft' | 'reviewed' | 'categoryApproved'>
  & { skills?: Maybe<Array<(
    { __typename?: 'Skill' }
    & Pick<Skill, 'id' | 'title'>
    & { icon?: Maybe<Array<(
      { __typename?: 'PictureDictionary' }
      & Pick<PictureDictionary, 'size' | 'value'>
    )>> }
  )>>, features?: Maybe<Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'id' | 'title'>
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'title' | 'slug'>
    & { features?: Maybe<Array<(
      { __typename?: 'Feature' }
      & Pick<Feature, 'id' | 'title' | 'categoryID'>
    )>> }
  )>>, icon?: Maybe<Array<(
    { __typename?: 'PictureDictionary' }
    & Pick<PictureDictionary, 'id' | 'value' | 'size'>
  )>>, images?: Maybe<Array<(
    { __typename?: 'Picture' }
    & ImageFragmentFragment
  )>>, promo?: Maybe<(
    { __typename?: 'Promo' }
    & Pick<Promo, 'offer' | 'code' | 'expire'>
  )>, author: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'title'>
  ) }
);

export type GetSubmittedToolsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetSubmittedToolsQuery = (
  { __typename?: 'Query' }
  & { submittedTools: (
    { __typename?: 'SubmittedToolsPaging' }
    & Pick<SubmittedToolsPaging, 'count'>
    & { data: Array<(
      { __typename?: 'SubmittedTool' }
      & SubmittedToolFragment
    )> }
  ) }
);

export type GetSubmittedToolQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSubmittedToolQuery = (
  { __typename?: 'Query' }
  & { submittedTool: (
    { __typename?: 'SubmittedTool' }
    & SubmittedToolFragment
  ) }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    & { avatar: Array<(
      { __typename?: 'PictureDictionary' }
      & Pick<PictureDictionary, 'size' | 'value' | 'id'>
    )> }
  ) }
);

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { signIn: (
    { __typename?: 'AuthResponse' }
    & Pick<AuthResponse, 'token'>
    & { me: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
    ) }
  ) }
);

export type UpdateProfileMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  avatar?: Maybe<PictureDictionaryInput>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProfile'>
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  ) }
);

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  HTML: any;
  ImageURL: any;
  JSON: any;
  URL: any;
  Upload: any;
};





export type Action = {
  __typename?: 'Action';
  actionType: ActionType;
  createdAt: Scalars['Date'];
  data: Object;
  objectType: ObjectType;
  user: User;
};

export enum ActionName {
  Read = 'Read',
  ReadWrite = 'ReadWrite',
  ReadWriteDelete = 'ReadWriteDelete'
}

export type ActionResponse = {
  __typename?: 'ActionResponse';
  actions?: Maybe<Array<Action>>;
  totalCount: Scalars['Int'];
};

export enum ActionType {
  CommentReply = 'CommentReply',
  Commented = 'Commented',
  Follower = 'Follower',
  Following = 'Following',
  Liked = 'Liked'
}

export type ActiveModels = {
  __typename?: 'ActiveModels';
  author: Scalars['Boolean'];
  blog: Scalars['Boolean'];
  category: Scalars['Boolean'];
  company: Scalars['Boolean'];
  item: Scalars['Boolean'];
  page: Scalars['Boolean'];
  skill: Scalars['Boolean'];
  sort: Scalars['Boolean'];
};

export type Ad = {
  __typename?: 'Ad';
  actionColor: Scalars['String'];
  align: Scalars['String'];
  background: Scalars['String'];
  id: Scalars['ID'];
  link: Scalars['String'];
};

export type AdInput = {
  actionColor: Scalars['String'];
  align: Scalars['String'];
  background: Scalars['String'];
  link: Scalars['String'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  me: User;
  token: Scalars['String'];
};

export type Author = Identifable & {
  __typename?: 'Author';
  avatar?: Maybe<Array<PictureDictionary>>;
  dynamic?: Maybe<Array<DynamicData>>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  posts?: Maybe<Array<BlogPost>>;
  postsCount: Scalars['Int'];
  user: Staff;
};

export type AuthorInput = {
  avatar?: Maybe<Array<PictureDictionaryInput>>;
  dynamic?: Maybe<Array<DynamicDataInput>>;
  lastName: Scalars['String'];
  name: Scalars['String'];
  user: Scalars['ID'];
};

export type AuthorsResponse = Pagination & {
  __typename?: 'AuthorsResponse';
  count: Scalars['Int'];
  data: Array<Author>;
  limit: Scalars['Int'];
};

export type BlogPost = Identifable & Object & {
  __typename?: 'BlogPost';
  active?: Maybe<Scalars['Boolean']>;
  author: Author;
  avatar?: Maybe<Array<PictureDictionary>>;
  categories: Array<Category>;
  comments: Array<Comment>;
  commentsCount: Scalars['Int'];
  content: Scalars['String'];
  date: Scalars['Date'];
  dynamic?: Maybe<Array<DynamicData>>;
  firstName?: Maybe<Scalars['String']>;
  icon?: Maybe<Array<PictureDictionary>>;
  id: Scalars['ID'];
  image?: Maybe<Picture>;
  itemCount?: Maybe<Scalars['Int']>;
  labels: Array<Label>;
  lastName?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Scalars['Int']>;
  relatedItems: Array<Item>;
  relatedPosts?: Maybe<Array<Maybe<BlogPost>>>;
  shortDescription: Scalars['String'];
  slug: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  thumb?: Maybe<Picture>;
  title: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  viewed: Scalars['Int'];
};


export type BlogPostCategoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type BlogPostInput = {
  active?: Maybe<Scalars['Boolean']>;
  categories: Array<Scalars['ID']>;
  content: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
  dynamic?: Maybe<Array<DynamicDataInput>>;
  labels: Array<Scalars['ID']>;
  shortDescription: Scalars['String'];
  slug: Scalars['String'];
  thumb?: Maybe<PictureInput>;
  title: Scalars['String'];
};

export type BlogPostsResponse = Pagination & {
  __typename?: 'BlogPostsResponse';
  count: Scalars['Int'];
  data: Array<BlogPost>;
  limit: Scalars['Int'];
};

export type CategoriesResponse = Pagination & {
  __typename?: 'CategoriesResponse';
  count: Scalars['Int'];
  data: Array<Category>;
  limit: Scalars['Int'];
};

export type Category = Identifable & Object & {
  __typename?: 'Category';
  active?: Maybe<Scalars['Boolean']>;
  avatar: Array<PictureDictionary>;
  blogPosts: BlogPostsResponse;
  color?: Maybe<Scalars['String']>;
  dynamic?: Maybe<Array<DynamicData>>;
  faq?: Maybe<Array<CategoryFaq>>;
  features?: Maybe<Array<Feature>>;
  firstName?: Maybe<Scalars['String']>;
  fontAwesomeIcon?: Maybe<FontAwesomeIcon>;
  icon?: Maybe<Array<PictureDictionary>>;
  id: Scalars['ID'];
  image?: Maybe<Picture>;
  itemCount?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Item>>;
  lastName?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  likes: Scalars['Int'];
  longDescription?: Maybe<Scalars['String']>;
  relatedCategories: Array<Category>;
  relatedCategoriesCount: Scalars['Int'];
  relatedCategoriesToSkills: Array<Category>;
  relatedCategoriesToSkillsCount: Scalars['Int'];
  seo?: Maybe<Seo>;
  seoCustom?: Maybe<Seo>;
  shortDescription?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Skill>>;
  slug: Scalars['String'];
  slugCustom?: Maybe<Scalars['Boolean']>;
  sortItemsCountTotal: Scalars['Int'];
  sortItemsCountVisible: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};


export type CategoryBlogPostsArgs = {
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
};


export type CategoryDynamicArgs = {
  modelNames?: Maybe<Array<Scalars['String']>>;
};


export type CategoryItemsArgs = {
  limit?: Maybe<Scalars['Int']>;
  onlyActive?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
};


export type CategoryRelatedCategoriesArgs = {
  limit?: Scalars['Int'];
  onlyActive?: Maybe<Scalars['Boolean']>;
  page?: Scalars['Int'];
};


export type CategoryRelatedCategoriesToSkillsArgs = {
  limit?: Scalars['Int'];
  onlyActive?: Maybe<Scalars['Boolean']>;
  page?: Scalars['Int'];
};

export type CategoryFaq = {
  __typename?: 'CategoryFAQ';
  answer: Scalars['String'];
  categoryID: Scalars['ID'];
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type CategoryFaqInput = {
  answer: Scalars['String'];
  categoryID: Scalars['ID'];
  question: Scalars['String'];
};

export type CategoryFields = {
  __typename?: 'CategoryFields';
  color: Scalars['Boolean'];
  disableChildrenItemsIfCategoryDisabled: Scalars['Boolean'];
  icon: Scalars['Boolean'];
  image: Scalars['Boolean'];
  longDescription: Scalars['Boolean'];
  shortDescription: Scalars['Boolean'];
  skills: Scalars['Boolean'];
};

export type CategoryInput = {
  active?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  dynamic?: Maybe<Array<DynamicDataInput>>;
  fontAwesomeIcon?: Maybe<FontAwesomeIconInput>;
  icon?: Maybe<Array<PictureDictionaryInput>>;
  image?: Maybe<PictureInput>;
  longDescription?: Maybe<Scalars['String']>;
  seoCustom?: Maybe<SeoInput>;
  shortDescription?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
  slug?: Maybe<Scalars['String']>;
  slugCustom?: Maybe<Scalars['Boolean']>;
  sortItemsCountTotal?: Maybe<Scalars['Int']>;
  sortItemsCountVisible?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export enum CmsModels {
  Author = 'author',
  Blog = 'blog',
  Category = 'category',
  Company = 'company',
  Item = 'item',
  Page = 'page',
  Skill = 'skill',
  Sort = 'sort'
}

export type Comment = Identifable & Object & {
  __typename?: 'Comment';
  active: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['Date']>;
  approvedBy?: Maybe<Scalars['String']>;
  avatar: Array<PictureDictionary>;
  children?: Maybe<Array<Comment>>;
  date: Scalars['Date'];
  firstName?: Maybe<Scalars['String']>;
  icon?: Maybe<Array<PictureDictionary>>;
  id: Scalars['ID'];
  image?: Maybe<Picture>;
  itemCount?: Maybe<Scalars['Int']>;
  itsMe: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  liked: Scalars['Boolean'];
  likes: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  user: User;
  userEmail?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type CommentInput = {
  parent: Scalars['ID'];
  parentType: ParentType;
  text: Scalars['String'];
  userEmail?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type CommentsResponse = Pagination & {
  __typename?: 'CommentsResponse';
  count: Scalars['Int'];
  data: Array<Comment>;
  limit: Scalars['Int'];
};

export type CompaniesResponse = Pagination & {
  __typename?: 'CompaniesResponse';
  count: Scalars['Int'];
  data: Array<Company>;
  limit: Scalars['Int'];
};

export type Company = Identifable & {
  __typename?: 'Company';
  icon?: Maybe<Array<PictureDictionary>>;
  id: Scalars['ID'];
  items?: Maybe<Array<Item>>;
  link?: Maybe<Link>;
  seo?: Maybe<Seo>;
  slug: Scalars['String'];
  social?: Maybe<Social>;
  title: Scalars['String'];
};

export type CompanyFields = {
  __typename?: 'CompanyFields';
  icon: Scalars['Boolean'];
  link: Scalars['Boolean'];
  seo: Scalars['Boolean'];
  social: Scalars['Boolean'];
};

export type CompanyInput = {
  icon?: Maybe<Array<PictureDictionaryInput>>;
  link?: Maybe<LinkInput>;
  seo?: Maybe<SeoInput>;
  social?: Maybe<SocialInput>;
  title: Scalars['String'];
};

export type ContactUs = {
  __typename?: 'ContactUs';
  ID: Scalars['ID'];
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  topic: Scalars['String'];
};

export type ContactUsInput = {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  topic: Scalars['String'];
};

export type Content = {
  __typename?: 'Content';
  avgCTR: Scalars['Float'];
  impressions: Scalars['Int'];
  pageId: Scalars['ID'];
  pageName: Scalars['String'];
  pageType: Scalars['String'];
  position: Scalars['Float'];
  searchTraffic: Scalars['Int'];
};

export type Country = {
  __typename?: 'Country';
  avgCTR: Scalars['Float'];
  impressions: Scalars['Int'];
  position: Scalars['Float'];
  searchTraffic: Scalars['Int'];
  title: Scalars['String'];
};


export type DefaultSizesSettings = {
  __typename?: 'DefaultSizesSettings';
  oneByOneCrop: Scalars['Boolean'];
  sizes: Array<SizesSettings>;
};

export type DeviceBreakdown = {
  __typename?: 'DeviceBreakdown';
  desktop: Scalars['Int'];
  mobile: Scalars['Int'];
  tablet: Scalars['Int'];
};

export type DynamicData = {
  __typename?: 'DynamicData';
  data?: Maybe<Scalars['JSON']>;
  filledData?: Maybe<Scalars['JSON']>;
  model: DynamicModel;
};

export type DynamicDataInput = {
  data?: Maybe<Scalars['JSON']>;
  modelID: Scalars['ID'];
};

export type DynamicFieldModel = {
  __typename?: 'DynamicFieldModel';
  id: Scalars['ID'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  primaryField: Scalars['Boolean'];
  type: DynamicFieldType;
};

export enum DynamicFieldType {
  Boolean = 'Boolean',
  Html = 'HTML',
  Image = 'Image',
  Number = 'Number',
  String = 'String'
}

export type DynamicFilter = {
  ModelName: Scalars['String'];
  VariantID: Scalars['ID'];
};

export type DynamicModel = {
  __typename?: 'DynamicModel';
  entity: Entity;
  fields: Array<DynamicFieldModel>;
  id: Scalars['ID'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: DynamicModelType;
  variants?: Maybe<Array<DynamicVariant>>;
};

export enum DynamicModelType {
  Inline = 'Inline',
  ManyToMany = 'ManyToMany',
  OneToMany = 'OneToMany'
}

export type DynamicSortingInput = {
  Direction: SortDirection;
  FieldName: Scalars['String'];
  ModelName: Scalars['String'];
};

export type DynamicVariant = {
  __typename?: 'DynamicVariant';
  id: Scalars['ID'];
  value: Scalars['JSON'];
};

export type DynamicVariantInput = {
  id: Scalars['ID'];
  value: Scalars['JSON'];
};

export enum Entity {
  Author = 'Author',
  Blog = 'Blog',
  Category = 'Category',
  Item = 'Item',
  Pages = 'Pages'
}

export enum EventType {
  Like = 'Like'
}

export type Feature = {
  __typename?: 'Feature';
  categoryID: Scalars['ID'];
  description: Scalars['String'];
  fontAwesomeIcon?: Maybe<FontAwesomeIcon>;
  icon?: Maybe<Array<FeatureIcon>>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type FeatureIcon = {
  __typename?: 'FeatureIcon';
  size: Scalars['Int'];
  url: Scalars['ImageURL'];
};

export type FeatureIconInput = {
  size: Scalars['Int'];
  url: Scalars['ImageURL'];
};

export type FeatureInput = {
  categoryID: Scalars['ID'];
  description: Scalars['String'];
  fontAwesomeIcon?: Maybe<FontAwesomeIconInput>;
  icon: Array<FeatureIconInput>;
  title: Scalars['String'];
};

export type FeatureUpdateInput = {
  description: Scalars['String'];
  fontAwesomeIcon?: Maybe<FontAwesomeIconInput>;
  icon: Array<FeatureIconInput>;
  title: Scalars['String'];
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['ID'];
  images?: Maybe<Array<PictureDictionary>>;
  itemID: Scalars['ID'];
  message: Scalars['String'];
  rate: Scalars['Int'];
};

export type FeedbackInput = {
  images?: Maybe<Array<PictureDictionaryInput>>;
  itemID: Scalars['ID'];
  message: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  link: Scalars['String'];
};

export type FontAwesomeIcon = {
  __typename?: 'FontAwesomeIcon';
  color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type FontAwesomeIconInput = {
  color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type GaDashboard = {
  __typename?: 'GaDashboard';
  avgSessionDuration: Scalars['Float'];
  bounceRate: Scalars['Float'];
  date: Scalars['Date'];
  pageviews: Scalars['Int'];
  sessions: Scalars['Int'];
};

export type GlobalSeo = {
  __typename?: 'GlobalSeo';
  image?: Maybe<Scalars['String']>;
  seo: Seo;
  templates?: Maybe<SeoTemplate>;
};


export type Identifable = {
  id: Scalars['ID'];
};


export type InputActiveModels = {
  author: Scalars['Boolean'];
  blog: Scalars['Boolean'];
  category: Scalars['Boolean'];
  company: Scalars['Boolean'];
  item: Scalars['Boolean'];
  page: Scalars['Boolean'];
  skill: Scalars['Boolean'];
  sort: Scalars['Boolean'];
};

export type InputCategoryFields = {
  color: Scalars['Boolean'];
  disableChildrenItemsIfCategoryDisabled: Scalars['Boolean'];
  icon: Scalars['Boolean'];
  image: Scalars['Boolean'];
  longDescription: Scalars['Boolean'];
  shortDescription: Scalars['Boolean'];
  skills: Scalars['Boolean'];
};

export type InputCompanyFields = {
  icon: Scalars['Boolean'];
  link: Scalars['Boolean'];
  seo: Scalars['Boolean'];
  social: Scalars['Boolean'];
};

export type InputDefaultSizesSettings = {
  oneByOneCrop: Scalars['Boolean'];
  sizes: Array<InputSizesSettings>;
};

export type InputItemFields = {
  categories: Scalars['Boolean'];
  company: Scalars['Boolean'];
  facebook: Scalars['Boolean'];
  free: Scalars['Boolean'];
  icon: Scalars['Boolean'];
  images: Scalars['Boolean'];
  link: Scalars['Boolean'];
  longDescription: Scalars['Boolean'];
  platform: Scalars['Boolean'];
  price: Scalars['Boolean'];
  prosCons: Scalars['Boolean'];
  reviews: Scalars['Boolean'];
  seo: Scalars['Boolean'];
  shortDescription: Scalars['Boolean'];
  skills: Scalars['Boolean'];
  tags: Scalars['Boolean'];
  twitter: Scalars['Boolean'];
  youtube: Scalars['Boolean'];
};

export type InputSizesSettings = {
  aspectRatio: Scalars['Float'];
  lock?: Maybe<Scalars['Boolean']>;
  parentType: ParentType;
  retina?: Maybe<Scalars['Boolean']>;
  width: Scalars['Int'];
};

export type InputSkillFields = {
  icon: Scalars['Boolean'];
};

export type Item = Identifable & Object & {
  __typename?: 'Item';
  active?: Maybe<Scalars['Boolean']>;
  alternativeItems: ItemsResponse;
  approved: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['Date']>;
  author?: Maybe<User>;
  avatar: Array<PictureDictionary>;
  categories?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  categoryApproved?: Maybe<Scalars['Boolean']>;
  comments: CommentsResponse;
  company?: Maybe<Company>;
  cons?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  creatorID: Scalars['ID'];
  dynamic?: Maybe<Array<DynamicData>>;
  facebook?: Maybe<Scalars['String']>;
  faq?: Maybe<Array<ItemFaq>>;
  features?: Maybe<Array<Feature>>;
  firstName?: Maybe<Scalars['String']>;
  free?: Maybe<Array<ItemFree>>;
  icon?: Maybe<Array<PictureDictionary>>;
  iconSetManually: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<Picture>;
  images?: Maybe<Array<Picture>>;
  itemCount?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  likedBy: Array<User>;
  likes: Scalars['Int'];
  link?: Maybe<Link>;
  longDescription?: Maybe<Scalars['HTML']>;
  mainCategory?: Maybe<MainCategoryItem>;
  makers?: Maybe<Array<User>>;
  numberOfRate?: Maybe<Scalars['Int']>;
  platform?: Maybe<Array<Platform>>;
  press?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  promo?: Maybe<Promo>;
  pros?: Maybe<Array<Scalars['String']>>;
  relatedBlogPosts: BlogPostsResponse;
  reviews: Array<ItemReview>;
  seo?: Maybe<Seo>;
  seoCustom?: Maybe<Seo>;
  shareCount: Scalars['Int'];
  sharedBy: Array<User>;
  shortDescription?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Skill>>;
  slug: Scalars['String'];
  slugCustom?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  text?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Array<PictureDictionary>>;
  title: Scalars['String'];
  twitter?: Maybe<Scalars['String']>;
  uploadedByUser?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};


export type ItemAlternativeItemsArgs = {
  limit?: Maybe<Scalars['Int']>;
  onlyActive?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
};


export type ItemCategoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortBy>;
};


export type ItemCommentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type ItemDynamicArgs = {
  modelNames?: Maybe<Array<Scalars['String']>>;
};


export type ItemImagesArgs = {
  size?: Maybe<Scalars['Int']>;
};


export type ItemLikedByArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type ItemRelatedBlogPostsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortBy>;
};


export type ItemSharedByArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type ItemFaq = {
  __typename?: 'ItemFAQ';
  answer: Scalars['String'];
  id: Scalars['ID'];
  itemID: Scalars['ID'];
  question: Scalars['String'];
};

export type ItemFaqInput = {
  ItemID: Scalars['ID'];
  answer: Scalars['String'];
  question: Scalars['String'];
};

export type ItemFields = {
  __typename?: 'ItemFields';
  categories: Scalars['Boolean'];
  company: Scalars['Boolean'];
  facebook: Scalars['Boolean'];
  free: Scalars['Boolean'];
  icon: Scalars['Boolean'];
  images: Scalars['Boolean'];
  link: Scalars['Boolean'];
  longDescription: Scalars['Boolean'];
  platform: Scalars['Boolean'];
  price: Scalars['Boolean'];
  prosCons: Scalars['Boolean'];
  reviews: Scalars['Boolean'];
  seo: Scalars['Boolean'];
  shortDescription: Scalars['Boolean'];
  skills: Scalars['Boolean'];
  tags: Scalars['Boolean'];
  twitter: Scalars['Boolean'];
  youtube: Scalars['Boolean'];
};

export enum ItemFree {
  Free = 'Free',
  Paid = 'Paid',
  Trial = 'Trial'
}

export type ItemFreeCount = {
  __typename?: 'ItemFreeCount';
  categoriesCount: Scalars['Int'];
  title: Scalars['String'];
};

export type ItemInput = {
  active?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Scalars['ID']>>;
  company?: Maybe<Scalars['ID']>;
  cons?: Maybe<Array<Scalars['String']>>;
  dynamic?: Maybe<Array<DynamicDataInput>>;
  facebook?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Scalars['ID']>>;
  free?: Maybe<Array<ItemFree>>;
  icon?: Maybe<Array<PictureDictionaryInput>>;
  iconSetManually?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<PictureInput>>;
  link?: Maybe<LinkInput>;
  longDescription?: Maybe<Scalars['HTML']>;
  mainCategory?: Maybe<MainCategoryItemInput>;
  platform?: Maybe<Array<Platform>>;
  price?: Maybe<Scalars['Float']>;
  pros?: Maybe<Array<Scalars['String']>>;
  reviews?: Maybe<Array<ItemReviewInput>>;
  seoCustom?: Maybe<SeoInput>;
  shortDescription?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
  slug?: Maybe<Scalars['String']>;
  slugCustom?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  thumbnail?: Maybe<Array<PictureDictionaryInput>>;
  title: Scalars['String'];
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

export type ItemPreviewResponse = {
  __typename?: 'ItemPreviewResponse';
  slug: Scalars['String'];
};

export type ItemReview = {
  __typename?: 'ItemReview';
  authorAvatar: Scalars['String'];
  authorCompany?: Maybe<Scalars['String']>;
  authorName: Scalars['String'];
  authorPosition?: Maybe<Scalars['String']>;
  companyLink?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  description: Scalars['String'];
  title: Scalars['String'];
};

export type ItemReviewInput = {
  authorAvatar: Scalars['String'];
  authorCompany?: Maybe<Scalars['String']>;
  authorName: Scalars['String'];
  authorPosition?: Maybe<Scalars['String']>;
  companyLink?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  description: Scalars['String'];
  title: Scalars['String'];
};

export type ItemsFavsResponse = {
  __typename?: 'ItemsFavsResponse';
  category: Category;
  countFavs: Scalars['Int'];
  countTotal: Scalars['Int'];
  items?: Maybe<Array<Item>>;
};

export type ItemsResponse = Pagination & {
  __typename?: 'ItemsResponse';
  count: Scalars['Int'];
  data: Array<Item>;
  limit: Scalars['Int'];
};


export type Keyword = {
  __typename?: 'Keyword';
  clicks: Scalars['Int'];
  impression: Scalars['Int'];
  position: Scalars['Float'];
  title: Scalars['String'];
};

export type Label = {
  __typename?: 'Label';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LabelInput = {
  icon?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  alternativeUrl?: Maybe<Scalars['String']>;
  forceNoFollow?: Maybe<Scalars['Boolean']>;
  forceNoIndex?: Maybe<Scalars['Boolean']>;
  forceNoOpenner?: Maybe<Scalars['Boolean']>;
  targetBlank?: Maybe<Scalars['Boolean']>;
  url: Scalars['URL'];
};

export type LinkInput = {
  alternativeUrl?: Maybe<Scalars['String']>;
  forceNoFollow?: Maybe<Scalars['Boolean']>;
  forceNoIndex?: Maybe<Scalars['Boolean']>;
  forceNoOpenner?: Maybe<Scalars['Boolean']>;
  targetBlank?: Maybe<Scalars['Boolean']>;
  url: Scalars['URL'];
};

export enum ModelName {
  Author = 'Author',
  Blog = 'Blog',
  Category = 'Category',
  CmsSetting = 'CmsSetting',
  Comment = 'Comment',
  Company = 'Company',
  Item = 'Item',
  Skill = 'Skill',
  Staff = 'Staff'
}

export type Mutation = {
  __typename?: 'Mutation';
  activeCategory: Scalars['Boolean'];
  activeItem: Scalars['Boolean'];
  addCategoryFAQ: CategoryFaq;
  addFeature: Feature;
  addFeedback: Feedback;
  addItemFAQ: ItemFaq;
  addQuestion?: Maybe<Question>;
  categorySortItems: Scalars['Boolean'];
  commentApprove: Comment;
  commentCreate: Comment;
  commentDelete: Comment;
  commentDisapprove: Comment;
  contactUs: Scalars['Boolean'];
  createAd: Ad;
  createAuthor: Author;
  createBlogPost: BlogPost;
  createCategory: Category;
  createCompany: Company;
  createDynamicField: DynamicFieldModel;
  createDynamicModel: DynamicModel;
  createDynamicVariant: DynamicVariant;
  createItem: Item;
  createLabel: Label;
  createSkill: Skill;
  createStaff: Staff;
  createUser: User;
  deleteAd: Scalars['Boolean'];
  deleteAuthor: Scalars['ID'];
  deleteBlogPost: Scalars['ID'];
  deleteCascadeDynamicModel: Scalars['Boolean'];
  deleteCategory: Scalars['ID'];
  deleteCategoryFAQ: Scalars['ID'];
  deleteCompany: Scalars['ID'];
  deleteDynamicField: Scalars['Boolean'];
  deleteDynamicModel: Scalars['Boolean'];
  deleteDynamicVariant: Scalars['Boolean'];
  deleteFeature: Scalars['ID'];
  deleteItem: Scalars['ID'];
  deleteItemFAQ: Scalars['ID'];
  deleteLabel: Scalars['Boolean'];
  deleteSize?: Maybe<Scalars['Boolean']>;
  deleteSkill: Scalars['ID'];
  deleteStaff: Scalars['ID'];
  emailConfirmation: AuthResponse;
  generateCategoryLikes: Scalars['Boolean'];
  generateUsers: Scalars['Boolean'];
  iconCreate: Array<PictureDictionary>;
  initEmailConfirmation: Scalars['Boolean'];
  initOAuthLogin: OAuthLoginResponse;
  myCommentDelete: Comment;
  newSize: SortCategories;
  notificationEmail: Scalars['Boolean'];
  oauthCallback?: Maybe<AuthResponse>;
  pageCreate: Page;
  pageDelete: Scalars['ID'];
  pageUpdate: Page;
  patchProfile: Scalars['Boolean'];
  preview?: Maybe<ItemPreviewResponse>;
  /** Put the token received by email to apply a new password */
  recoverPasswordApply: Scalars['Boolean'];
  /** Sends an email with token */
  recoverPasswordInit: Scalars['Boolean'];
  recreateScreenShots: Scalars['Boolean'];
  restoreItem: Item;
  screenshotCreate: Picture;
  seoUpdate: Scalars['Boolean'];
  setDefaultSizeSettings: Scalars['Boolean'];
  setFavouriteCategoriesOrder: Scalars['Boolean'];
  setFeedbackRateExperience: Scalars['ID'];
  setLike: Scalars['Int'];
  share: Scalars['Int'];
  signIn: AuthResponse;
  signUp: AuthResponse;
  sliderCategories: Scalars['Boolean'];
  staffSetPassword: AuthResponse;
  staticRebuildRun: Scalars['Boolean'];
  submitToolApprove: Scalars['Boolean'];
  submitToolCategoryApproval: Scalars['Boolean'];
  submitToolExtra: SubmittedTool;
  submitToolLaunch: Scalars['Boolean'];
  submitToolMainInfo: SubmittedTool;
  submitToolMedia: SubmittedTool;
  submitToolUpdateMainInfo: SubmittedTool;
  subscribe: Scalars['Boolean'];
  syncItemsInSortScreen: Scalars['Boolean'];
  syncItemsInSortScreenV2: Scalars['Boolean'];
  toggleActiveModels: ActiveModels;
  toggleCategoryFields: CategoryFields;
  toggleCompanyFields: CompanyFields;
  toggleItemFields: ItemFields;
  toggleSkillFields: SkillFields;
  unsubscribe: Scalars['Boolean'];
  updateAd: Ad;
  updateAuthor: Author;
  updateBlogPost: BlogPost;
  updateCategory: Category;
  updateCategoryFAQ: CategoryFaq;
  updateCompany: Company;
  updateDynamicField: DynamicFieldModel;
  updateDynamicModel: DynamicModel;
  updateDynamicVariant: DynamicVariant;
  updateDynamicVariants: Scalars['Boolean'];
  updateFavicons?: Maybe<Array<Scalars['String']>>;
  updateFaviconsOnlyEmpty?: Maybe<Array<Scalars['String']>>;
  updateFeature: Feature;
  updateItem: Item;
  updateItemFAQ: ItemFaq;
  updateLabel: Label;
  updateProfile: Scalars['Boolean'];
  updateScreenSizeV4: SortScreenSizeV4;
  updateScreenSizeV5: Scalars['Boolean'];
  updateSkill: Skill;
  updateStaff: Staff;
  updateStaffPermissions: Staff;
  updateWatermarkForImages: Scalars['Boolean'];
  uploadCropImage: Picture;
  uploadImage: Picture;
  uploadWatermark: Scalars['String'];
  upscaleRelatedItems350CropTo700: Scalars['Int'];
  verifyEmailConfirmation: Scalars['Boolean'];
  webPush: Scalars['Boolean'];
};


export type MutationActiveCategoryArgs = {
  active: Scalars['Boolean'];
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationActiveItemArgs = {
  active: Scalars['Boolean'];
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationAddCategoryFaqArgs = {
  input: CategoryFaqInput;
};


export type MutationAddFeatureArgs = {
  input: FeatureInput;
};


export type MutationAddFeedbackArgs = {
  input: FeedbackInput;
};


export type MutationAddItemFaqArgs = {
  input: ItemFaqInput;
};


export type MutationAddQuestionArgs = {
  input?: Maybe<QuestionInput>;
};


export type MutationCategorySortItemsArgs = {
  id: Scalars['ID'];
  items: Array<Scalars['ID']>;
};


export type MutationCommentApproveArgs = {
  id: Scalars['ID'];
};


export type MutationCommentCreateArgs = {
  input?: Maybe<CommentInput>;
};


export type MutationCommentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCommentDisapproveArgs = {
  id: Scalars['ID'];
};


export type MutationContactUsArgs = {
  input: ContactUsInput;
};


export type MutationCreateAdArgs = {
  input: AdInput;
};


export type MutationCreateAuthorArgs = {
  input: AuthorInput;
};


export type MutationCreateBlogPostArgs = {
  input: BlogPostInput;
};


export type MutationCreateCategoryArgs = {
  input: CategoryInput;
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationCreateDynamicFieldArgs = {
  isArray: Scalars['Boolean'];
  modelID: Scalars['ID'];
  name: Scalars['String'];
  primaryField: Scalars['Boolean'];
  type: DynamicFieldType;
};


export type MutationCreateDynamicModelArgs = {
  entity: Entity;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: DynamicModelType;
};


export type MutationCreateDynamicVariantArgs = {
  modelID: Scalars['ID'];
  value: Scalars['JSON'];
};


export type MutationCreateItemArgs = {
  input: ItemInput;
};


export type MutationCreateLabelArgs = {
  input: LabelInput;
};


export type MutationCreateSkillArgs = {
  input: SkillInput;
};


export type MutationCreateStaffArgs = {
  input: StaffInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationDeleteAdArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAuthorArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBlogPostArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCascadeDynamicModelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryFaqArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDynamicFieldArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDynamicModelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDynamicVariantArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFeatureArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteItemFaqArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLabelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSizeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSkillArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStaffArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  change: Scalars['Boolean'];
  guid: Scalars['String'];
};


export type MutationGenerateCategoryLikesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationGenerateUsersArgs = {
  count: Scalars['Int'];
};


export type MutationIconCreateArgs = {
  sizes: Array<Scalars['Int']>;
  url: Scalars['String'];
};


export type MutationInitEmailConfirmationArgs = {
  email: Scalars['String'];
};


export type MutationInitOAuthLoginArgs = {
  oauthType: OAuthType;
};


export type MutationMyCommentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationNewSizeArgs = {
  column: Scalars['Int'];
  size: Scalars['Int'];
};


export type MutationOauthCallbackArgs = {
  code: Scalars['String'];
  oauthType: OAuthType;
  stateCookie: Scalars['String'];
  stateForm: Scalars['String'];
};


export type MutationPageCreateArgs = {
  input: PageInput;
};


export type MutationPageDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPageUpdateArgs = {
  id?: Maybe<Scalars['ID']>;
  input: PageInput;
  slug?: Maybe<Scalars['String']>;
};


export type MutationPatchProfileArgs = {
  avatar?: Maybe<PictureDictionaryInput>;
  email?: Maybe<Scalars['String']>;
  emailConfirmCode?: Maybe<Scalars['String']>;
  emailSubscriptionEnabled?: Maybe<Scalars['Boolean']>;
  facebook?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
  slug?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type MutationPreviewArgs = {
  input: ItemInput;
};


export type MutationRecoverPasswordApplyArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRecoverPasswordInitArgs = {
  email: Scalars['String'];
};


export type MutationRecreateScreenShotsArgs = {
  categoryID: Scalars['ID'];
  sizes: Array<Scalars['Int']>;
};


export type MutationRestoreItemArgs = {
  id: Scalars['ID'];
};


export type MutationScreenshotCreateArgs = {
  fullPage?: Maybe<Scalars['Boolean']>;
  sizes?: Maybe<Array<Resize>>;
  url: Scalars['String'];
};


export type MutationSeoUpdateArgs = {
  image?: Maybe<Scalars['String']>;
  seo: SeoInput;
  templates?: Maybe<SeoTemplateInput>;
};


export type MutationSetDefaultSizeSettingsArgs = {
  input: InputDefaultSizesSettings;
};


export type MutationSetFavouriteCategoriesOrderArgs = {
  input: Array<Scalars['ID']>;
};


export type MutationSetFeedbackRateExperienceArgs = {
  id: Scalars['ID'];
  rate: Scalars['Int'];
};


export type MutationSetLikeArgs = {
  id: Scalars['ID'];
  parentType: ParentType;
};


export type MutationShareArgs = {
  id: Scalars['ID'];
  shareType: ShareType;
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSliderCategoriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationStaffSetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSubmitToolApproveArgs = {
  id: Scalars['ID'];
  reasons?: Maybe<Array<Scalars['String']>>;
  value: Scalars['Boolean'];
};


export type MutationSubmitToolCategoryApprovalArgs = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationSubmitToolExtraArgs = {
  id: Scalars['ID'];
  input: SubmitToolExtrasInput;
};


export type MutationSubmitToolLaunchArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitToolMainInfoArgs = {
  input: SubmitToolMainInfoInput;
};


export type MutationSubmitToolMediaArgs = {
  id: Scalars['ID'];
  input: SubmitToolMediaInput;
};


export type MutationSubmitToolUpdateMainInfoArgs = {
  id: Scalars['ID'];
  input: SubmitToolMainInfoInput;
};


export type MutationSubscribeArgs = {
  user: Scalars['ID'];
};


export type MutationSyncItemsInSortScreenArgs = {
  sortScreenID: Scalars['ID'];
};


export type MutationSyncItemsInSortScreenV2Args = {
  type: ScreenSizeType;
};


export type MutationToggleActiveModelsArgs = {
  input: InputActiveModels;
};


export type MutationToggleCategoryFieldsArgs = {
  input: InputCategoryFields;
};


export type MutationToggleCompanyFieldsArgs = {
  input: InputCompanyFields;
};


export type MutationToggleItemFieldsArgs = {
  input: InputItemFields;
};


export type MutationToggleSkillFieldsArgs = {
  input: InputSkillFields;
};


export type MutationUnsubscribeArgs = {
  user: Scalars['ID'];
};


export type MutationUpdateAdArgs = {
  id: Scalars['ID'];
  input: AdInput;
};


export type MutationUpdateAuthorArgs = {
  id: Scalars['ID'];
  input: AuthorInput;
};


export type MutationUpdateBlogPostArgs = {
  id: Scalars['ID'];
  input: BlogPostInput;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  input: CategoryInput;
};


export type MutationUpdateCategoryFaqArgs = {
  id: Scalars['ID'];
  input: CategoryFaqInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
  slug: Scalars['String'];
};


export type MutationUpdateDynamicFieldArgs = {
  id: Scalars['ID'];
  isArray: Scalars['Boolean'];
  name: Scalars['String'];
  primaryField: Scalars['Boolean'];
  type: DynamicFieldType;
};


export type MutationUpdateDynamicModelArgs = {
  entity: Entity;
  id: Scalars['ID'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: DynamicModelType;
};


export type MutationUpdateDynamicVariantArgs = {
  id: Scalars['ID'];
  value: Scalars['JSON'];
};


export type MutationUpdateDynamicVariantsArgs = {
  updateArr?: Maybe<Array<DynamicVariantInput>>;
};


export type MutationUpdateFaviconsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationUpdateFeatureArgs = {
  id: Scalars['ID'];
  input: FeatureUpdateInput;
};


export type MutationUpdateItemArgs = {
  id: Scalars['ID'];
  input: ItemInput;
};


export type MutationUpdateItemFaqArgs = {
  id: Scalars['ID'];
  input: ItemFaqInput;
};


export type MutationUpdateLabelArgs = {
  id: Scalars['ID'];
  input: LabelInput;
};


export type MutationUpdateProfileArgs = {
  Avatar?: Maybe<PictureDictionaryInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type MutationUpdateScreenSizeV4Args = {
  id: Scalars['ID'];
  input: SortScreenSizeInputV4;
};


export type MutationUpdateScreenSizeV5Args = {
  input: Array<Maybe<Array<SortCategoryV4>>>;
  type: ScreenSizeType;
};


export type MutationUpdateSkillArgs = {
  id: Scalars['ID'];
  input: SkillInput;
};


export type MutationUpdateStaffArgs = {
  id: Scalars['ID'];
  input: StaffInput;
};


export type MutationUpdateStaffPermissionsArgs = {
  id: Scalars['ID'];
  permissions: Array<PermissionInput>;
};


export type MutationUploadCropImageArgs = {
  input: UploadCropInput;
};


export type MutationUploadImageArgs = {
  input: UploadInput;
};


export type MutationUploadWatermarkArgs = {
  input: Scalars['Upload'];
};


export type MutationUpscaleRelatedItems350CropTo700Args = {
  categoryID: Scalars['ID'];
};


export type MutationVerifyEmailConfirmationArgs = {
  code: Scalars['String'];
};


export type MutationWebPushArgs = {
  push: Scalars['JSON'];
};

export type OAuthLoginResponse = {
  __typename?: 'OAuthLoginResponse';
  oauthstate: Scalars['String'];
  redirectUrl: Scalars['String'];
};

export enum OAuthType {
  Facebook = 'Facebook',
  Github = 'Github',
  Google = 'Google',
  LinkedIn = 'LinkedIn',
  Twitter = 'Twitter'
}

export type Object = {
  avatar?: Maybe<Array<PictureDictionary>>;
  firstName?: Maybe<Scalars['String']>;
  icon?: Maybe<Array<PictureDictionary>>;
  id: Scalars['ID'];
  image?: Maybe<Picture>;
  itemCount?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export enum ObjectType {
  BlogPost = 'BlogPost',
  Category = 'Category',
  Comment = 'Comment',
  Item = 'Item',
  User = 'User'
}

export type Page = {
  __typename?: 'Page';
  content: Scalars['String'];
  dynamic?: Maybe<Array<DynamicData>>;
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type PageInput = {
  content: Scalars['String'];
  dynamic?: Maybe<Array<DynamicDataInput>>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type Pagination = {
  count: Scalars['Int'];
  data: Array<Identifable>;
  limit: Scalars['Int'];
};

export enum ParentType {
  BlogPost = 'BlogPost',
  Category = 'Category',
  Comment = 'Comment',
  Item = 'Item'
}

export type Permission = {
  __typename?: 'Permission';
  actions: ActionName;
  model: ModelName;
};

export type PermissionInput = {
  actions: ActionName;
  model: ModelName;
};

export type Picture = {
  __typename?: 'Picture';
  Sizes: Array<PictureDictionary>;
  id: Scalars['ID'];
  positions?: Maybe<Positions>;
};

export type PictureDictionary = {
  __typename?: 'PictureDictionary';
  hasWatermark?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  value: Scalars['ImageURL'];
};

export type PictureDictionaryInput = {
  hasWatermark?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  value: Scalars['ImageURL'];
};

export type PictureInput = {
  Sizes: Array<PictureDictionaryInput>;
  id: Scalars['ID'];
  positions?: Maybe<PositionsInput>;
};

export type PipelineData = {
  __typename?: 'PipelineData';
  created_at: Scalars['String'];
  id: Scalars['Int'];
  status: RebuildStatus;
  updated_at: Scalars['String'];
};

export enum Platform {
  Android = 'Android',
  Ios = 'IOS',
  KindleFire = 'KindleFire',
  Linux = 'Linux',
  MacOs = 'MacOS',
  Routers = 'Routers',
  Web = 'Web',
  Windows = 'Windows'
}

export type Positions = {
  __typename?: 'Positions';
  height: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type PositionsInput = {
  height: Scalars['Int'];
  width: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type Promo = {
  __typename?: 'Promo';
  code?: Maybe<Scalars['String']>;
  expire?: Maybe<Scalars['String']>;
  offer?: Maybe<Scalars['String']>;
};

export type PromoInput = {
  code?: Maybe<Scalars['String']>;
  expire?: Maybe<Scalars['String']>;
  offer?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activeModels: ActiveModels;
  ads: Array<Ad>;
  author: Author;
  authors: AuthorsResponse;
  blogPost: BlogPost;
  blogPosts: BlogPostsResponse;
  categories: CategoriesResponse;
  category: Category;
  categoryFields: CategoryFields;
  comment: Comment;
  comments: CommentsResponse;
  companies: CompaniesResponse;
  company: Company;
  companyFields: CompanyFields;
  content: Array<Content>;
  countries: Array<Country>;
  defaultSizeSettings: DefaultSizesSettings;
  deletedItems: Array<Item>;
  deviceBreakdown: DeviceBreakdown;
  dynamicField: DynamicFieldModel;
  dynamicFields: Array<DynamicFieldModel>;
  dynamicModel: DynamicModel;
  dynamicModels: Array<DynamicModel>;
  dynamicVariant: DynamicVariant;
  dynamicVariants: Array<DynamicVariant>;
  faq: Array<CategoryFaq>;
  features: Array<Feature>;
  fetchPipelineData?: Maybe<PipelineData>;
  getContactUs?: Maybe<Array<ContactUs>>;
  /** Pass there main image ID, not size ID */
  getImageOriginUrl: Scalars['String'];
  glance: GaDashboard;
  item: Item;
  itemFaq: Array<ItemFaq>;
  itemFields: ItemFields;
  itemFreeCategoriesCount?: Maybe<Array<ItemFreeCount>>;
  items: ItemsResponse;
  itemsByCategoryFavsFirst: ItemsFavsResponse;
  itemsCount: Scalars['Int'];
  keywords: Array<Keyword>;
  labels: Array<Label>;
  latestTools: ItemsResponse;
  me: User;
  myActions: ActionResponse;
  myTool: SubmittedTool;
  myTools: Array<SubmittedTool>;
  overview: Array<GaDashboard>;
  page: Page;
  pages: Array<Page>;
  screenSizeV4: SortScreenSizeV4;
  screenSizeV5: Array<Maybe<Array<Category>>>;
  screenSizes?: Maybe<Array<SortScreenSize>>;
  screenSizesV4?: Maybe<Array<SortScreenSizeV4>>;
  seo: GlobalSeo;
  skill: Skill;
  skillFields: SkillFields;
  skills: SkillsResponse;
  sliderCategories: Array<Category>;
  sortCategoriesSizeV3: SortCategories;
  staff: Staff;
  staffs: Array<Staff>;
  staticRebuildStatus?: Maybe<RebuildStatus>;
  submittedTool: SubmittedTool;
  submittedTools: SubmittedToolsPaging;
  topPages: Array<TopPage>;
  user: User;
  userActions: ActionResponse;
  users: UsersResponse;
  visitors: Visitors;
  watermark?: Maybe<Scalars['String']>;
};


export type QueryAuthorArgs = {
  id: Scalars['ID'];
};


export type QueryAuthorsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
};


export type QueryBlogPostArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryBlogPostsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
  thumbFilter?: Maybe<WithWithout>;
};


export type QueryCategoriesArgs = {
  active?: Maybe<Scalars['Boolean']>;
  descriptionFilter?: Maybe<WithWithout>;
  dynamicFilter?: Maybe<DynamicFilter>;
  dynamicSort?: Maybe<DynamicSortingInput>;
  free?: Maybe<Array<ItemFree>>;
  iconFilter?: Maybe<WithWithout>;
  items?: Maybe<Array<Scalars['ID']>>;
  limit?: Maybe<Scalars['Int']>;
  onlyIds?: Maybe<Array<Scalars['ID']>>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
  thumbFilter?: Maybe<WithWithout>;
  zeroItems?: Maybe<WithWithout>;
};


export type QueryCategoryArgs = {
  device?: Maybe<ScreenSizeType>;
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCommentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  parent: Scalars['ID'];
  parentType: ParentType;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  sortDirection?: Maybe<SortDirection>;
};


export type QueryCompaniesArgs = {
  iconFilter?: Maybe<WithWithout>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
};


export type QueryCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryContentArgs = {
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
};


export type QueryCountriesArgs = {
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
};


export type QueryDeletedItemsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryDeviceBreakdownArgs = {
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
};


export type QueryDynamicFieldArgs = {
  id: Scalars['ID'];
};


export type QueryDynamicFieldsArgs = {
  modelID: Scalars['ID'];
};


export type QueryDynamicModelArgs = {
  id: Scalars['ID'];
};


export type QueryDynamicModelsArgs = {
  entity?: Maybe<Entity>;
};


export type QueryDynamicVariantArgs = {
  id: Scalars['ID'];
};


export type QueryDynamicVariantsArgs = {
  modelID: Scalars['ID'];
};


export type QueryFaqArgs = {
  categoryID: Scalars['ID'];
};


export type QueryFeaturesArgs = {
  categoryId: Array<Scalars['ID']>;
};


export type QueryGetContactUsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetImageOriginUrlArgs = {
  imageID: Scalars['ID'];
};


export type QueryGlanceArgs = {
  date?: Maybe<Scalars['Date']>;
};


export type QueryItemArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryItemFaqArgs = {
  ItemID: Scalars['ID'];
};


export type QueryItemsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Scalars['ID']>>;
  category?: Maybe<Scalars['ID']>;
  companies?: Maybe<Array<Scalars['ID']>>;
  descriptionFilter?: Maybe<WithWithout>;
  dynamicFilter?: Maybe<DynamicFilter>;
  dynamicSort?: Maybe<DynamicSortingInput>;
  iconFilter?: Maybe<WithWithout>;
  limit?: Maybe<Scalars['Int']>;
  onlyIDs?: Maybe<Array<Scalars['ID']>>;
  page?: Maybe<Scalars['Int']>;
  platform?: Maybe<Array<Platform>>;
  search?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
  thumbFilter?: Maybe<WithWithout>;
  uploadedByUser?: Maybe<Scalars['Boolean']>;
};


export type QueryItemsByCategoryFavsFirstArgs = {
  categoryID: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  userID: Scalars['ID'];
};


export type QueryItemsCountArgs = {
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  categorySkills?: Maybe<Array<Scalars['ID']>>;
  free?: Maybe<Array<ItemFree>>;
  includeDisabled?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Array<Platform>>;
  search?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
};


export type QueryKeywordsArgs = {
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
};


export type QueryLatestToolsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  onlyIds?: Maybe<Array<Scalars['ID']>>;
  page?: Maybe<Scalars['Int']>;
  skills?: Maybe<Array<Scalars['ID']>>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
  year?: Maybe<Scalars['String']>;
};


export type QueryMyActionsArgs = {
  limit?: Scalars['Int'];
  page?: Scalars['Int'];
  sortDirection?: SortDirection;
};


export type QueryMyToolArgs = {
  id: Scalars['ID'];
};


export type QueryOverviewArgs = {
  days: Scalars['Int'];
};


export type QueryPageArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryPagesArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryScreenSizeV4Args = {
  free?: Maybe<Array<ItemFree>>;
  id?: Maybe<Scalars['ID']>;
  includeDisabled?: Maybe<Scalars['Boolean']>;
  maxCountInColumn?: Maybe<Scalars['Int']>;
  onlyIds?: Maybe<Array<Scalars['ID']>>;
  screenSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
};


export type QueryScreenSizeV5Args = {
  free?: Maybe<Array<ItemFree>>;
  includeDisabled?: Maybe<Scalars['Boolean']>;
  maxCountInColumn?: Maybe<Scalars['Int']>;
  onlyIds?: Maybe<Array<Scalars['ID']>>;
  platform?: Maybe<Array<Platform>>;
  search?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
  skipInColumn?: Maybe<Scalars['Int']>;
  sortItemsAdditionalCount?: Maybe<Scalars['Int']>;
  type: ScreenSizeType;
};


export type QuerySkillArgs = {
  id: Scalars['ID'];
};


export type QuerySkillsArgs = {
  iconFilter?: Maybe<WithWithout>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortBy>;
  sortByDirection?: Maybe<SortDirection>;
};


export type QuerySliderCategoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QuerySortCategoriesSizeV3Args = {
  free?: Maybe<Array<ItemFree>>;
  includeDisabled?: Maybe<Scalars['Boolean']>;
  maxCountInColumn?: Maybe<Scalars['Int']>;
  onlyIds?: Maybe<Array<Scalars['ID']>>;
  screenSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
};


export type QueryStaffArgs = {
  id: Scalars['ID'];
};


export type QueryStaffsArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QuerySubmittedToolArgs = {
  id: Scalars['ID'];
};


export type QuerySubmittedToolsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};


export type QueryTopPagesArgs = {
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryUserActionsArgs = {
  limit?: Scalars['Int'];
  page?: Scalars['Int'];
  sortDirection?: SortDirection;
  userID: Scalars['Int'];
};


export type QueryUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryVisitorsArgs = {
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
};

export type Question = {
  __typename?: 'Question';
  email: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  name: Scalars['String'];
  services: Scalars['String'];
};

export type QuestionInput = {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  services: Scalars['String'];
};

export enum RebuildStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Failed = 'Failed',
  Pending = 'Pending',
  Running = 'Running',
  Success = 'Success'
}

export type Resize = {
  aspectRatio?: Maybe<Scalars['Float']>;
  watermark?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
};

export type Seo = {
  __typename?: 'Seo';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SeoInput = {
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SeoTemplate = {
  __typename?: 'SeoTemplate';
  category?: Maybe<Seo>;
  item?: Maybe<Seo>;
};

export type SeoTemplateInput = {
  category?: Maybe<SeoInput>;
  item?: Maybe<SeoInput>;
};

export enum ShareType {
  Embed = 'Embed',
  Facebook = 'Facebook',
  Twitter = 'Twitter'
}

export type SizesSettings = {
  __typename?: 'SizesSettings';
  aspectRatio: Scalars['Float'];
  lock?: Maybe<Scalars['Boolean']>;
  parentType: ParentType;
  retina?: Maybe<Scalars['Boolean']>;
  width: Scalars['Int'];
};

export type Skill = Identifable & {
  __typename?: 'Skill';
  categories: Array<Category>;
  categoriesCount: Scalars['Int'];
  fontAwesomeIcon?: Maybe<FontAwesomeIcon>;
  icon?: Maybe<Array<PictureDictionary>>;
  id: Scalars['ID'];
  itemsCount: Scalars['Int'];
  title: Scalars['String'];
};

export type SkillFields = {
  __typename?: 'SkillFields';
  icon: Scalars['Boolean'];
};

export type SkillInput = {
  fontAwesomeIcon?: Maybe<FontAwesomeIconInput>;
  icon?: Maybe<Array<PictureDictionaryInput>>;
  title: Scalars['String'];
};

export type SkillsResponse = Pagination & {
  __typename?: 'SkillsResponse';
  count: Scalars['Int'];
  data: Array<Skill>;
  limit: Scalars['Int'];
};

export type Social = {
  __typename?: 'Social';
  facebook?: Maybe<Scalars['URL']>;
  twitter?: Maybe<Scalars['URL']>;
};

export type SocialInput = {
  facebook?: Maybe<Scalars['URL']>;
  twitter?: Maybe<Scalars['URL']>;
};

export enum SortBy {
  CreationDate = 'creationDate',
  Popular = 'popular',
  Price = 'price',
  Title = 'title',
  UpdatedDate = 'updatedDate'
}

export type SortCategories = {
  __typename?: 'SortCategories';
  categories: Array<Array<Category>>;
  id: Scalars['ID'];
  screenSize: Scalars['Int'];
};

export type SortCategoryV4 = {
  additionalItemsCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  items?: Maybe<Array<Scalars['ID']>>;
  itemsCountTotal: Scalars['Int'];
  itemsCountVisible: Scalars['Int'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortScreenSize = {
  __typename?: 'SortScreenSize';
  columns: Scalars['Int'];
  id: Scalars['ID'];
  screenSize: Scalars['Int'];
};

export type SortScreenSizeInputV4 = {
  categories: Array<Array<SortCategoryV4>>;
  screenSize: Scalars['Int'];
};

export type SortScreenSizeV4 = {
  __typename?: 'SortScreenSizeV4';
  categories: Array<Array<Category>>;
  id: Scalars['ID'];
  screenSize: Scalars['Int'];
};

export type Staff = {
  __typename?: 'Staff';
  email: Scalars['String'];
  id: Scalars['ID'];
  permissions: Array<Permission>;
};

export type StaffInput = {
  email: Scalars['String'];
  permissions: Array<PermissionInput>;
};

export enum Status {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type SubmitToolExtrasInput = {
  comment?: Maybe<Scalars['String']>;
  free?: Maybe<Array<ItemFree>>;
  makers?: Maybe<Array<Scalars['ID']>>;
  press?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  promo?: Maybe<PromoInput>;
};

export type SubmitToolMainInfoInput = {
  categories?: Maybe<Array<Scalars['ID']>>;
  facebook?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Scalars['ID']>>;
  launchTime?: Maybe<Scalars['Date']>;
  linkURL: Scalars['String'];
  longDescription?: Maybe<Scalars['String']>;
  platform?: Maybe<Array<Scalars['String']>>;
  shortDescription?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['ID']>>;
  title: Scalars['String'];
  twitter?: Maybe<Scalars['String']>;
};

export type SubmitToolMediaInput = {
  icon?: Maybe<Array<PictureDictionaryInput>>;
  images?: Maybe<Array<PictureInput>>;
  youtube?: Maybe<Scalars['String']>;
};

export type SubmittedTool = Identifable & {
  __typename?: 'SubmittedTool';
  active?: Maybe<Scalars['Boolean']>;
  approved: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['Date']>;
  author: User;
  categories?: Maybe<Array<Category>>;
  categoryApproved?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Feature>>;
  free?: Maybe<Array<ItemFree>>;
  icon?: Maybe<Array<PictureDictionary>>;
  id: Scalars['ID'];
  images?: Maybe<Array<Picture>>;
  isDraft?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  launchTime?: Maybe<Scalars['Date']>;
  linkURL: Scalars['String'];
  longDescription?: Maybe<Scalars['String']>;
  makers?: Maybe<Array<User>>;
  platform?: Maybe<Array<Platform>>;
  press?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  promo?: Maybe<Promo>;
  reviewed?: Maybe<Scalars['Boolean']>;
  shortDescription?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Skill>>;
  slug: Scalars['String'];
  status?: Maybe<Status>;
  tags?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};


export type SubmittedToolImagesArgs = {
  size?: Maybe<Scalars['Int']>;
};

export type SubmittedToolsPaging = Pagination & {
  __typename?: 'SubmittedToolsPaging';
  count: Scalars['Int'];
  data: Array<SubmittedTool>;
  limit: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onRebuildStatusChanged: RebuildStatus;
};

export type TopPage = {
  __typename?: 'TopPage';
  title: Scalars['String'];
  visitors: Scalars['Int'];
};



export type UploadCropInput = {
  imageUrl: Scalars['String'];
  positions?: Maybe<PositionsInput>;
  sizes?: Maybe<Array<Resize>>;
};

export type UploadInput = {
  file: Scalars['Upload'];
  positions?: Maybe<PositionsInput>;
  sizes?: Maybe<Array<Resize>>;
};

export type User = Identifable & Object & {
  __typename?: 'User';
  activitiesCount: Scalars['Int'];
  avatar: Array<PictureDictionary>;
  comments: Array<Comment>;
  commentsCount: Scalars['Int'];
  email: Scalars['String'];
  emailSubscriptionEnabled: Scalars['Boolean'];
  facebook?: Maybe<Scalars['String']>;
  favoriteCategories: Array<Category>;
  firstName?: Maybe<Scalars['String']>;
  icon?: Maybe<Array<PictureDictionary>>;
  id: Scalars['ID'];
  image?: Maybe<Picture>;
  itemCount?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  likedItemsCount: Scalars['Int'];
  likes?: Maybe<Scalars['Int']>;
  skills?: Maybe<Array<Skill>>;
  slug?: Maybe<Scalars['String']>;
  subscribers: Array<User>;
  subscribersCount: Scalars['Int'];
  subscriptions: Array<User>;
  subscriptionsCount: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  toolsCount: Scalars['Int'];
  twitter?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type UserCommentsArgs = {
  fromDate?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortBy>;
  toDate?: Maybe<Scalars['Date']>;
};


export type UserFavoriteCategoriesArgs = {
  search?: Maybe<Scalars['String']>;
};


export type UserSubscribersArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortBy>;
  sortDirection?: Maybe<SortDirection>;
};


export type UserSubscriptionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SortBy>;
  sortDirection?: Maybe<SortDirection>;
};

export type UserInput = {
  avatar?: Maybe<Array<PictureDictionaryInput>>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export type UsersResponse = Pagination & {
  __typename?: 'UsersResponse';
  count: Scalars['Int'];
  data: Array<User>;
  limit: Scalars['Int'];
};

export type Visitors = {
  __typename?: 'Visitors';
  new: Scalars['Int'];
  returned: Scalars['Int'];
};

export enum WithWithout {
  With = 'With',
  Without = 'Without'
}

export type MainCategoryItem = {
  __typename?: 'mainCategoryItem';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type MainCategoryItemInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export enum ScreenSizeType {
  Custom = 'custom',
  Desktop = 'desktop',
  Mobile = 'mobile',
  Tablet = 'tablet'
}

export const AuthorFragmentFragmentDoc = gql`
    fragment AuthorFragment on Author {
  id
  user {
    id
    email
  }
  name
  lastName
  avatar {
    value
    id
    size
  }
  dynamic {
    data
    model {
      id
      name
    }
  }
  posts {
    id
    title
    categories {
      id
      title
      shortDescription
    }
  }
}
    `;
export const CategoryFragmentSuperLightFragmentDoc = gql`
    fragment CategoryFragmentSuperLight on Category {
  id
  title
  active
  sortItemsCountTotal
  sortItemsCountVisible
  skills {
    title
    id
  }
  icon {
    id
    size
    value
  }
}
    `;
export const ImageFragmentFragmentDoc = gql`
    fragment ImageFragment on Picture {
  id
  Sizes {
    id
    size
    value
    hasWatermark
  }
  positions {
    x
    y
    width
    height
  }
}
    `;
export const SeoFragmentFragmentDoc = gql`
    fragment SeoFragment on Seo {
  title
  description
  image
}
    `;
export const CategoryFragmentLightFragmentDoc = gql`
    fragment CategoryFragmentLight on Category {
  id
  title
  slug
  slugCustom
  shortDescription
  longDescription
  color
  active
  sortItemsCountTotal
  sortItemsCountVisible
  faq {
    id
    question
    answer
  }
  dynamic {
    data
    model {
      id
      name
    }
  }
  relatedCategories {
    id
    title
  }
  icon {
    id
    size
    value
  }
  image {
    ...ImageFragment
  }
  seoCustom {
    ...SeoFragment
  }
  skills {
    id
    title
  }
  fontAwesomeIcon {
    name
    prefix
    color
  }
}
    ${ImageFragmentFragmentDoc}
${SeoFragmentFragmentDoc}`;
export const ItemFragmentFragmentDoc = gql`
    fragment ItemFragment on Item {
  id
  title
  slug
  slugCustom
  shortDescription
  longDescription
  free
  price
  active
  iconSetManually
  approved
  approvedAt
  twitter
  facebook
  youtube
  createdAt
  creatorID
  categoryApproved
  mainCategory {
    id
    title
  }
  faq {
    id
    question
    answer
    itemID
  }
  username
  features {
    id
    title
  }
  promo {
    offer
    code
    expire
  }
  reviews {
    title
    description
    authorName
    authorAvatar
    authorCompany
    authorPosition
    date
    companyLink
  }
  thumbnail {
    id
    size
    value
  }
  link {
    url
    alternativeUrl
    forceNoFollow
    forceNoIndex
    forceNoOpenner
    targetBlank
  }
  icon {
    id
    value
    size
  }
  images {
    ...ImageFragment
  }
  platform
  company {
    id
    title
  }
  skills {
    id
    title
    icon {
      size
      value
    }
  }
  categories {
    id
    title
    slug
    features {
      id
      title
      categoryID
    }
  }
  seoCustom {
    ...SeoFragment
  }
  pros
  cons
  tags
  dynamic {
    data
    filledData
    model {
      id
      name
      type
      variants {
        id
        value
      }
      fields {
        id
        isArray
        name
        primaryField
        type
      }
    }
  }
}
    ${ImageFragmentFragmentDoc}
${SeoFragmentFragmentDoc}`;
export const CategoryFragmentFragmentDoc = gql`
    fragment CategoryFragment on Category {
  id
  title
  slug
  shortDescription
  longDescription
  color
  active
  sortItemsCountTotal
  sortItemsCountVisible
  seo {
    image
  }
  seoCustom {
    ...SeoFragment
  }
  dynamic {
    data
    model {
      id
      name
    }
  }
  relatedCategories {
    id
    title
  }
  icon {
    id
    size
    value
  }
  image {
    ...ImageFragment
  }
  items {
    ...ItemFragment
  }
  skills {
    id
    title
  }
  fontAwesomeIcon {
    name
    prefix
    color
  }
}
    ${SeoFragmentFragmentDoc}
${ImageFragmentFragmentDoc}
${ItemFragmentFragmentDoc}`;
export const CompanyFragmentFragmentDoc = gql`
    fragment CompanyFragment on Company {
  id
  title
  icon {
    id
    value
    size
  }
  link {
    url
    alternativeUrl
    forceNoFollow
    forceNoIndex
    forceNoOpenner
    targetBlank
  }
  items {
    id
    title
  }
  seo {
    title
    description
  }
  social {
    twitter
    facebook
  }
  slug
}
    `;
export const FeatureFragmentFragmentDoc = gql`
    fragment FeatureFragment on Feature {
  id
  title
  description
  icon {
    size
    url
  }
  fontAwesomeIcon {
    name
    prefix
    color
  }
}
    `;
export const PageFragmentFragmentDoc = gql`
    fragment PageFragment on Page {
  id
  title
  slug
  content
  dynamic {
    data
    model {
      id
      name
    }
  }
}
    `;
export const BlogPostFragmentFragmentDoc = gql`
    fragment BlogPostFragment on BlogPost {
  id
  author {
    id
    name
  }
  thumb {
    id
    Sizes {
      id
      size
      value
    }
  }
  date
  comments {
    id
    user {
      id
      email
    }
    text
  }
  title
  slug
  content
  shortDescription
  active
  categories {
    id
    title
  }
  labels {
    id
    name
  }
  dynamic {
    data
    model {
      id
      name
    }
  }
}
    `;
export const SkillFragmentFragmentDoc = gql`
    fragment SkillFragment on Skill {
  id
  title
  fontAwesomeIcon {
    name
    prefix
    color
  }
  icon {
    value
    size
    id
  }
}
    `;
export const SubmittedToolFragmentDoc = gql`
    fragment SubmittedTool on SubmittedTool {
  id
  itemID
  title
  slug
  tags
  linkURL
  active
  status
  skills {
    id
    title
    icon {
      size
      value
    }
  }
  features {
    id
    title
  }
  categories {
    id
    title
    slug
    features {
      id
      title
      categoryID
    }
  }
  shortDescription
  longDescription
  icon {
    id
    value
    size
  }
  images {
    ...ImageFragment
  }
  free
  promo {
    offer
    code
    expire
  }
  press
  comment
  approved
  facebook
  twitter
  youtube
  author {
    id
    title
  }
  price
  launchTime
  approvedAt
  isDraft
  reviewed
  categoryApproved
}
    ${ImageFragmentFragmentDoc}`;
export const GetAdsDocument = gql`
    query GetAds {
  ads {
    id
    background
    link
    align
    actionColor
  }
}
    `;

/**
 * __useGetAdsQuery__
 *
 * To run a query within a React component, call `useGetAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdsQuery, GetAdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdsQuery, GetAdsQueryVariables>(GetAdsDocument, options);
      }
export function useGetAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdsQuery, GetAdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdsQuery, GetAdsQueryVariables>(GetAdsDocument, options);
        }
export type GetAdsQueryHookResult = ReturnType<typeof useGetAdsQuery>;
export type GetAdsLazyQueryHookResult = ReturnType<typeof useGetAdsLazyQuery>;
export type GetAdsQueryResult = Apollo.QueryResult<GetAdsQuery, GetAdsQueryVariables>;
export const CreateAdDocument = gql`
    mutation CreateAd($input: AdInput!) {
  createAd(input: $input) {
    id
    background
    link
    align
    actionColor
  }
}
    `;
export type CreateAdMutationFn = Apollo.MutationFunction<CreateAdMutation, CreateAdMutationVariables>;

/**
 * __useCreateAdMutation__
 *
 * To run a mutation, you first call `useCreateAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdMutation, { data, loading, error }] = useCreateAdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdMutation, CreateAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdMutation, CreateAdMutationVariables>(CreateAdDocument, options);
      }
export type CreateAdMutationHookResult = ReturnType<typeof useCreateAdMutation>;
export type CreateAdMutationResult = Apollo.MutationResult<CreateAdMutation>;
export type CreateAdMutationOptions = Apollo.BaseMutationOptions<CreateAdMutation, CreateAdMutationVariables>;
export const UpdateAdDocument = gql`
    mutation UpdateAd($id: ID!, $input: AdInput!) {
  updateAd(id: $id, input: $input) {
    id
    background
    link
    align
    actionColor
  }
}
    `;
export type UpdateAdMutationFn = Apollo.MutationFunction<UpdateAdMutation, UpdateAdMutationVariables>;

/**
 * __useUpdateAdMutation__
 *
 * To run a mutation, you first call `useUpdateAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdMutation, { data, loading, error }] = useUpdateAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdMutation, UpdateAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdMutation, UpdateAdMutationVariables>(UpdateAdDocument, options);
      }
export type UpdateAdMutationHookResult = ReturnType<typeof useUpdateAdMutation>;
export type UpdateAdMutationResult = Apollo.MutationResult<UpdateAdMutation>;
export type UpdateAdMutationOptions = Apollo.BaseMutationOptions<UpdateAdMutation, UpdateAdMutationVariables>;
export const DeleteAdDocument = gql`
    mutation DeleteAd($id: ID!) {
  deleteAd(id: $id)
}
    `;
export type DeleteAdMutationFn = Apollo.MutationFunction<DeleteAdMutation, DeleteAdMutationVariables>;

/**
 * __useDeleteAdMutation__
 *
 * To run a mutation, you first call `useDeleteAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdMutation, { data, loading, error }] = useDeleteAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdMutation, DeleteAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdMutation, DeleteAdMutationVariables>(DeleteAdDocument, options);
      }
export type DeleteAdMutationHookResult = ReturnType<typeof useDeleteAdMutation>;
export type DeleteAdMutationResult = Apollo.MutationResult<DeleteAdMutation>;
export type DeleteAdMutationOptions = Apollo.BaseMutationOptions<DeleteAdMutation, DeleteAdMutationVariables>;
export const GetAuthorListDocument = gql`
    query getAuthorList($page: Int, $limit: Int, $search: String, $sortBy: SortBy) {
  authors(page: $page, limit: $limit, search: $search, sortBy: $sortBy) {
    data {
      ...AuthorFragment
    }
    count
  }
}
    ${AuthorFragmentFragmentDoc}`;

/**
 * __useGetAuthorListQuery__
 *
 * To run a query within a React component, call `useGetAuthorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetAuthorListQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthorListQuery, GetAuthorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthorListQuery, GetAuthorListQueryVariables>(GetAuthorListDocument, options);
      }
export function useGetAuthorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthorListQuery, GetAuthorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthorListQuery, GetAuthorListQueryVariables>(GetAuthorListDocument, options);
        }
export type GetAuthorListQueryHookResult = ReturnType<typeof useGetAuthorListQuery>;
export type GetAuthorListLazyQueryHookResult = ReturnType<typeof useGetAuthorListLazyQuery>;
export type GetAuthorListQueryResult = Apollo.QueryResult<GetAuthorListQuery, GetAuthorListQueryVariables>;
export const GetAuthorDocument = gql`
    query getAuthor($id: ID!) {
  author(id: $id) {
    ...AuthorFragment
  }
}
    ${AuthorFragmentFragmentDoc}`;

/**
 * __useGetAuthorQuery__
 *
 * To run a query within a React component, call `useGetAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuthorQuery(baseOptions: Apollo.QueryHookOptions<GetAuthorQuery, GetAuthorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthorQuery, GetAuthorQueryVariables>(GetAuthorDocument, options);
      }
export function useGetAuthorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthorQuery, GetAuthorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthorQuery, GetAuthorQueryVariables>(GetAuthorDocument, options);
        }
export type GetAuthorQueryHookResult = ReturnType<typeof useGetAuthorQuery>;
export type GetAuthorLazyQueryHookResult = ReturnType<typeof useGetAuthorLazyQuery>;
export type GetAuthorQueryResult = Apollo.QueryResult<GetAuthorQuery, GetAuthorQueryVariables>;
export const CreateAuthorDocument = gql`
    mutation createAuthor($input: AuthorInput!) {
  createAuthor(input: $input) {
    ...AuthorFragment
  }
}
    ${AuthorFragmentFragmentDoc}`;
export type CreateAuthorMutationFn = Apollo.MutationFunction<CreateAuthorMutation, CreateAuthorMutationVariables>;

/**
 * __useCreateAuthorMutation__
 *
 * To run a mutation, you first call `useCreateAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthorMutation, { data, loading, error }] = useCreateAuthorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthorMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthorMutation, CreateAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthorMutation, CreateAuthorMutationVariables>(CreateAuthorDocument, options);
      }
export type CreateAuthorMutationHookResult = ReturnType<typeof useCreateAuthorMutation>;
export type CreateAuthorMutationResult = Apollo.MutationResult<CreateAuthorMutation>;
export type CreateAuthorMutationOptions = Apollo.BaseMutationOptions<CreateAuthorMutation, CreateAuthorMutationVariables>;
export const UpdateAuthorDocument = gql`
    mutation updateAuthor($id: ID!, $input: AuthorInput!) {
  updateAuthor(id: $id, input: $input) {
    ...AuthorFragment
  }
}
    ${AuthorFragmentFragmentDoc}`;
export type UpdateAuthorMutationFn = Apollo.MutationFunction<UpdateAuthorMutation, UpdateAuthorMutationVariables>;

/**
 * __useUpdateAuthorMutation__
 *
 * To run a mutation, you first call `useUpdateAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthorMutation, { data, loading, error }] = useUpdateAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAuthorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuthorMutation, UpdateAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuthorMutation, UpdateAuthorMutationVariables>(UpdateAuthorDocument, options);
      }
export type UpdateAuthorMutationHookResult = ReturnType<typeof useUpdateAuthorMutation>;
export type UpdateAuthorMutationResult = Apollo.MutationResult<UpdateAuthorMutation>;
export type UpdateAuthorMutationOptions = Apollo.BaseMutationOptions<UpdateAuthorMutation, UpdateAuthorMutationVariables>;
export const DeleteAuthorDocument = gql`
    mutation deleteAuthor($id: ID!) {
  deleteAuthor(id: $id)
}
    `;
export type DeleteAuthorMutationFn = Apollo.MutationFunction<DeleteAuthorMutation, DeleteAuthorMutationVariables>;

/**
 * __useDeleteAuthorMutation__
 *
 * To run a mutation, you first call `useDeleteAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuthorMutation, { data, loading, error }] = useDeleteAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAuthorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuthorMutation, DeleteAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuthorMutation, DeleteAuthorMutationVariables>(DeleteAuthorDocument, options);
      }
export type DeleteAuthorMutationHookResult = ReturnType<typeof useDeleteAuthorMutation>;
export type DeleteAuthorMutationResult = Apollo.MutationResult<DeleteAuthorMutation>;
export type DeleteAuthorMutationOptions = Apollo.BaseMutationOptions<DeleteAuthorMutation, DeleteAuthorMutationVariables>;
export const SliderCategoriesDocument = gql`
    query sliderCategories($page: Int, $limit: Int) {
  sliderCategories(page: $page, limit: $limit) {
    id
    title
    slug
    icon {
      id
      size
      value
    }
    image {
      ...ImageFragment
    }
  }
}
    ${ImageFragmentFragmentDoc}`;

/**
 * __useSliderCategoriesQuery__
 *
 * To run a query within a React component, call `useSliderCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSliderCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSliderCategoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSliderCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SliderCategoriesQuery, SliderCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SliderCategoriesQuery, SliderCategoriesQueryVariables>(SliderCategoriesDocument, options);
      }
export function useSliderCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SliderCategoriesQuery, SliderCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SliderCategoriesQuery, SliderCategoriesQueryVariables>(SliderCategoriesDocument, options);
        }
export type SliderCategoriesQueryHookResult = ReturnType<typeof useSliderCategoriesQuery>;
export type SliderCategoriesLazyQueryHookResult = ReturnType<typeof useSliderCategoriesLazyQuery>;
export type SliderCategoriesQueryResult = Apollo.QueryResult<SliderCategoriesQuery, SliderCategoriesQueryVariables>;
export const GetCategoriesSuperLightListDocument = gql`
    query getCategoriesSuperLightList($page: Int, $limit: Int, $search: String, $iconFilter: WithWithout, $thumbFilter: WithWithout, $sortBy: SortBy) {
  categories(
    page: $page
    limit: $limit
    search: $search
    iconFilter: $iconFilter
    thumbFilter: $thumbFilter
    sortBy: $sortBy
  ) {
    data {
      ...CategoryFragmentSuperLight
      slug
    }
    count
  }
}
    ${CategoryFragmentSuperLightFragmentDoc}`;

/**
 * __useGetCategoriesSuperLightListQuery__
 *
 * To run a query within a React component, call `useGetCategoriesSuperLightListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesSuperLightListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesSuperLightListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      iconFilter: // value for 'iconFilter'
 *      thumbFilter: // value for 'thumbFilter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetCategoriesSuperLightListQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesSuperLightListQuery, GetCategoriesSuperLightListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesSuperLightListQuery, GetCategoriesSuperLightListQueryVariables>(GetCategoriesSuperLightListDocument, options);
      }
export function useGetCategoriesSuperLightListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesSuperLightListQuery, GetCategoriesSuperLightListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesSuperLightListQuery, GetCategoriesSuperLightListQueryVariables>(GetCategoriesSuperLightListDocument, options);
        }
export type GetCategoriesSuperLightListQueryHookResult = ReturnType<typeof useGetCategoriesSuperLightListQuery>;
export type GetCategoriesSuperLightListLazyQueryHookResult = ReturnType<typeof useGetCategoriesSuperLightListLazyQuery>;
export type GetCategoriesSuperLightListQueryResult = Apollo.QueryResult<GetCategoriesSuperLightListQuery, GetCategoriesSuperLightListQueryVariables>;
export const GetCategoriesListDocument = gql`
    query getCategoriesList($page: Int, $limit: Int, $search: String, $iconFilter: WithWithout, $thumbFilter: WithWithout, $sortBy: SortBy, $descriptionFilter: WithWithout, $items: [ID!], $skills: [ID!], $active: Boolean, $sortByDirection: SortDirection, $zeroItems: WithWithout, $onlyIds: [ID!]) {
  categories(
    page: $page
    limit: $limit
    search: $search
    iconFilter: $iconFilter
    thumbFilter: $thumbFilter
    sortBy: $sortBy
    descriptionFilter: $descriptionFilter
    skills: $skills
    items: $items
    sortByDirection: $sortByDirection
    active: $active
    zeroItems: $zeroItems
    onlyIds: $onlyIds
  ) {
    data {
      ...CategoryFragment
    }
    count
  }
}
    ${CategoryFragmentFragmentDoc}`;

/**
 * __useGetCategoriesListQuery__
 *
 * To run a query within a React component, call `useGetCategoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      iconFilter: // value for 'iconFilter'
 *      thumbFilter: // value for 'thumbFilter'
 *      sortBy: // value for 'sortBy'
 *      descriptionFilter: // value for 'descriptionFilter'
 *      items: // value for 'items'
 *      skills: // value for 'skills'
 *      active: // value for 'active'
 *      sortByDirection: // value for 'sortByDirection'
 *      zeroItems: // value for 'zeroItems'
 *      onlyIds: // value for 'onlyIds'
 *   },
 * });
 */
export function useGetCategoriesListQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesListQuery, GetCategoriesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesListQuery, GetCategoriesListQueryVariables>(GetCategoriesListDocument, options);
      }
export function useGetCategoriesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesListQuery, GetCategoriesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesListQuery, GetCategoriesListQueryVariables>(GetCategoriesListDocument, options);
        }
export type GetCategoriesListQueryHookResult = ReturnType<typeof useGetCategoriesListQuery>;
export type GetCategoriesListLazyQueryHookResult = ReturnType<typeof useGetCategoriesListLazyQuery>;
export type GetCategoriesListQueryResult = Apollo.QueryResult<GetCategoriesListQuery, GetCategoriesListQueryVariables>;
export const GetCurrentCategoryDocument = gql`
    query getCurrentCategory($id: ID, $slug: String) {
  skills(page: 1, limit: 1000) {
    data {
      id
      title
    }
  }
  category(id: $id, slug: $slug) {
    ...CategoryFragmentLight
    items {
      ...ItemFragment
    }
  }
}
    ${CategoryFragmentLightFragmentDoc}
${ItemFragmentFragmentDoc}`;

/**
 * __useGetCurrentCategoryQuery__
 *
 * To run a query within a React component, call `useGetCurrentCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCurrentCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCategoryQuery, GetCurrentCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCategoryQuery, GetCurrentCategoryQueryVariables>(GetCurrentCategoryDocument, options);
      }
export function useGetCurrentCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCategoryQuery, GetCurrentCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCategoryQuery, GetCurrentCategoryQueryVariables>(GetCurrentCategoryDocument, options);
        }
export type GetCurrentCategoryQueryHookResult = ReturnType<typeof useGetCurrentCategoryQuery>;
export type GetCurrentCategoryLazyQueryHookResult = ReturnType<typeof useGetCurrentCategoryLazyQuery>;
export type GetCurrentCategoryQueryResult = Apollo.QueryResult<GetCurrentCategoryQuery, GetCurrentCategoryQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($input: CategoryInput!) {
  createCategory(input: $input) {
    ...CategoryFragment
  }
}
    ${CategoryFragmentFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($id: ID!, $input: CategoryInput!) {
  updateCategory(id: $id, input: $input) {
    ...CategoryFragment
  }
}
    ${CategoryFragmentFragmentDoc}`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: ID!) {
  deleteCategory(id: $id)
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const SortCategoriesItemsDocument = gql`
    mutation sortCategoriesItems($id: ID!, $items: [ID!]!) {
  categorySortItems(id: $id, items: $items)
}
    `;
export type SortCategoriesItemsMutationFn = Apollo.MutationFunction<SortCategoriesItemsMutation, SortCategoriesItemsMutationVariables>;

/**
 * __useSortCategoriesItemsMutation__
 *
 * To run a mutation, you first call `useSortCategoriesItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortCategoriesItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortCategoriesItemsMutation, { data, loading, error }] = useSortCategoriesItemsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useSortCategoriesItemsMutation(baseOptions?: Apollo.MutationHookOptions<SortCategoriesItemsMutation, SortCategoriesItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortCategoriesItemsMutation, SortCategoriesItemsMutationVariables>(SortCategoriesItemsDocument, options);
      }
export type SortCategoriesItemsMutationHookResult = ReturnType<typeof useSortCategoriesItemsMutation>;
export type SortCategoriesItemsMutationResult = Apollo.MutationResult<SortCategoriesItemsMutation>;
export type SortCategoriesItemsMutationOptions = Apollo.BaseMutationOptions<SortCategoriesItemsMutation, SortCategoriesItemsMutationVariables>;
export const SaveSliderCategoriesDocument = gql`
    mutation saveSliderCategories($ids: [ID!]!) {
  sliderCategories(ids: $ids)
}
    `;
export type SaveSliderCategoriesMutationFn = Apollo.MutationFunction<SaveSliderCategoriesMutation, SaveSliderCategoriesMutationVariables>;

/**
 * __useSaveSliderCategoriesMutation__
 *
 * To run a mutation, you first call `useSaveSliderCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSliderCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSliderCategoriesMutation, { data, loading, error }] = useSaveSliderCategoriesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSaveSliderCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<SaveSliderCategoriesMutation, SaveSliderCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSliderCategoriesMutation, SaveSliderCategoriesMutationVariables>(SaveSliderCategoriesDocument, options);
      }
export type SaveSliderCategoriesMutationHookResult = ReturnType<typeof useSaveSliderCategoriesMutation>;
export type SaveSliderCategoriesMutationResult = Apollo.MutationResult<SaveSliderCategoriesMutation>;
export type SaveSliderCategoriesMutationOptions = Apollo.BaseMutationOptions<SaveSliderCategoriesMutation, SaveSliderCategoriesMutationVariables>;
export const UpscaleRelatedItems350CropTo700Document = gql`
    mutation upscaleRelatedItems350CropTo700($categoryID: ID!) {
  upscaleRelatedItems350CropTo700(categoryID: $categoryID)
}
    `;
export type UpscaleRelatedItems350CropTo700MutationFn = Apollo.MutationFunction<UpscaleRelatedItems350CropTo700Mutation, UpscaleRelatedItems350CropTo700MutationVariables>;

/**
 * __useUpscaleRelatedItems350CropTo700Mutation__
 *
 * To run a mutation, you first call `useUpscaleRelatedItems350CropTo700Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpscaleRelatedItems350CropTo700Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upscaleRelatedItems350CropTo700Mutation, { data, loading, error }] = useUpscaleRelatedItems350CropTo700Mutation({
 *   variables: {
 *      categoryID: // value for 'categoryID'
 *   },
 * });
 */
export function useUpscaleRelatedItems350CropTo700Mutation(baseOptions?: Apollo.MutationHookOptions<UpscaleRelatedItems350CropTo700Mutation, UpscaleRelatedItems350CropTo700MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpscaleRelatedItems350CropTo700Mutation, UpscaleRelatedItems350CropTo700MutationVariables>(UpscaleRelatedItems350CropTo700Document, options);
      }
export type UpscaleRelatedItems350CropTo700MutationHookResult = ReturnType<typeof useUpscaleRelatedItems350CropTo700Mutation>;
export type UpscaleRelatedItems350CropTo700MutationResult = Apollo.MutationResult<UpscaleRelatedItems350CropTo700Mutation>;
export type UpscaleRelatedItems350CropTo700MutationOptions = Apollo.BaseMutationOptions<UpscaleRelatedItems350CropTo700Mutation, UpscaleRelatedItems350CropTo700MutationVariables>;
export const AddCategoryFaqDocument = gql`
    mutation AddCategoryFAQ($input: CategoryFAQInput!) {
  addCategoryFAQ(input: $input) {
    id
    question
    answer
  }
}
    `;
export type AddCategoryFaqMutationFn = Apollo.MutationFunction<AddCategoryFaqMutation, AddCategoryFaqMutationVariables>;

/**
 * __useAddCategoryFaqMutation__
 *
 * To run a mutation, you first call `useAddCategoryFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryFaqMutation, { data, loading, error }] = useAddCategoryFaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCategoryFaqMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoryFaqMutation, AddCategoryFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoryFaqMutation, AddCategoryFaqMutationVariables>(AddCategoryFaqDocument, options);
      }
export type AddCategoryFaqMutationHookResult = ReturnType<typeof useAddCategoryFaqMutation>;
export type AddCategoryFaqMutationResult = Apollo.MutationResult<AddCategoryFaqMutation>;
export type AddCategoryFaqMutationOptions = Apollo.BaseMutationOptions<AddCategoryFaqMutation, AddCategoryFaqMutationVariables>;
export const UpdateCategoryFaqDocument = gql`
    mutation UpdateCategoryFAQ($id: ID!, $input: CategoryFAQInput!) {
  updateCategoryFAQ(id: $id, input: $input) {
    id
    question
    answer
  }
}
    `;
export type UpdateCategoryFaqMutationFn = Apollo.MutationFunction<UpdateCategoryFaqMutation, UpdateCategoryFaqMutationVariables>;

/**
 * __useUpdateCategoryFaqMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryFaqMutation, { data, loading, error }] = useUpdateCategoryFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryFaqMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryFaqMutation, UpdateCategoryFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryFaqMutation, UpdateCategoryFaqMutationVariables>(UpdateCategoryFaqDocument, options);
      }
export type UpdateCategoryFaqMutationHookResult = ReturnType<typeof useUpdateCategoryFaqMutation>;
export type UpdateCategoryFaqMutationResult = Apollo.MutationResult<UpdateCategoryFaqMutation>;
export type UpdateCategoryFaqMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryFaqMutation, UpdateCategoryFaqMutationVariables>;
export const DeleteCategoryFaqDocument = gql`
    mutation DeleteCategoryFAQ($id: ID!) {
  deleteCategoryFAQ(id: $id)
}
    `;
export type DeleteCategoryFaqMutationFn = Apollo.MutationFunction<DeleteCategoryFaqMutation, DeleteCategoryFaqMutationVariables>;

/**
 * __useDeleteCategoryFaqMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryFaqMutation, { data, loading, error }] = useDeleteCategoryFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryFaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryFaqMutation, DeleteCategoryFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryFaqMutation, DeleteCategoryFaqMutationVariables>(DeleteCategoryFaqDocument, options);
      }
export type DeleteCategoryFaqMutationHookResult = ReturnType<typeof useDeleteCategoryFaqMutation>;
export type DeleteCategoryFaqMutationResult = Apollo.MutationResult<DeleteCategoryFaqMutation>;
export type DeleteCategoryFaqMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryFaqMutation, DeleteCategoryFaqMutationVariables>;
export const GetCommonShortListDocument = gql`
    query getCommonShortList {
  skills(page: 1, limit: 1000) {
    data {
      id
      title
    }
  }
  categories(page: 1, limit: 1000) {
    data {
      id
      title
    }
  }
  companies(page: 1, limit: 1000) {
    data {
      id
      title
    }
  }
}
    `;

/**
 * __useGetCommonShortListQuery__
 *
 * To run a query within a React component, call `useGetCommonShortListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonShortListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonShortListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommonShortListQuery(baseOptions?: Apollo.QueryHookOptions<GetCommonShortListQuery, GetCommonShortListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommonShortListQuery, GetCommonShortListQueryVariables>(GetCommonShortListDocument, options);
      }
export function useGetCommonShortListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommonShortListQuery, GetCommonShortListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommonShortListQuery, GetCommonShortListQueryVariables>(GetCommonShortListDocument, options);
        }
export type GetCommonShortListQueryHookResult = ReturnType<typeof useGetCommonShortListQuery>;
export type GetCommonShortListLazyQueryHookResult = ReturnType<typeof useGetCommonShortListLazyQuery>;
export type GetCommonShortListQueryResult = Apollo.QueryResult<GetCommonShortListQuery, GetCommonShortListQueryVariables>;
export const GetCompaniesListDocument = gql`
    query getCompaniesList($page: Int, $limit: Int, $search: String, $iconFilter: WithWithout, $sortBy: SortBy, $sortByDirection: SortDirection) {
  companies(
    page: $page
    limit: $limit
    search: $search
    iconFilter: $iconFilter
    sortBy: $sortBy
    sortByDirection: $sortByDirection
  ) {
    data {
      slug
      id
      title
      icon {
        value
      }
      link {
        url
      }
      items {
        id
        title
      }
      seo {
        title
      }
    }
    count
  }
}
    `;

/**
 * __useGetCompaniesListQuery__
 *
 * To run a query within a React component, call `useGetCompaniesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      iconFilter: // value for 'iconFilter'
 *      sortBy: // value for 'sortBy'
 *      sortByDirection: // value for 'sortByDirection'
 *   },
 * });
 */
export function useGetCompaniesListQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesListQuery, GetCompaniesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesListQuery, GetCompaniesListQueryVariables>(GetCompaniesListDocument, options);
      }
export function useGetCompaniesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesListQuery, GetCompaniesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesListQuery, GetCompaniesListQueryVariables>(GetCompaniesListDocument, options);
        }
export type GetCompaniesListQueryHookResult = ReturnType<typeof useGetCompaniesListQuery>;
export type GetCompaniesListLazyQueryHookResult = ReturnType<typeof useGetCompaniesListLazyQuery>;
export type GetCompaniesListQueryResult = Apollo.QueryResult<GetCompaniesListQuery, GetCompaniesListQueryVariables>;
export const GetCompanyDocument = gql`
    query getCompany($id: ID, $slug: String) {
  company(id: $id, slug: $slug) {
    ...CompanyFragment
  }
}
    ${CompanyFragmentFragmentDoc}`;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: ID!) {
  deleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($slug: String!, $input: CompanyInput!) {
  updateCompany(slug: $slug, input: $input) {
    ...CompanyFragment
  }
}
    ${CompanyFragmentFragmentDoc}`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CompanyInput!) {
  createCompany(input: $input) {
    ...CompanyFragment
  }
}
    ${CompanyFragmentFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const GetCsvDataDocument = gql`
    query getCSVData {
  categories(limit: 0) {
    data {
      ...CategoryFragmentLight
    }
  }
  items(limit: 0) {
    data {
      ...ItemFragment
    }
  }
  dynamicModels(entity: Item) {
    name
    id
    type
    fields {
      type
      name
      id
    }
    variants {
      id
      value
    }
  }
}
    ${CategoryFragmentLightFragmentDoc}
${ItemFragmentFragmentDoc}`;

/**
 * __useGetCsvDataQuery__
 *
 * To run a query within a React component, call `useGetCsvDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsvDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsvDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCsvDataQuery(baseOptions?: Apollo.QueryHookOptions<GetCsvDataQuery, GetCsvDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCsvDataQuery, GetCsvDataQueryVariables>(GetCsvDataDocument, options);
      }
export function useGetCsvDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCsvDataQuery, GetCsvDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCsvDataQuery, GetCsvDataQueryVariables>(GetCsvDataDocument, options);
        }
export type GetCsvDataQueryHookResult = ReturnType<typeof useGetCsvDataQuery>;
export type GetCsvDataLazyQueryHookResult = ReturnType<typeof useGetCsvDataLazyQuery>;
export type GetCsvDataQueryResult = Apollo.QueryResult<GetCsvDataQuery, GetCsvDataQueryVariables>;
export const DashboardOverviewDocument = gql`
    query DashboardOverview($days: Int!) {
  overview(days: $days) {
    date
    sessions
    avgSessionDuration
    pageviews
    bounceRate
  }
}
    `;

/**
 * __useDashboardOverviewQuery__
 *
 * To run a query within a React component, call `useDashboardOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardOverviewQuery({
 *   variables: {
 *      days: // value for 'days'
 *   },
 * });
 */
export function useDashboardOverviewQuery(baseOptions: Apollo.QueryHookOptions<DashboardOverviewQuery, DashboardOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardOverviewQuery, DashboardOverviewQueryVariables>(DashboardOverviewDocument, options);
      }
export function useDashboardOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardOverviewQuery, DashboardOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardOverviewQuery, DashboardOverviewQueryVariables>(DashboardOverviewDocument, options);
        }
export type DashboardOverviewQueryHookResult = ReturnType<typeof useDashboardOverviewQuery>;
export type DashboardOverviewLazyQueryHookResult = ReturnType<typeof useDashboardOverviewLazyQuery>;
export type DashboardOverviewQueryResult = Apollo.QueryResult<DashboardOverviewQuery, DashboardOverviewQueryVariables>;
export const DashboardGlanceDocument = gql`
    query DashboardGlance($date: Date) {
  glance(date: $date) {
    date
    sessions
    avgSessionDuration
    pageviews
    bounceRate
  }
}
    `;

/**
 * __useDashboardGlanceQuery__
 *
 * To run a query within a React component, call `useDashboardGlanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardGlanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardGlanceQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDashboardGlanceQuery(baseOptions?: Apollo.QueryHookOptions<DashboardGlanceQuery, DashboardGlanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardGlanceQuery, DashboardGlanceQueryVariables>(DashboardGlanceDocument, options);
      }
export function useDashboardGlanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardGlanceQuery, DashboardGlanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardGlanceQuery, DashboardGlanceQueryVariables>(DashboardGlanceDocument, options);
        }
export type DashboardGlanceQueryHookResult = ReturnType<typeof useDashboardGlanceQuery>;
export type DashboardGlanceLazyQueryHookResult = ReturnType<typeof useDashboardGlanceLazyQuery>;
export type DashboardGlanceQueryResult = Apollo.QueryResult<DashboardGlanceQuery, DashboardGlanceQueryVariables>;
export const DashboardReportsDocument = gql`
    query DashboardReports($dateStart: Date!, $dateEnd: Date!) {
  keywords(dateStart: $dateStart, dateEnd: $dateEnd) {
    title
    impression
    clicks
    position
  }
  content(dateStart: $dateStart, dateEnd: $dateEnd) {
    pageName
    searchTraffic
    impressions
    avgCTR
    position
    pageId
    pageType
  }
  topPages(dateStart: $dateStart, dateEnd: $dateEnd) {
    title
    visitors
  }
  countries(dateStart: $dateStart, dateEnd: $dateEnd) {
    title
    searchTraffic
    position
    impressions
    avgCTR
  }
  deviceBreakdown(dateStart: $dateStart, dateEnd: $dateEnd) {
    desktop
    tablet
    mobile
  }
  visitors(dateStart: $dateStart, dateEnd: $dateEnd) {
    new
    returned
  }
}
    `;

/**
 * __useDashboardReportsQuery__
 *
 * To run a query within a React component, call `useDashboardReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardReportsQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useDashboardReportsQuery(baseOptions: Apollo.QueryHookOptions<DashboardReportsQuery, DashboardReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardReportsQuery, DashboardReportsQueryVariables>(DashboardReportsDocument, options);
      }
export function useDashboardReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardReportsQuery, DashboardReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardReportsQuery, DashboardReportsQueryVariables>(DashboardReportsDocument, options);
        }
export type DashboardReportsQueryHookResult = ReturnType<typeof useDashboardReportsQuery>;
export type DashboardReportsLazyQueryHookResult = ReturnType<typeof useDashboardReportsLazyQuery>;
export type DashboardReportsQueryResult = Apollo.QueryResult<DashboardReportsQuery, DashboardReportsQueryVariables>;
export const CategoryFeaturesDocument = gql`
    query CategoryFeatures {
  categories(limit: 0, sortBy: title, active: true) {
    data {
      id
      title
      features {
        ...FeatureFragment
      }
    }
  }
}
    ${FeatureFragmentFragmentDoc}`;

/**
 * __useCategoryFeaturesQuery__
 *
 * To run a query within a React component, call `useCategoryFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<CategoryFeaturesQuery, CategoryFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryFeaturesQuery, CategoryFeaturesQueryVariables>(CategoryFeaturesDocument, options);
      }
export function useCategoryFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryFeaturesQuery, CategoryFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryFeaturesQuery, CategoryFeaturesQueryVariables>(CategoryFeaturesDocument, options);
        }
export type CategoryFeaturesQueryHookResult = ReturnType<typeof useCategoryFeaturesQuery>;
export type CategoryFeaturesLazyQueryHookResult = ReturnType<typeof useCategoryFeaturesLazyQuery>;
export type CategoryFeaturesQueryResult = Apollo.QueryResult<CategoryFeaturesQuery, CategoryFeaturesQueryVariables>;
export const AddFeatureDocument = gql`
    mutation AddFeature($input: FeatureInput!) {
  addFeature(input: $input) {
    ...FeatureFragment
    categoryID
  }
}
    ${FeatureFragmentFragmentDoc}`;
export type AddFeatureMutationFn = Apollo.MutationFunction<AddFeatureMutation, AddFeatureMutationVariables>;

/**
 * __useAddFeatureMutation__
 *
 * To run a mutation, you first call `useAddFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeatureMutation, { data, loading, error }] = useAddFeatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFeatureMutation(baseOptions?: Apollo.MutationHookOptions<AddFeatureMutation, AddFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFeatureMutation, AddFeatureMutationVariables>(AddFeatureDocument, options);
      }
export type AddFeatureMutationHookResult = ReturnType<typeof useAddFeatureMutation>;
export type AddFeatureMutationResult = Apollo.MutationResult<AddFeatureMutation>;
export type AddFeatureMutationOptions = Apollo.BaseMutationOptions<AddFeatureMutation, AddFeatureMutationVariables>;
export const UpdateFeatureDocument = gql`
    mutation UpdateFeature($id: ID!, $input: FeatureUpdateInput!) {
  updateFeature(id: $id, input: $input) {
    ...FeatureFragment
  }
}
    ${FeatureFragmentFragmentDoc}`;
export type UpdateFeatureMutationFn = Apollo.MutationFunction<UpdateFeatureMutation, UpdateFeatureMutationVariables>;

/**
 * __useUpdateFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureMutation, { data, loading, error }] = useUpdateFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeatureMutation, UpdateFeatureMutationVariables>(UpdateFeatureDocument, options);
      }
export type UpdateFeatureMutationHookResult = ReturnType<typeof useUpdateFeatureMutation>;
export type UpdateFeatureMutationResult = Apollo.MutationResult<UpdateFeatureMutation>;
export type UpdateFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>;
export const DeleteFeatureDocument = gql`
    mutation DeleteFeature($id: ID!) {
  deleteFeature(id: $id)
}
    `;
export type DeleteFeatureMutationFn = Apollo.MutationFunction<DeleteFeatureMutation, DeleteFeatureMutationVariables>;

/**
 * __useDeleteFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeatureMutation, { data, loading, error }] = useDeleteFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeatureMutation, DeleteFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeatureMutation, DeleteFeatureMutationVariables>(DeleteFeatureDocument, options);
      }
export type DeleteFeatureMutationHookResult = ReturnType<typeof useDeleteFeatureMutation>;
export type DeleteFeatureMutationResult = Apollo.MutationResult<DeleteFeatureMutation>;
export type DeleteFeatureMutationOptions = Apollo.BaseMutationOptions<DeleteFeatureMutation, DeleteFeatureMutationVariables>;
export const GetImageOriginUrlDocument = gql`
    query getImageOriginUrl($imageID: ID!) {
  getImageOriginUrl(imageID: $imageID)
}
    `;

/**
 * __useGetImageOriginUrlQuery__
 *
 * To run a query within a React component, call `useGetImageOriginUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageOriginUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageOriginUrlQuery({
 *   variables: {
 *      imageID: // value for 'imageID'
 *   },
 * });
 */
export function useGetImageOriginUrlQuery(baseOptions: Apollo.QueryHookOptions<GetImageOriginUrlQuery, GetImageOriginUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetImageOriginUrlQuery, GetImageOriginUrlQueryVariables>(GetImageOriginUrlDocument, options);
      }
export function useGetImageOriginUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImageOriginUrlQuery, GetImageOriginUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetImageOriginUrlQuery, GetImageOriginUrlQueryVariables>(GetImageOriginUrlDocument, options);
        }
export type GetImageOriginUrlQueryHookResult = ReturnType<typeof useGetImageOriginUrlQuery>;
export type GetImageOriginUrlLazyQueryHookResult = ReturnType<typeof useGetImageOriginUrlLazyQuery>;
export type GetImageOriginUrlQueryResult = Apollo.QueryResult<GetImageOriginUrlQuery, GetImageOriginUrlQueryVariables>;
export const UploadImageDocument = gql`
    mutation uploadImage($input: UploadInput!) {
  uploadImage(input: $input) {
    ...ImageFragment
  }
}
    ${ImageFragmentFragmentDoc}`;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UploadCropImageDocument = gql`
    mutation uploadCropImage($input: UploadCropInput!) {
  uploadCropImage(input: $input) {
    ...ImageFragment
  }
}
    ${ImageFragmentFragmentDoc}`;
export type UploadCropImageMutationFn = Apollo.MutationFunction<UploadCropImageMutation, UploadCropImageMutationVariables>;

/**
 * __useUploadCropImageMutation__
 *
 * To run a mutation, you first call `useUploadCropImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCropImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCropImageMutation, { data, loading, error }] = useUploadCropImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadCropImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadCropImageMutation, UploadCropImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCropImageMutation, UploadCropImageMutationVariables>(UploadCropImageDocument, options);
      }
export type UploadCropImageMutationHookResult = ReturnType<typeof useUploadCropImageMutation>;
export type UploadCropImageMutationResult = Apollo.MutationResult<UploadCropImageMutation>;
export type UploadCropImageMutationOptions = Apollo.BaseMutationOptions<UploadCropImageMutation, UploadCropImageMutationVariables>;
export const ScreenshotCreateDocument = gql`
    mutation screenshotCreate($url: String!, $sizes: [Resize!], $fullPage: Boolean) {
  screenshotCreate(url: $url, sizes: $sizes, fullPage: $fullPage) {
    ...ImageFragment
    positions {
      x
      y
      width
      height
    }
  }
}
    ${ImageFragmentFragmentDoc}`;
export type ScreenshotCreateMutationFn = Apollo.MutationFunction<ScreenshotCreateMutation, ScreenshotCreateMutationVariables>;

/**
 * __useScreenshotCreateMutation__
 *
 * To run a mutation, you first call `useScreenshotCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScreenshotCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [screenshotCreateMutation, { data, loading, error }] = useScreenshotCreateMutation({
 *   variables: {
 *      url: // value for 'url'
 *      sizes: // value for 'sizes'
 *      fullPage: // value for 'fullPage'
 *   },
 * });
 */
export function useScreenshotCreateMutation(baseOptions?: Apollo.MutationHookOptions<ScreenshotCreateMutation, ScreenshotCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScreenshotCreateMutation, ScreenshotCreateMutationVariables>(ScreenshotCreateDocument, options);
      }
export type ScreenshotCreateMutationHookResult = ReturnType<typeof useScreenshotCreateMutation>;
export type ScreenshotCreateMutationResult = Apollo.MutationResult<ScreenshotCreateMutation>;
export type ScreenshotCreateMutationOptions = Apollo.BaseMutationOptions<ScreenshotCreateMutation, ScreenshotCreateMutationVariables>;
export const IconCreateDocument = gql`
    mutation iconCreate($url: String!, $sizes: [Int!]!) {
  iconCreate(url: $url, sizes: $sizes) {
    id
    size
    value
  }
}
    `;
export type IconCreateMutationFn = Apollo.MutationFunction<IconCreateMutation, IconCreateMutationVariables>;

/**
 * __useIconCreateMutation__
 *
 * To run a mutation, you first call `useIconCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIconCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iconCreateMutation, { data, loading, error }] = useIconCreateMutation({
 *   variables: {
 *      url: // value for 'url'
 *      sizes: // value for 'sizes'
 *   },
 * });
 */
export function useIconCreateMutation(baseOptions?: Apollo.MutationHookOptions<IconCreateMutation, IconCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IconCreateMutation, IconCreateMutationVariables>(IconCreateDocument, options);
      }
export type IconCreateMutationHookResult = ReturnType<typeof useIconCreateMutation>;
export type IconCreateMutationResult = Apollo.MutationResult<IconCreateMutation>;
export type IconCreateMutationOptions = Apollo.BaseMutationOptions<IconCreateMutation, IconCreateMutationVariables>;
export const RegenerateLikesDocument = gql`
    mutation RegenerateLikes($ids: [ID!]) {
  generateCategoryLikes(ids: $ids)
}
    `;
export type RegenerateLikesMutationFn = Apollo.MutationFunction<RegenerateLikesMutation, RegenerateLikesMutationVariables>;

/**
 * __useRegenerateLikesMutation__
 *
 * To run a mutation, you first call `useRegenerateLikesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateLikesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateLikesMutation, { data, loading, error }] = useRegenerateLikesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRegenerateLikesMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateLikesMutation, RegenerateLikesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateLikesMutation, RegenerateLikesMutationVariables>(RegenerateLikesDocument, options);
      }
export type RegenerateLikesMutationHookResult = ReturnType<typeof useRegenerateLikesMutation>;
export type RegenerateLikesMutationResult = Apollo.MutationResult<RegenerateLikesMutation>;
export type RegenerateLikesMutationOptions = Apollo.BaseMutationOptions<RegenerateLikesMutation, RegenerateLikesMutationVariables>;
export const GetItemsSuperLightDocument = gql`
    query getItemsSuperLight($page: Int, $limit: Int, $search: String) {
  items(page: $page, limit: $limit, search: $search) {
    data {
      id
      title
      shortDescription
      longDescription
      slug
      seo {
        title
        description
      }
      pros
      cons
    }
    count
  }
}
    `;

/**
 * __useGetItemsSuperLightQuery__
 *
 * To run a query within a React component, call `useGetItemsSuperLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsSuperLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsSuperLightQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetItemsSuperLightQuery(baseOptions?: Apollo.QueryHookOptions<GetItemsSuperLightQuery, GetItemsSuperLightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemsSuperLightQuery, GetItemsSuperLightQueryVariables>(GetItemsSuperLightDocument, options);
      }
export function useGetItemsSuperLightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemsSuperLightQuery, GetItemsSuperLightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemsSuperLightQuery, GetItemsSuperLightQueryVariables>(GetItemsSuperLightDocument, options);
        }
export type GetItemsSuperLightQueryHookResult = ReturnType<typeof useGetItemsSuperLightQuery>;
export type GetItemsSuperLightLazyQueryHookResult = ReturnType<typeof useGetItemsSuperLightLazyQuery>;
export type GetItemsSuperLightQueryResult = Apollo.QueryResult<GetItemsSuperLightQuery, GetItemsSuperLightQueryVariables>;
export const GetItemsDocument = gql`
    query getItems($page: Int, $limit: Int, $search: String, $sortBy: SortBy, $sortByDirection: SortDirection, $iconFilter: WithWithout, $thumbFilter: WithWithout, $descriptionFilter: WithWithout, $active: Boolean, $categories: [ID!], $skills: [ID!], $companies: [ID!], $uploadedByUser: Boolean) {
  items(
    page: $page
    limit: $limit
    search: $search
    iconFilter: $iconFilter
    thumbFilter: $thumbFilter
    descriptionFilter: $descriptionFilter
    sortBy: $sortBy
    categories: $categories
    sortByDirection: $sortByDirection
    active: $active
    skills: $skills
    companies: $companies
    uploadedByUser: $uploadedByUser
  ) {
    data {
      ...ItemFragment
    }
    count
  }
}
    ${ItemFragmentFragmentDoc}`;

/**
 * __useGetItemsQuery__
 *
 * To run a query within a React component, call `useGetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      sortByDirection: // value for 'sortByDirection'
 *      iconFilter: // value for 'iconFilter'
 *      thumbFilter: // value for 'thumbFilter'
 *      descriptionFilter: // value for 'descriptionFilter'
 *      active: // value for 'active'
 *      categories: // value for 'categories'
 *      skills: // value for 'skills'
 *      companies: // value for 'companies'
 *      uploadedByUser: // value for 'uploadedByUser'
 *   },
 * });
 */
export function useGetItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
      }
export function useGetItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
        }
export type GetItemsQueryHookResult = ReturnType<typeof useGetItemsQuery>;
export type GetItemsLazyQueryHookResult = ReturnType<typeof useGetItemsLazyQuery>;
export type GetItemsQueryResult = Apollo.QueryResult<GetItemsQuery, GetItemsQueryVariables>;
export const GetCurrentItemDocument = gql`
    query getCurrentItem($id: ID, $slug: String) {
  item(id: $id, slug: $slug) {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;

/**
 * __useGetCurrentItemQuery__
 *
 * To run a query within a React component, call `useGetCurrentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCurrentItemQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentItemQuery, GetCurrentItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentItemQuery, GetCurrentItemQueryVariables>(GetCurrentItemDocument, options);
      }
export function useGetCurrentItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentItemQuery, GetCurrentItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentItemQuery, GetCurrentItemQueryVariables>(GetCurrentItemDocument, options);
        }
export type GetCurrentItemQueryHookResult = ReturnType<typeof useGetCurrentItemQuery>;
export type GetCurrentItemLazyQueryHookResult = ReturnType<typeof useGetCurrentItemLazyQuery>;
export type GetCurrentItemQueryResult = Apollo.QueryResult<GetCurrentItemQuery, GetCurrentItemQueryVariables>;
export const GetDeletedItemsDocument = gql`
    query getDeletedItems {
  deletedItems(page: 1, limit: 0) {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;

/**
 * __useGetDeletedItemsQuery__
 *
 * To run a query within a React component, call `useGetDeletedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeletedItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetDeletedItemsQuery, GetDeletedItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeletedItemsQuery, GetDeletedItemsQueryVariables>(GetDeletedItemsDocument, options);
      }
export function useGetDeletedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedItemsQuery, GetDeletedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeletedItemsQuery, GetDeletedItemsQueryVariables>(GetDeletedItemsDocument, options);
        }
export type GetDeletedItemsQueryHookResult = ReturnType<typeof useGetDeletedItemsQuery>;
export type GetDeletedItemsLazyQueryHookResult = ReturnType<typeof useGetDeletedItemsLazyQuery>;
export type GetDeletedItemsQueryResult = Apollo.QueryResult<GetDeletedItemsQuery, GetDeletedItemsQueryVariables>;
export const CreateItemDocument = gql`
    mutation createItem($input: ItemInput!) {
  createItem(input: $input) {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;
export type CreateItemMutationFn = Apollo.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($id: ID!, $input: ItemInput!) {
  updateItem(id: $id, input: $input) {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const DeleteItemDocument = gql`
    mutation deleteItem($id: ID!) {
  deleteItem(id: $id)
}
    `;
export type DeleteItemMutationFn = Apollo.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, options);
      }
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = Apollo.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const PreviewItemDocument = gql`
    mutation previewItem($input: ItemInput!) {
  preview(input: $input) {
    slug
  }
}
    `;
export type PreviewItemMutationFn = Apollo.MutationFunction<PreviewItemMutation, PreviewItemMutationVariables>;

/**
 * __usePreviewItemMutation__
 *
 * To run a mutation, you first call `usePreviewItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewItemMutation, { data, loading, error }] = usePreviewItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewItemMutation(baseOptions?: Apollo.MutationHookOptions<PreviewItemMutation, PreviewItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreviewItemMutation, PreviewItemMutationVariables>(PreviewItemDocument, options);
      }
export type PreviewItemMutationHookResult = ReturnType<typeof usePreviewItemMutation>;
export type PreviewItemMutationResult = Apollo.MutationResult<PreviewItemMutation>;
export type PreviewItemMutationOptions = Apollo.BaseMutationOptions<PreviewItemMutation, PreviewItemMutationVariables>;
export const UpdateFaviconsDocument = gql`
    mutation updateFavicons($ids: [ID!]) {
  updateFavicons(ids: $ids)
}
    `;
export type UpdateFaviconsMutationFn = Apollo.MutationFunction<UpdateFaviconsMutation, UpdateFaviconsMutationVariables>;

/**
 * __useUpdateFaviconsMutation__
 *
 * To run a mutation, you first call `useUpdateFaviconsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaviconsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaviconsMutation, { data, loading, error }] = useUpdateFaviconsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateFaviconsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFaviconsMutation, UpdateFaviconsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFaviconsMutation, UpdateFaviconsMutationVariables>(UpdateFaviconsDocument, options);
      }
export type UpdateFaviconsMutationHookResult = ReturnType<typeof useUpdateFaviconsMutation>;
export type UpdateFaviconsMutationResult = Apollo.MutationResult<UpdateFaviconsMutation>;
export type UpdateFaviconsMutationOptions = Apollo.BaseMutationOptions<UpdateFaviconsMutation, UpdateFaviconsMutationVariables>;
export const RecreateScreenShotsDocument = gql`
    mutation recreateScreenShots($categoryID: ID!, $sizes: [Int!]!) {
  recreateScreenShots(categoryID: $categoryID, sizes: $sizes)
}
    `;
export type RecreateScreenShotsMutationFn = Apollo.MutationFunction<RecreateScreenShotsMutation, RecreateScreenShotsMutationVariables>;

/**
 * __useRecreateScreenShotsMutation__
 *
 * To run a mutation, you first call `useRecreateScreenShotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecreateScreenShotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recreateScreenShotsMutation, { data, loading, error }] = useRecreateScreenShotsMutation({
 *   variables: {
 *      categoryID: // value for 'categoryID'
 *      sizes: // value for 'sizes'
 *   },
 * });
 */
export function useRecreateScreenShotsMutation(baseOptions?: Apollo.MutationHookOptions<RecreateScreenShotsMutation, RecreateScreenShotsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecreateScreenShotsMutation, RecreateScreenShotsMutationVariables>(RecreateScreenShotsDocument, options);
      }
export type RecreateScreenShotsMutationHookResult = ReturnType<typeof useRecreateScreenShotsMutation>;
export type RecreateScreenShotsMutationResult = Apollo.MutationResult<RecreateScreenShotsMutation>;
export type RecreateScreenShotsMutationOptions = Apollo.BaseMutationOptions<RecreateScreenShotsMutation, RecreateScreenShotsMutationVariables>;
export const RestoreItemDocument = gql`
    mutation restoreItem($id: ID!) {
  restoreItem(id: $id) {
    ...ItemFragment
  }
}
    ${ItemFragmentFragmentDoc}`;
export type RestoreItemMutationFn = Apollo.MutationFunction<RestoreItemMutation, RestoreItemMutationVariables>;

/**
 * __useRestoreItemMutation__
 *
 * To run a mutation, you first call `useRestoreItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreItemMutation, { data, loading, error }] = useRestoreItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreItemMutation(baseOptions?: Apollo.MutationHookOptions<RestoreItemMutation, RestoreItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreItemMutation, RestoreItemMutationVariables>(RestoreItemDocument, options);
      }
export type RestoreItemMutationHookResult = ReturnType<typeof useRestoreItemMutation>;
export type RestoreItemMutationResult = Apollo.MutationResult<RestoreItemMutation>;
export type RestoreItemMutationOptions = Apollo.BaseMutationOptions<RestoreItemMutation, RestoreItemMutationVariables>;
export const AddItemFaqDocument = gql`
    mutation addItemFAQ($input: ItemFAQInput!) {
  addItemFAQ(input: $input) {
    id
    question
    answer
  }
}
    `;
export type AddItemFaqMutationFn = Apollo.MutationFunction<AddItemFaqMutation, AddItemFaqMutationVariables>;

/**
 * __useAddItemFaqMutation__
 *
 * To run a mutation, you first call `useAddItemFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemFaqMutation, { data, loading, error }] = useAddItemFaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddItemFaqMutation(baseOptions?: Apollo.MutationHookOptions<AddItemFaqMutation, AddItemFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddItemFaqMutation, AddItemFaqMutationVariables>(AddItemFaqDocument, options);
      }
export type AddItemFaqMutationHookResult = ReturnType<typeof useAddItemFaqMutation>;
export type AddItemFaqMutationResult = Apollo.MutationResult<AddItemFaqMutation>;
export type AddItemFaqMutationOptions = Apollo.BaseMutationOptions<AddItemFaqMutation, AddItemFaqMutationVariables>;
export const UpdateItemFaqDocument = gql`
    mutation updateItemFAQ($id: ID!, $input: ItemFAQInput!) {
  updateItemFAQ(id: $id, input: $input) {
    id
    question
    answer
  }
}
    `;
export type UpdateItemFaqMutationFn = Apollo.MutationFunction<UpdateItemFaqMutation, UpdateItemFaqMutationVariables>;

/**
 * __useUpdateItemFaqMutation__
 *
 * To run a mutation, you first call `useUpdateItemFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemFaqMutation, { data, loading, error }] = useUpdateItemFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemFaqMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemFaqMutation, UpdateItemFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemFaqMutation, UpdateItemFaqMutationVariables>(UpdateItemFaqDocument, options);
      }
export type UpdateItemFaqMutationHookResult = ReturnType<typeof useUpdateItemFaqMutation>;
export type UpdateItemFaqMutationResult = Apollo.MutationResult<UpdateItemFaqMutation>;
export type UpdateItemFaqMutationOptions = Apollo.BaseMutationOptions<UpdateItemFaqMutation, UpdateItemFaqMutationVariables>;
export const DeleteItemFaqDocument = gql`
    mutation deleteItemFAQ($id: ID!) {
  deleteItemFAQ(id: $id)
}
    `;
export type DeleteItemFaqMutationFn = Apollo.MutationFunction<DeleteItemFaqMutation, DeleteItemFaqMutationVariables>;

/**
 * __useDeleteItemFaqMutation__
 *
 * To run a mutation, you first call `useDeleteItemFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemFaqMutation, { data, loading, error }] = useDeleteItemFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemFaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemFaqMutation, DeleteItemFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemFaqMutation, DeleteItemFaqMutationVariables>(DeleteItemFaqDocument, options);
      }
export type DeleteItemFaqMutationHookResult = ReturnType<typeof useDeleteItemFaqMutation>;
export type DeleteItemFaqMutationResult = Apollo.MutationResult<DeleteItemFaqMutation>;
export type DeleteItemFaqMutationOptions = Apollo.BaseMutationOptions<DeleteItemFaqMutation, DeleteItemFaqMutationVariables>;
export const SubmitToolApproveDocument = gql`
    mutation submitToolApprove($id: ID!, $value: Boolean!, $reasons: [String!]) {
  submitToolApprove(id: $id, value: $value, reasons: $reasons)
}
    `;
export type SubmitToolApproveMutationFn = Apollo.MutationFunction<SubmitToolApproveMutation, SubmitToolApproveMutationVariables>;

/**
 * __useSubmitToolApproveMutation__
 *
 * To run a mutation, you first call `useSubmitToolApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitToolApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitToolApproveMutation, { data, loading, error }] = useSubmitToolApproveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      reasons: // value for 'reasons'
 *   },
 * });
 */
export function useSubmitToolApproveMutation(baseOptions?: Apollo.MutationHookOptions<SubmitToolApproveMutation, SubmitToolApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitToolApproveMutation, SubmitToolApproveMutationVariables>(SubmitToolApproveDocument, options);
      }
export type SubmitToolApproveMutationHookResult = ReturnType<typeof useSubmitToolApproveMutation>;
export type SubmitToolApproveMutationResult = Apollo.MutationResult<SubmitToolApproveMutation>;
export type SubmitToolApproveMutationOptions = Apollo.BaseMutationOptions<SubmitToolApproveMutation, SubmitToolApproveMutationVariables>;
export const SubmitToolCategoryApprovalDocument = gql`
    mutation submitToolCategoryApproval($id: ID!, $value: Boolean!) {
  submitToolCategoryApproval(id: $id, value: $value)
}
    `;
export type SubmitToolCategoryApprovalMutationFn = Apollo.MutationFunction<SubmitToolCategoryApprovalMutation, SubmitToolCategoryApprovalMutationVariables>;

/**
 * __useSubmitToolCategoryApprovalMutation__
 *
 * To run a mutation, you first call `useSubmitToolCategoryApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitToolCategoryApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitToolCategoryApprovalMutation, { data, loading, error }] = useSubmitToolCategoryApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSubmitToolCategoryApprovalMutation(baseOptions?: Apollo.MutationHookOptions<SubmitToolCategoryApprovalMutation, SubmitToolCategoryApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitToolCategoryApprovalMutation, SubmitToolCategoryApprovalMutationVariables>(SubmitToolCategoryApprovalDocument, options);
      }
export type SubmitToolCategoryApprovalMutationHookResult = ReturnType<typeof useSubmitToolCategoryApprovalMutation>;
export type SubmitToolCategoryApprovalMutationResult = Apollo.MutationResult<SubmitToolCategoryApprovalMutation>;
export type SubmitToolCategoryApprovalMutationOptions = Apollo.BaseMutationOptions<SubmitToolCategoryApprovalMutation, SubmitToolCategoryApprovalMutationVariables>;
export const GetLabelsDocument = gql`
    query GetLabels {
  labels {
    id
    name
    icon
  }
}
    `;

/**
 * __useGetLabelsQuery__
 *
 * To run a query within a React component, call `useGetLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLabelsQuery(baseOptions?: Apollo.QueryHookOptions<GetLabelsQuery, GetLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelsQuery, GetLabelsQueryVariables>(GetLabelsDocument, options);
      }
export function useGetLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelsQuery, GetLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelsQuery, GetLabelsQueryVariables>(GetLabelsDocument, options);
        }
export type GetLabelsQueryHookResult = ReturnType<typeof useGetLabelsQuery>;
export type GetLabelsLazyQueryHookResult = ReturnType<typeof useGetLabelsLazyQuery>;
export type GetLabelsQueryResult = Apollo.QueryResult<GetLabelsQuery, GetLabelsQueryVariables>;
export const CreateLabelDocument = gql`
    mutation CreateLabel($input: LabelInput!) {
  createLabel(input: $input) {
    id
    name
    icon
  }
}
    `;
export type CreateLabelMutationFn = Apollo.MutationFunction<CreateLabelMutation, CreateLabelMutationVariables>;

/**
 * __useCreateLabelMutation__
 *
 * To run a mutation, you first call `useCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelMutation, { data, loading, error }] = useCreateLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLabelMutation(baseOptions?: Apollo.MutationHookOptions<CreateLabelMutation, CreateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLabelMutation, CreateLabelMutationVariables>(CreateLabelDocument, options);
      }
export type CreateLabelMutationHookResult = ReturnType<typeof useCreateLabelMutation>;
export type CreateLabelMutationResult = Apollo.MutationResult<CreateLabelMutation>;
export type CreateLabelMutationOptions = Apollo.BaseMutationOptions<CreateLabelMutation, CreateLabelMutationVariables>;
export const UpdateLabelDocument = gql`
    mutation UpdateLabel($id: ID!, $input: LabelInput!) {
  updateLabel(id: $id, input: $input) {
    id
    name
    icon
  }
}
    `;
export type UpdateLabelMutationFn = Apollo.MutationFunction<UpdateLabelMutation, UpdateLabelMutationVariables>;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLabelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLabelMutation, UpdateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLabelMutation, UpdateLabelMutationVariables>(UpdateLabelDocument, options);
      }
export type UpdateLabelMutationHookResult = ReturnType<typeof useUpdateLabelMutation>;
export type UpdateLabelMutationResult = Apollo.MutationResult<UpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<UpdateLabelMutation, UpdateLabelMutationVariables>;
export const DeleteLabelDocument = gql`
    mutation DeleteLabel($id: ID!) {
  deleteLabel(id: $id)
}
    `;
export type DeleteLabelMutationFn = Apollo.MutationFunction<DeleteLabelMutation, DeleteLabelMutationVariables>;

/**
 * __useDeleteLabelMutation__
 *
 * To run a mutation, you first call `useDeleteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabelMutation, { data, loading, error }] = useDeleteLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLabelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLabelMutation, DeleteLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLabelMutation, DeleteLabelMutationVariables>(DeleteLabelDocument, options);
      }
export type DeleteLabelMutationHookResult = ReturnType<typeof useDeleteLabelMutation>;
export type DeleteLabelMutationResult = Apollo.MutationResult<DeleteLabelMutation>;
export type DeleteLabelMutationOptions = Apollo.BaseMutationOptions<DeleteLabelMutation, DeleteLabelMutationVariables>;
export const ActiveModelsDocument = gql`
    query activeModels {
  activeModels {
    company
    item
    skill
    category
    page
    sort
    blog
  }
}
    `;

/**
 * __useActiveModelsQuery__
 *
 * To run a query within a React component, call `useActiveModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveModelsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveModelsQuery, ActiveModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveModelsQuery, ActiveModelsQueryVariables>(ActiveModelsDocument, options);
      }
export function useActiveModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveModelsQuery, ActiveModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveModelsQuery, ActiveModelsQueryVariables>(ActiveModelsDocument, options);
        }
export type ActiveModelsQueryHookResult = ReturnType<typeof useActiveModelsQuery>;
export type ActiveModelsLazyQueryHookResult = ReturnType<typeof useActiveModelsLazyQuery>;
export type ActiveModelsQueryResult = Apollo.QueryResult<ActiveModelsQuery, ActiveModelsQueryVariables>;
export const CompanyFieldsDocument = gql`
    query companyFields {
  companyFields {
    icon
    link
    social
    seo
  }
}
    `;

/**
 * __useCompanyFieldsQuery__
 *
 * To run a query within a React component, call `useCompanyFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyFieldsQuery(baseOptions?: Apollo.QueryHookOptions<CompanyFieldsQuery, CompanyFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyFieldsQuery, CompanyFieldsQueryVariables>(CompanyFieldsDocument, options);
      }
export function useCompanyFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyFieldsQuery, CompanyFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyFieldsQuery, CompanyFieldsQueryVariables>(CompanyFieldsDocument, options);
        }
export type CompanyFieldsQueryHookResult = ReturnType<typeof useCompanyFieldsQuery>;
export type CompanyFieldsLazyQueryHookResult = ReturnType<typeof useCompanyFieldsLazyQuery>;
export type CompanyFieldsQueryResult = Apollo.QueryResult<CompanyFieldsQuery, CompanyFieldsQueryVariables>;
export const BlogFieldsDocument = gql`
    query blogFields {
  dynamicModels(entity: Blog) {
    name
    id
  }
}
    `;

/**
 * __useBlogFieldsQuery__
 *
 * To run a query within a React component, call `useBlogFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogFieldsQuery(baseOptions?: Apollo.QueryHookOptions<BlogFieldsQuery, BlogFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogFieldsQuery, BlogFieldsQueryVariables>(BlogFieldsDocument, options);
      }
export function useBlogFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogFieldsQuery, BlogFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogFieldsQuery, BlogFieldsQueryVariables>(BlogFieldsDocument, options);
        }
export type BlogFieldsQueryHookResult = ReturnType<typeof useBlogFieldsQuery>;
export type BlogFieldsLazyQueryHookResult = ReturnType<typeof useBlogFieldsLazyQuery>;
export type BlogFieldsQueryResult = Apollo.QueryResult<BlogFieldsQuery, BlogFieldsQueryVariables>;
export const ItemFieldsDocument = gql`
    query itemFields {
  itemFields {
    shortDescription
    longDescription
    free
    icon
    images
    platform
    company
    skills
    categories
    seo
    link
    price
    reviews
    tags
    prosCons
    twitter
    facebook
    youtube
  }
  dynamicModels(entity: Item) {
    name
    id
    title
    fields {
      name
      type
      id
    }
  }
}
    `;

/**
 * __useItemFieldsQuery__
 *
 * To run a query within a React component, call `useItemFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemFieldsQuery(baseOptions?: Apollo.QueryHookOptions<ItemFieldsQuery, ItemFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemFieldsQuery, ItemFieldsQueryVariables>(ItemFieldsDocument, options);
      }
export function useItemFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemFieldsQuery, ItemFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemFieldsQuery, ItemFieldsQueryVariables>(ItemFieldsDocument, options);
        }
export type ItemFieldsQueryHookResult = ReturnType<typeof useItemFieldsQuery>;
export type ItemFieldsLazyQueryHookResult = ReturnType<typeof useItemFieldsLazyQuery>;
export type ItemFieldsQueryResult = Apollo.QueryResult<ItemFieldsQuery, ItemFieldsQueryVariables>;
export const SkillFieldsDocument = gql`
    query skillFields {
  skillFields {
    icon
  }
}
    `;

/**
 * __useSkillFieldsQuery__
 *
 * To run a query within a React component, call `useSkillFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillFieldsQuery(baseOptions?: Apollo.QueryHookOptions<SkillFieldsQuery, SkillFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillFieldsQuery, SkillFieldsQueryVariables>(SkillFieldsDocument, options);
      }
export function useSkillFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillFieldsQuery, SkillFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillFieldsQuery, SkillFieldsQueryVariables>(SkillFieldsDocument, options);
        }
export type SkillFieldsQueryHookResult = ReturnType<typeof useSkillFieldsQuery>;
export type SkillFieldsLazyQueryHookResult = ReturnType<typeof useSkillFieldsLazyQuery>;
export type SkillFieldsQueryResult = Apollo.QueryResult<SkillFieldsQuery, SkillFieldsQueryVariables>;
export const PageFieldsDocument = gql`
    query pageFields {
  dynamicModels(entity: Pages) {
    name
    title
    id
  }
}
    `;

/**
 * __usePageFieldsQuery__
 *
 * To run a query within a React component, call `usePageFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageFieldsQuery(baseOptions?: Apollo.QueryHookOptions<PageFieldsQuery, PageFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageFieldsQuery, PageFieldsQueryVariables>(PageFieldsDocument, options);
      }
export function usePageFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageFieldsQuery, PageFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageFieldsQuery, PageFieldsQueryVariables>(PageFieldsDocument, options);
        }
export type PageFieldsQueryHookResult = ReturnType<typeof usePageFieldsQuery>;
export type PageFieldsLazyQueryHookResult = ReturnType<typeof usePageFieldsLazyQuery>;
export type PageFieldsQueryResult = Apollo.QueryResult<PageFieldsQuery, PageFieldsQueryVariables>;
export const AuthorFieldsDocument = gql`
    query authorFields {
  dynamicModels(entity: Author) {
    name
    id
  }
}
    `;

/**
 * __useAuthorFieldsQuery__
 *
 * To run a query within a React component, call `useAuthorFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorFieldsQuery(baseOptions?: Apollo.QueryHookOptions<AuthorFieldsQuery, AuthorFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorFieldsQuery, AuthorFieldsQueryVariables>(AuthorFieldsDocument, options);
      }
export function useAuthorFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorFieldsQuery, AuthorFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorFieldsQuery, AuthorFieldsQueryVariables>(AuthorFieldsDocument, options);
        }
export type AuthorFieldsQueryHookResult = ReturnType<typeof useAuthorFieldsQuery>;
export type AuthorFieldsLazyQueryHookResult = ReturnType<typeof useAuthorFieldsLazyQuery>;
export type AuthorFieldsQueryResult = Apollo.QueryResult<AuthorFieldsQuery, AuthorFieldsQueryVariables>;
export const CategoryFieldsDocument = gql`
    query categoryFields {
  categoryFields {
    shortDescription
    longDescription
    icon
    image
    skills
    color
    disableChildrenItemsIfCategoryDisabled
  }
  dynamicModels(entity: Category) {
    name
    title
    id
  }
}
    `;

/**
 * __useCategoryFieldsQuery__
 *
 * To run a query within a React component, call `useCategoryFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryFieldsQuery(baseOptions?: Apollo.QueryHookOptions<CategoryFieldsQuery, CategoryFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryFieldsQuery, CategoryFieldsQueryVariables>(CategoryFieldsDocument, options);
      }
export function useCategoryFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryFieldsQuery, CategoryFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryFieldsQuery, CategoryFieldsQueryVariables>(CategoryFieldsDocument, options);
        }
export type CategoryFieldsQueryHookResult = ReturnType<typeof useCategoryFieldsQuery>;
export type CategoryFieldsLazyQueryHookResult = ReturnType<typeof useCategoryFieldsLazyQuery>;
export type CategoryFieldsQueryResult = Apollo.QueryResult<CategoryFieldsQuery, CategoryFieldsQueryVariables>;
export const WatermarkDocument = gql`
    query watermark {
  watermark
}
    `;

/**
 * __useWatermarkQuery__
 *
 * To run a query within a React component, call `useWatermarkQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatermarkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatermarkQuery({
 *   variables: {
 *   },
 * });
 */
export function useWatermarkQuery(baseOptions?: Apollo.QueryHookOptions<WatermarkQuery, WatermarkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WatermarkQuery, WatermarkQueryVariables>(WatermarkDocument, options);
      }
export function useWatermarkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WatermarkQuery, WatermarkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WatermarkQuery, WatermarkQueryVariables>(WatermarkDocument, options);
        }
export type WatermarkQueryHookResult = ReturnType<typeof useWatermarkQuery>;
export type WatermarkLazyQueryHookResult = ReturnType<typeof useWatermarkLazyQuery>;
export type WatermarkQueryResult = Apollo.QueryResult<WatermarkQuery, WatermarkQueryVariables>;
export const ToggleActiveModelsDocument = gql`
    mutation toggleActiveModels($input: InputActiveModels!) {
  toggleActiveModels(input: $input) {
    company
    item
    skill
    category
    page
    sort
    blog
  }
}
    `;
export type ToggleActiveModelsMutationFn = Apollo.MutationFunction<ToggleActiveModelsMutation, ToggleActiveModelsMutationVariables>;

/**
 * __useToggleActiveModelsMutation__
 *
 * To run a mutation, you first call `useToggleActiveModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActiveModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActiveModelsMutation, { data, loading, error }] = useToggleActiveModelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleActiveModelsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleActiveModelsMutation, ToggleActiveModelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleActiveModelsMutation, ToggleActiveModelsMutationVariables>(ToggleActiveModelsDocument, options);
      }
export type ToggleActiveModelsMutationHookResult = ReturnType<typeof useToggleActiveModelsMutation>;
export type ToggleActiveModelsMutationResult = Apollo.MutationResult<ToggleActiveModelsMutation>;
export type ToggleActiveModelsMutationOptions = Apollo.BaseMutationOptions<ToggleActiveModelsMutation, ToggleActiveModelsMutationVariables>;
export const ToggleCompanyFieldsDocument = gql`
    mutation toggleCompanyFields($input: InputCompanyFields!) {
  toggleCompanyFields(input: $input) {
    icon
    link
    social
    seo
  }
}
    `;
export type ToggleCompanyFieldsMutationFn = Apollo.MutationFunction<ToggleCompanyFieldsMutation, ToggleCompanyFieldsMutationVariables>;

/**
 * __useToggleCompanyFieldsMutation__
 *
 * To run a mutation, you first call `useToggleCompanyFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCompanyFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCompanyFieldsMutation, { data, loading, error }] = useToggleCompanyFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleCompanyFieldsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCompanyFieldsMutation, ToggleCompanyFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCompanyFieldsMutation, ToggleCompanyFieldsMutationVariables>(ToggleCompanyFieldsDocument, options);
      }
export type ToggleCompanyFieldsMutationHookResult = ReturnType<typeof useToggleCompanyFieldsMutation>;
export type ToggleCompanyFieldsMutationResult = Apollo.MutationResult<ToggleCompanyFieldsMutation>;
export type ToggleCompanyFieldsMutationOptions = Apollo.BaseMutationOptions<ToggleCompanyFieldsMutation, ToggleCompanyFieldsMutationVariables>;
export const ToggleAuthorFieldsDocument = gql`
    mutation toggleAuthorFields($input: InputCompanyFields!) {
  toggleCompanyFields(input: $input) {
    icon
    link
    social
    seo
  }
}
    `;
export type ToggleAuthorFieldsMutationFn = Apollo.MutationFunction<ToggleAuthorFieldsMutation, ToggleAuthorFieldsMutationVariables>;

/**
 * __useToggleAuthorFieldsMutation__
 *
 * To run a mutation, you first call `useToggleAuthorFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAuthorFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAuthorFieldsMutation, { data, loading, error }] = useToggleAuthorFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleAuthorFieldsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleAuthorFieldsMutation, ToggleAuthorFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleAuthorFieldsMutation, ToggleAuthorFieldsMutationVariables>(ToggleAuthorFieldsDocument, options);
      }
export type ToggleAuthorFieldsMutationHookResult = ReturnType<typeof useToggleAuthorFieldsMutation>;
export type ToggleAuthorFieldsMutationResult = Apollo.MutationResult<ToggleAuthorFieldsMutation>;
export type ToggleAuthorFieldsMutationOptions = Apollo.BaseMutationOptions<ToggleAuthorFieldsMutation, ToggleAuthorFieldsMutationVariables>;
export const ToggleItemFieldsDocument = gql`
    mutation toggleItemFields($input: InputItemFields!) {
  toggleItemFields(input: $input) {
    shortDescription
    longDescription
    free
    icon
    images
    platform
    company
    seo
    skills
    categories
    link
    price
    reviews
    tags
    prosCons
  }
}
    `;
export type ToggleItemFieldsMutationFn = Apollo.MutationFunction<ToggleItemFieldsMutation, ToggleItemFieldsMutationVariables>;

/**
 * __useToggleItemFieldsMutation__
 *
 * To run a mutation, you first call `useToggleItemFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleItemFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleItemFieldsMutation, { data, loading, error }] = useToggleItemFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleItemFieldsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleItemFieldsMutation, ToggleItemFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleItemFieldsMutation, ToggleItemFieldsMutationVariables>(ToggleItemFieldsDocument, options);
      }
export type ToggleItemFieldsMutationHookResult = ReturnType<typeof useToggleItemFieldsMutation>;
export type ToggleItemFieldsMutationResult = Apollo.MutationResult<ToggleItemFieldsMutation>;
export type ToggleItemFieldsMutationOptions = Apollo.BaseMutationOptions<ToggleItemFieldsMutation, ToggleItemFieldsMutationVariables>;
export const ToggleSkillFieldsDocument = gql`
    mutation toggleSkillFields($input: InputSkillFields!) {
  toggleSkillFields(input: $input) {
    icon
  }
}
    `;
export type ToggleSkillFieldsMutationFn = Apollo.MutationFunction<ToggleSkillFieldsMutation, ToggleSkillFieldsMutationVariables>;

/**
 * __useToggleSkillFieldsMutation__
 *
 * To run a mutation, you first call `useToggleSkillFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSkillFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSkillFieldsMutation, { data, loading, error }] = useToggleSkillFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleSkillFieldsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSkillFieldsMutation, ToggleSkillFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSkillFieldsMutation, ToggleSkillFieldsMutationVariables>(ToggleSkillFieldsDocument, options);
      }
export type ToggleSkillFieldsMutationHookResult = ReturnType<typeof useToggleSkillFieldsMutation>;
export type ToggleSkillFieldsMutationResult = Apollo.MutationResult<ToggleSkillFieldsMutation>;
export type ToggleSkillFieldsMutationOptions = Apollo.BaseMutationOptions<ToggleSkillFieldsMutation, ToggleSkillFieldsMutationVariables>;
export const ToggleCategoryFieldsDocument = gql`
    mutation toggleCategoryFields($input: InputCategoryFields!) {
  toggleCategoryFields(input: $input) {
    shortDescription
    longDescription
    icon
    image
    skills
    color
    disableChildrenItemsIfCategoryDisabled
  }
}
    `;
export type ToggleCategoryFieldsMutationFn = Apollo.MutationFunction<ToggleCategoryFieldsMutation, ToggleCategoryFieldsMutationVariables>;

/**
 * __useToggleCategoryFieldsMutation__
 *
 * To run a mutation, you first call `useToggleCategoryFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCategoryFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCategoryFieldsMutation, { data, loading, error }] = useToggleCategoryFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleCategoryFieldsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCategoryFieldsMutation, ToggleCategoryFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCategoryFieldsMutation, ToggleCategoryFieldsMutationVariables>(ToggleCategoryFieldsDocument, options);
      }
export type ToggleCategoryFieldsMutationHookResult = ReturnType<typeof useToggleCategoryFieldsMutation>;
export type ToggleCategoryFieldsMutationResult = Apollo.MutationResult<ToggleCategoryFieldsMutation>;
export type ToggleCategoryFieldsMutationOptions = Apollo.BaseMutationOptions<ToggleCategoryFieldsMutation, ToggleCategoryFieldsMutationVariables>;
export const UploadWatermarkDocument = gql`
    mutation uploadWatermark($input: Upload!) {
  uploadWatermark(input: $input)
}
    `;
export type UploadWatermarkMutationFn = Apollo.MutationFunction<UploadWatermarkMutation, UploadWatermarkMutationVariables>;

/**
 * __useUploadWatermarkMutation__
 *
 * To run a mutation, you first call `useUploadWatermarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadWatermarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadWatermarkMutation, { data, loading, error }] = useUploadWatermarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadWatermarkMutation(baseOptions?: Apollo.MutationHookOptions<UploadWatermarkMutation, UploadWatermarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadWatermarkMutation, UploadWatermarkMutationVariables>(UploadWatermarkDocument, options);
      }
export type UploadWatermarkMutationHookResult = ReturnType<typeof useUploadWatermarkMutation>;
export type UploadWatermarkMutationResult = Apollo.MutationResult<UploadWatermarkMutation>;
export type UploadWatermarkMutationOptions = Apollo.BaseMutationOptions<UploadWatermarkMutation, UploadWatermarkMutationVariables>;
export const CreateDynamicModelDocument = gql`
    mutation CreateDynamicModel($entity: Entity!, $name: String!, $title: String, $type: DynamicModelType!) {
  createDynamicModel(type: $type, entity: $entity, name: $name, title: $title) {
    id
    name
    type
    title
    fields {
      id
      type
    }
  }
}
    `;
export type CreateDynamicModelMutationFn = Apollo.MutationFunction<CreateDynamicModelMutation, CreateDynamicModelMutationVariables>;

/**
 * __useCreateDynamicModelMutation__
 *
 * To run a mutation, you first call `useCreateDynamicModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDynamicModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDynamicModelMutation, { data, loading, error }] = useCreateDynamicModelMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      name: // value for 'name'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateDynamicModelMutation(baseOptions?: Apollo.MutationHookOptions<CreateDynamicModelMutation, CreateDynamicModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDynamicModelMutation, CreateDynamicModelMutationVariables>(CreateDynamicModelDocument, options);
      }
export type CreateDynamicModelMutationHookResult = ReturnType<typeof useCreateDynamicModelMutation>;
export type CreateDynamicModelMutationResult = Apollo.MutationResult<CreateDynamicModelMutation>;
export type CreateDynamicModelMutationOptions = Apollo.BaseMutationOptions<CreateDynamicModelMutation, CreateDynamicModelMutationVariables>;
export const CreateDynamicFieldDocument = gql`
    mutation CreateDynamicField($type: DynamicFieldType!, $name: String!, $isArray: Boolean!, $modelID: ID!, $primaryField: Boolean!) {
  createDynamicField(
    name: $name
    type: $type
    isArray: $isArray
    modelID: $modelID
    primaryField: $primaryField
  ) {
    id
    name
    type
  }
}
    `;
export type CreateDynamicFieldMutationFn = Apollo.MutationFunction<CreateDynamicFieldMutation, CreateDynamicFieldMutationVariables>;

/**
 * __useCreateDynamicFieldMutation__
 *
 * To run a mutation, you first call `useCreateDynamicFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDynamicFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDynamicFieldMutation, { data, loading, error }] = useCreateDynamicFieldMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      isArray: // value for 'isArray'
 *      modelID: // value for 'modelID'
 *      primaryField: // value for 'primaryField'
 *   },
 * });
 */
export function useCreateDynamicFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateDynamicFieldMutation, CreateDynamicFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDynamicFieldMutation, CreateDynamicFieldMutationVariables>(CreateDynamicFieldDocument, options);
      }
export type CreateDynamicFieldMutationHookResult = ReturnType<typeof useCreateDynamicFieldMutation>;
export type CreateDynamicFieldMutationResult = Apollo.MutationResult<CreateDynamicFieldMutation>;
export type CreateDynamicFieldMutationOptions = Apollo.BaseMutationOptions<CreateDynamicFieldMutation, CreateDynamicFieldMutationVariables>;
export const UpdateDynamicFieldDocument = gql`
    mutation UpdateDynamicField($id: ID!, $name: String!, $type: DynamicFieldType!, $isArray: Boolean!, $primaryField: Boolean!) {
  updateDynamicField(
    id: $id
    name: $name
    type: $type
    isArray: $isArray
    primaryField: $primaryField
  ) {
    id
    name
    type
  }
}
    `;
export type UpdateDynamicFieldMutationFn = Apollo.MutationFunction<UpdateDynamicFieldMutation, UpdateDynamicFieldMutationVariables>;

/**
 * __useUpdateDynamicFieldMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicFieldMutation, { data, loading, error }] = useUpdateDynamicFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      isArray: // value for 'isArray'
 *      primaryField: // value for 'primaryField'
 *   },
 * });
 */
export function useUpdateDynamicFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicFieldMutation, UpdateDynamicFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicFieldMutation, UpdateDynamicFieldMutationVariables>(UpdateDynamicFieldDocument, options);
      }
export type UpdateDynamicFieldMutationHookResult = ReturnType<typeof useUpdateDynamicFieldMutation>;
export type UpdateDynamicFieldMutationResult = Apollo.MutationResult<UpdateDynamicFieldMutation>;
export type UpdateDynamicFieldMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicFieldMutation, UpdateDynamicFieldMutationVariables>;
export const DeleteDynamicFieldDocument = gql`
    mutation DeleteDynamicField($id: ID!) {
  deleteDynamicField(id: $id)
}
    `;
export type DeleteDynamicFieldMutationFn = Apollo.MutationFunction<DeleteDynamicFieldMutation, DeleteDynamicFieldMutationVariables>;

/**
 * __useDeleteDynamicFieldMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicFieldMutation, { data, loading, error }] = useDeleteDynamicFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicFieldMutation, DeleteDynamicFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicFieldMutation, DeleteDynamicFieldMutationVariables>(DeleteDynamicFieldDocument, options);
      }
export type DeleteDynamicFieldMutationHookResult = ReturnType<typeof useDeleteDynamicFieldMutation>;
export type DeleteDynamicFieldMutationResult = Apollo.MutationResult<DeleteDynamicFieldMutation>;
export type DeleteDynamicFieldMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicFieldMutation, DeleteDynamicFieldMutationVariables>;
export const UpdateDynamicModelDocument = gql`
    mutation UpdateDynamicModel($id: ID!, $entity: Entity!, $name: String!, $title: String, $type: DynamicModelType!) {
  updateDynamicModel(
    id: $id
    entity: $entity
    name: $name
    type: $type
    title: $title
  ) {
    id
    title
    name
  }
}
    `;
export type UpdateDynamicModelMutationFn = Apollo.MutationFunction<UpdateDynamicModelMutation, UpdateDynamicModelMutationVariables>;

/**
 * __useUpdateDynamicModelMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicModelMutation, { data, loading, error }] = useUpdateDynamicModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entity: // value for 'entity'
 *      name: // value for 'name'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateDynamicModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicModelMutation, UpdateDynamicModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicModelMutation, UpdateDynamicModelMutationVariables>(UpdateDynamicModelDocument, options);
      }
export type UpdateDynamicModelMutationHookResult = ReturnType<typeof useUpdateDynamicModelMutation>;
export type UpdateDynamicModelMutationResult = Apollo.MutationResult<UpdateDynamicModelMutation>;
export type UpdateDynamicModelMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicModelMutation, UpdateDynamicModelMutationVariables>;
export const DeleteDynamicModelDocument = gql`
    mutation DeleteDynamicModel($id: ID!) {
  deleteCascadeDynamicModel(id: $id)
}
    `;
export type DeleteDynamicModelMutationFn = Apollo.MutationFunction<DeleteDynamicModelMutation, DeleteDynamicModelMutationVariables>;

/**
 * __useDeleteDynamicModelMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicModelMutation, { data, loading, error }] = useDeleteDynamicModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicModelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicModelMutation, DeleteDynamicModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicModelMutation, DeleteDynamicModelMutationVariables>(DeleteDynamicModelDocument, options);
      }
export type DeleteDynamicModelMutationHookResult = ReturnType<typeof useDeleteDynamicModelMutation>;
export type DeleteDynamicModelMutationResult = Apollo.MutationResult<DeleteDynamicModelMutation>;
export type DeleteDynamicModelMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicModelMutation, DeleteDynamicModelMutationVariables>;
export const CreateDynamicVariantDocument = gql`
    mutation CreateDynamicVariant($modelID: ID!, $value: JSON!) {
  createDynamicVariant(modelID: $modelID, value: $value) {
    id
    value
  }
}
    `;
export type CreateDynamicVariantMutationFn = Apollo.MutationFunction<CreateDynamicVariantMutation, CreateDynamicVariantMutationVariables>;

/**
 * __useCreateDynamicVariantMutation__
 *
 * To run a mutation, you first call `useCreateDynamicVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDynamicVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDynamicVariantMutation, { data, loading, error }] = useCreateDynamicVariantMutation({
 *   variables: {
 *      modelID: // value for 'modelID'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateDynamicVariantMutation(baseOptions?: Apollo.MutationHookOptions<CreateDynamicVariantMutation, CreateDynamicVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDynamicVariantMutation, CreateDynamicVariantMutationVariables>(CreateDynamicVariantDocument, options);
      }
export type CreateDynamicVariantMutationHookResult = ReturnType<typeof useCreateDynamicVariantMutation>;
export type CreateDynamicVariantMutationResult = Apollo.MutationResult<CreateDynamicVariantMutation>;
export type CreateDynamicVariantMutationOptions = Apollo.BaseMutationOptions<CreateDynamicVariantMutation, CreateDynamicVariantMutationVariables>;
export const UpdateDynamicVariantDocument = gql`
    mutation UpdateDynamicVariant($id: ID!, $value: JSON!) {
  updateDynamicVariant(id: $id, value: $value) {
    id
    value
  }
}
    `;
export type UpdateDynamicVariantMutationFn = Apollo.MutationFunction<UpdateDynamicVariantMutation, UpdateDynamicVariantMutationVariables>;

/**
 * __useUpdateDynamicVariantMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicVariantMutation, { data, loading, error }] = useUpdateDynamicVariantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateDynamicVariantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicVariantMutation, UpdateDynamicVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicVariantMutation, UpdateDynamicVariantMutationVariables>(UpdateDynamicVariantDocument, options);
      }
export type UpdateDynamicVariantMutationHookResult = ReturnType<typeof useUpdateDynamicVariantMutation>;
export type UpdateDynamicVariantMutationResult = Apollo.MutationResult<UpdateDynamicVariantMutation>;
export type UpdateDynamicVariantMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicVariantMutation, UpdateDynamicVariantMutationVariables>;
export const DeleteDynamicVariantDocument = gql`
    mutation DeleteDynamicVariant($id: ID!) {
  deleteDynamicVariant(id: $id)
}
    `;
export type DeleteDynamicVariantMutationFn = Apollo.MutationFunction<DeleteDynamicVariantMutation, DeleteDynamicVariantMutationVariables>;

/**
 * __useDeleteDynamicVariantMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicVariantMutation, { data, loading, error }] = useDeleteDynamicVariantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicVariantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDynamicVariantMutation, DeleteDynamicVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDynamicVariantMutation, DeleteDynamicVariantMutationVariables>(DeleteDynamicVariantDocument, options);
      }
export type DeleteDynamicVariantMutationHookResult = ReturnType<typeof useDeleteDynamicVariantMutation>;
export type DeleteDynamicVariantMutationResult = Apollo.MutationResult<DeleteDynamicVariantMutation>;
export type DeleteDynamicVariantMutationOptions = Apollo.BaseMutationOptions<DeleteDynamicVariantMutation, DeleteDynamicVariantMutationVariables>;
export const DynamicModelsDocument = gql`
    query DynamicModels($entity: Entity) {
  dynamicModels(entity: $entity) {
    id
    entity
    name
    title
    fields {
      id
      name
      type
      isArray
      primaryField
    }
    type
    variants {
      id
      value
    }
  }
}
    `;

/**
 * __useDynamicModelsQuery__
 *
 * To run a query within a React component, call `useDynamicModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDynamicModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicModelsQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useDynamicModelsQuery(baseOptions?: Apollo.QueryHookOptions<DynamicModelsQuery, DynamicModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DynamicModelsQuery, DynamicModelsQueryVariables>(DynamicModelsDocument, options);
      }
export function useDynamicModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DynamicModelsQuery, DynamicModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DynamicModelsQuery, DynamicModelsQueryVariables>(DynamicModelsDocument, options);
        }
export type DynamicModelsQueryHookResult = ReturnType<typeof useDynamicModelsQuery>;
export type DynamicModelsLazyQueryHookResult = ReturnType<typeof useDynamicModelsLazyQuery>;
export type DynamicModelsQueryResult = Apollo.QueryResult<DynamicModelsQuery, DynamicModelsQueryVariables>;
export const DynamicModelDocument = gql`
    query DynamicModel($id: ID!) {
  dynamicModel(id: $id) {
    id
    name
    entity
    type
    title
    fields {
      id
      name
      isArray
      type
      primaryField
    }
    variants {
      id
      value
    }
  }
}
    `;

/**
 * __useDynamicModelQuery__
 *
 * To run a query within a React component, call `useDynamicModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDynamicModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicModelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDynamicModelQuery(baseOptions: Apollo.QueryHookOptions<DynamicModelQuery, DynamicModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DynamicModelQuery, DynamicModelQueryVariables>(DynamicModelDocument, options);
      }
export function useDynamicModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DynamicModelQuery, DynamicModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DynamicModelQuery, DynamicModelQueryVariables>(DynamicModelDocument, options);
        }
export type DynamicModelQueryHookResult = ReturnType<typeof useDynamicModelQuery>;
export type DynamicModelLazyQueryHookResult = ReturnType<typeof useDynamicModelLazyQuery>;
export type DynamicModelQueryResult = Apollo.QueryResult<DynamicModelQuery, DynamicModelQueryVariables>;
export const UpdateDynamicVariantsDocument = gql`
    mutation UpdateDynamicVariants($input: [DynamicVariantInput!]!) {
  updateDynamicVariants(updateArr: $input)
}
    `;
export type UpdateDynamicVariantsMutationFn = Apollo.MutationFunction<UpdateDynamicVariantsMutation, UpdateDynamicVariantsMutationVariables>;

/**
 * __useUpdateDynamicVariantsMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicVariantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicVariantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicVariantsMutation, { data, loading, error }] = useUpdateDynamicVariantsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDynamicVariantsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicVariantsMutation, UpdateDynamicVariantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicVariantsMutation, UpdateDynamicVariantsMutationVariables>(UpdateDynamicVariantsDocument, options);
      }
export type UpdateDynamicVariantsMutationHookResult = ReturnType<typeof useUpdateDynamicVariantsMutation>;
export type UpdateDynamicVariantsMutationResult = Apollo.MutationResult<UpdateDynamicVariantsMutation>;
export type UpdateDynamicVariantsMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicVariantsMutation, UpdateDynamicVariantsMutationVariables>;
export const GetPagesDocument = gql`
    query getPages {
  pages {
    ...PageFragment
  }
}
    ${PageFragmentFragmentDoc}`;

/**
 * __useGetPagesQuery__
 *
 * To run a query within a React component, call `useGetPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPagesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, options);
      }
export function useGetPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, options);
        }
export type GetPagesQueryHookResult = ReturnType<typeof useGetPagesQuery>;
export type GetPagesLazyQueryHookResult = ReturnType<typeof useGetPagesLazyQuery>;
export type GetPagesQueryResult = Apollo.QueryResult<GetPagesQuery, GetPagesQueryVariables>;
export const GetPageDocument = gql`
    query getPage($id: ID, $slug: String) {
  page(id: $id, slug: $slug) {
    ...PageFragment
  }
}
    ${PageFragmentFragmentDoc}`;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions?: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const PageCreateDocument = gql`
    mutation pageCreate($input: PageInput!) {
  pageCreate(input: $input) {
    ...PageFragment
  }
}
    ${PageFragmentFragmentDoc}`;
export type PageCreateMutationFn = Apollo.MutationFunction<PageCreateMutation, PageCreateMutationVariables>;

/**
 * __usePageCreateMutation__
 *
 * To run a mutation, you first call `usePageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pageCreateMutation, { data, loading, error }] = usePageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageCreateMutation(baseOptions?: Apollo.MutationHookOptions<PageCreateMutation, PageCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PageCreateMutation, PageCreateMutationVariables>(PageCreateDocument, options);
      }
export type PageCreateMutationHookResult = ReturnType<typeof usePageCreateMutation>;
export type PageCreateMutationResult = Apollo.MutationResult<PageCreateMutation>;
export type PageCreateMutationOptions = Apollo.BaseMutationOptions<PageCreateMutation, PageCreateMutationVariables>;
export const PageUpdateDocument = gql`
    mutation pageUpdate($id: ID, $slug: String, $input: PageInput!) {
  pageUpdate(id: $id, slug: $slug, input: $input) {
    ...PageFragment
  }
}
    ${PageFragmentFragmentDoc}`;
export type PageUpdateMutationFn = Apollo.MutationFunction<PageUpdateMutation, PageUpdateMutationVariables>;

/**
 * __usePageUpdateMutation__
 *
 * To run a mutation, you first call `usePageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pageUpdateMutation, { data, loading, error }] = usePageUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PageUpdateMutation, PageUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PageUpdateMutation, PageUpdateMutationVariables>(PageUpdateDocument, options);
      }
export type PageUpdateMutationHookResult = ReturnType<typeof usePageUpdateMutation>;
export type PageUpdateMutationResult = Apollo.MutationResult<PageUpdateMutation>;
export type PageUpdateMutationOptions = Apollo.BaseMutationOptions<PageUpdateMutation, PageUpdateMutationVariables>;
export const PageDeleteDocument = gql`
    mutation pageDelete($id: ID!) {
  pageDelete(id: $id)
}
    `;
export type PageDeleteMutationFn = Apollo.MutationFunction<PageDeleteMutation, PageDeleteMutationVariables>;

/**
 * __usePageDeleteMutation__
 *
 * To run a mutation, you first call `usePageDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePageDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pageDeleteMutation, { data, loading, error }] = usePageDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePageDeleteMutation(baseOptions?: Apollo.MutationHookOptions<PageDeleteMutation, PageDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PageDeleteMutation, PageDeleteMutationVariables>(PageDeleteDocument, options);
      }
export type PageDeleteMutationHookResult = ReturnType<typeof usePageDeleteMutation>;
export type PageDeleteMutationResult = Apollo.MutationResult<PageDeleteMutation>;
export type PageDeleteMutationOptions = Apollo.BaseMutationOptions<PageDeleteMutation, PageDeleteMutationVariables>;
export const GetBlogPostsDocument = gql`
    query getBlogPosts($page: Int, $limit: Int, $search: String, $sortBy: SortBy, $thumbFilter: WithWithout, $sortByDirection: SortDirection, $active: Boolean) {
  blogPosts(
    page: $page
    limit: $limit
    search: $search
    sortBy: $sortBy
    thumbFilter: $thumbFilter
    sortByDirection: $sortByDirection
    active: $active
  ) {
    data {
      ...BlogPostFragment
    }
    count
  }
}
    ${BlogPostFragmentFragmentDoc}`;

/**
 * __useGetBlogPostsQuery__
 *
 * To run a query within a React component, call `useGetBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      thumbFilter: // value for 'thumbFilter'
 *      sortByDirection: // value for 'sortByDirection'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetBlogPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, options);
      }
export function useGetBlogPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, options);
        }
export type GetBlogPostsQueryHookResult = ReturnType<typeof useGetBlogPostsQuery>;
export type GetBlogPostsLazyQueryHookResult = ReturnType<typeof useGetBlogPostsLazyQuery>;
export type GetBlogPostsQueryResult = Apollo.QueryResult<GetBlogPostsQuery, GetBlogPostsQueryVariables>;
export const GetBlogPostDocument = gql`
    query getBlogPost($id: ID!) {
  blogPost(id: $id) {
    ...BlogPostFragment
  }
}
    ${BlogPostFragmentFragmentDoc}`;

/**
 * __useGetBlogPostQuery__
 *
 * To run a query within a React component, call `useGetBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBlogPostQuery(baseOptions: Apollo.QueryHookOptions<GetBlogPostQuery, GetBlogPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostQuery, GetBlogPostQueryVariables>(GetBlogPostDocument, options);
      }
export function useGetBlogPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostQuery, GetBlogPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostQuery, GetBlogPostQueryVariables>(GetBlogPostDocument, options);
        }
export type GetBlogPostQueryHookResult = ReturnType<typeof useGetBlogPostQuery>;
export type GetBlogPostLazyQueryHookResult = ReturnType<typeof useGetBlogPostLazyQuery>;
export type GetBlogPostQueryResult = Apollo.QueryResult<GetBlogPostQuery, GetBlogPostQueryVariables>;
export const CreateBlogPostDocument = gql`
    mutation createBlogPost($input: BlogPostInput!) {
  createBlogPost(input: $input) {
    ...BlogPostFragment
  }
}
    ${BlogPostFragmentFragmentDoc}`;
export type CreateBlogPostMutationFn = Apollo.MutationFunction<CreateBlogPostMutation, CreateBlogPostMutationVariables>;

/**
 * __useCreateBlogPostMutation__
 *
 * To run a mutation, you first call `useCreateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogPostMutation, { data, loading, error }] = useCreateBlogPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlogPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogPostMutation, CreateBlogPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogPostMutation, CreateBlogPostMutationVariables>(CreateBlogPostDocument, options);
      }
export type CreateBlogPostMutationHookResult = ReturnType<typeof useCreateBlogPostMutation>;
export type CreateBlogPostMutationResult = Apollo.MutationResult<CreateBlogPostMutation>;
export type CreateBlogPostMutationOptions = Apollo.BaseMutationOptions<CreateBlogPostMutation, CreateBlogPostMutationVariables>;
export const UpdateBlogPostDocument = gql`
    mutation updateBlogPost($id: ID!, $input: BlogPostInput!) {
  updateBlogPost(id: $id, input: $input) {
    ...BlogPostFragment
  }
}
    ${BlogPostFragmentFragmentDoc}`;
export type UpdateBlogPostMutationFn = Apollo.MutationFunction<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;

/**
 * __useUpdateBlogPostMutation__
 *
 * To run a mutation, you first call `useUpdateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogPostMutation, { data, loading, error }] = useUpdateBlogPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlogPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>(UpdateBlogPostDocument, options);
      }
export type UpdateBlogPostMutationHookResult = ReturnType<typeof useUpdateBlogPostMutation>;
export type UpdateBlogPostMutationResult = Apollo.MutationResult<UpdateBlogPostMutation>;
export type UpdateBlogPostMutationOptions = Apollo.BaseMutationOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;
export const DeleteBlogPostDocument = gql`
    mutation deleteBlogPost($id: ID!) {
  deleteBlogPost(id: $id)
}
    `;
export type DeleteBlogPostMutationFn = Apollo.MutationFunction<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;

/**
 * __useDeleteBlogPostMutation__
 *
 * To run a mutation, you first call `useDeleteBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogPostMutation, { data, loading, error }] = useDeleteBlogPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogPostMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>(DeleteBlogPostDocument, options);
      }
export type DeleteBlogPostMutationHookResult = ReturnType<typeof useDeleteBlogPostMutation>;
export type DeleteBlogPostMutationResult = Apollo.MutationResult<DeleteBlogPostMutation>;
export type DeleteBlogPostMutationOptions = Apollo.BaseMutationOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;
export const CheckRebuildStatusDocument = gql`
    query CheckRebuildStatus {
  staticRebuildStatus
}
    `;

/**
 * __useCheckRebuildStatusQuery__
 *
 * To run a query within a React component, call `useCheckRebuildStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRebuildStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRebuildStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckRebuildStatusQuery(baseOptions?: Apollo.QueryHookOptions<CheckRebuildStatusQuery, CheckRebuildStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckRebuildStatusQuery, CheckRebuildStatusQueryVariables>(CheckRebuildStatusDocument, options);
      }
export function useCheckRebuildStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckRebuildStatusQuery, CheckRebuildStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckRebuildStatusQuery, CheckRebuildStatusQueryVariables>(CheckRebuildStatusDocument, options);
        }
export type CheckRebuildStatusQueryHookResult = ReturnType<typeof useCheckRebuildStatusQuery>;
export type CheckRebuildStatusLazyQueryHookResult = ReturnType<typeof useCheckRebuildStatusLazyQuery>;
export type CheckRebuildStatusQueryResult = Apollo.QueryResult<CheckRebuildStatusQuery, CheckRebuildStatusQueryVariables>;
export const GetPipelineDataDocument = gql`
    query GetPipelineData {
  fetchPipelineData {
    id
    status
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetPipelineDataQuery__
 *
 * To run a query within a React component, call `useGetPipelineDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPipelineDataQuery(baseOptions?: Apollo.QueryHookOptions<GetPipelineDataQuery, GetPipelineDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipelineDataQuery, GetPipelineDataQueryVariables>(GetPipelineDataDocument, options);
      }
export function useGetPipelineDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipelineDataQuery, GetPipelineDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipelineDataQuery, GetPipelineDataQueryVariables>(GetPipelineDataDocument, options);
        }
export type GetPipelineDataQueryHookResult = ReturnType<typeof useGetPipelineDataQuery>;
export type GetPipelineDataLazyQueryHookResult = ReturnType<typeof useGetPipelineDataLazyQuery>;
export type GetPipelineDataQueryResult = Apollo.QueryResult<GetPipelineDataQuery, GetPipelineDataQueryVariables>;
export const RebuildRunDocument = gql`
    mutation RebuildRun {
  staticRebuildRun
}
    `;
export type RebuildRunMutationFn = Apollo.MutationFunction<RebuildRunMutation, RebuildRunMutationVariables>;

/**
 * __useRebuildRunMutation__
 *
 * To run a mutation, you first call `useRebuildRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildRunMutation, { data, loading, error }] = useRebuildRunMutation({
 *   variables: {
 *   },
 * });
 */
export function useRebuildRunMutation(baseOptions?: Apollo.MutationHookOptions<RebuildRunMutation, RebuildRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebuildRunMutation, RebuildRunMutationVariables>(RebuildRunDocument, options);
      }
export type RebuildRunMutationHookResult = ReturnType<typeof useRebuildRunMutation>;
export type RebuildRunMutationResult = Apollo.MutationResult<RebuildRunMutation>;
export type RebuildRunMutationOptions = Apollo.BaseMutationOptions<RebuildRunMutation, RebuildRunMutationVariables>;
export const GlobalSearchQueryDocument = gql`
    query globalSearchQuery($search: String, $page: Int, $limit: Int) {
  items(search: $search, page: $page, limit: $limit) {
    data {
      id
      slug
      title
      link {
        url
        alternativeUrl
      }
      icon {
        value
        id
        size
      }
    }
    count
  }
  categories(search: $search, page: $page, limit: $limit) {
    data {
      id
      slug
      title
      icon {
        value
        id
        size
      }
    }
    count
  }
  skills(search: $search, page: $page, limit: $limit) {
    data {
      id
      title
      icon {
        value
        id
        size
      }
    }
    count
  }
  blogPosts(search: $search, page: $page, limit: $limit) {
    data {
      id
      slug
      active
      title
      thumb {
        Sizes {
          id
          size
          value
        }
      }
    }
    count
  }
}
    `;

/**
 * __useGlobalSearchQueryQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQueryQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGlobalSearchQueryQuery(baseOptions?: Apollo.QueryHookOptions<GlobalSearchQueryQuery, GlobalSearchQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalSearchQueryQuery, GlobalSearchQueryQueryVariables>(GlobalSearchQueryDocument, options);
      }
export function useGlobalSearchQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalSearchQueryQuery, GlobalSearchQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalSearchQueryQuery, GlobalSearchQueryQueryVariables>(GlobalSearchQueryDocument, options);
        }
export type GlobalSearchQueryQueryHookResult = ReturnType<typeof useGlobalSearchQueryQuery>;
export type GlobalSearchQueryLazyQueryHookResult = ReturnType<typeof useGlobalSearchQueryLazyQuery>;
export type GlobalSearchQueryQueryResult = Apollo.QueryResult<GlobalSearchQueryQuery, GlobalSearchQueryQueryVariables>;
export const SeoDocument = gql`
    query seo {
  seo {
    seo {
      title
      description
    }
    image
    templates {
      item {
        title
        description
      }
      category {
        title
        description
      }
    }
  }
}
    `;

/**
 * __useSeoQuery__
 *
 * To run a query within a React component, call `useSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeoQuery(baseOptions?: Apollo.QueryHookOptions<SeoQuery, SeoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeoQuery, SeoQueryVariables>(SeoDocument, options);
      }
export function useSeoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeoQuery, SeoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeoQuery, SeoQueryVariables>(SeoDocument, options);
        }
export type SeoQueryHookResult = ReturnType<typeof useSeoQuery>;
export type SeoLazyQueryHookResult = ReturnType<typeof useSeoLazyQuery>;
export type SeoQueryResult = Apollo.QueryResult<SeoQuery, SeoQueryVariables>;
export const SeoUpdateDocument = gql`
    mutation seoUpdate($seo: SeoInput!, $image: String, $templates: SeoTemplateInput) {
  seoUpdate(seo: $seo, image: $image, templates: $templates)
}
    `;
export type SeoUpdateMutationFn = Apollo.MutationFunction<SeoUpdateMutation, SeoUpdateMutationVariables>;

/**
 * __useSeoUpdateMutation__
 *
 * To run a mutation, you first call `useSeoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seoUpdateMutation, { data, loading, error }] = useSeoUpdateMutation({
 *   variables: {
 *      seo: // value for 'seo'
 *      image: // value for 'image'
 *      templates: // value for 'templates'
 *   },
 * });
 */
export function useSeoUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SeoUpdateMutation, SeoUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SeoUpdateMutation, SeoUpdateMutationVariables>(SeoUpdateDocument, options);
      }
export type SeoUpdateMutationHookResult = ReturnType<typeof useSeoUpdateMutation>;
export type SeoUpdateMutationResult = Apollo.MutationResult<SeoUpdateMutation>;
export type SeoUpdateMutationOptions = Apollo.BaseMutationOptions<SeoUpdateMutation, SeoUpdateMutationVariables>;
export const SettingsDocument = gql`
    query settings {
  defaultSizeSettings {
    oneByOneCrop
    sizes {
      width
      aspectRatio
      parentType
      lock
      retina
    }
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const SaveSettingsDocument = gql`
    mutation saveSettings($input: InputDefaultSizesSettings!) {
  setDefaultSizeSettings(input: $input)
}
    `;
export type SaveSettingsMutationFn = Apollo.MutationFunction<SaveSettingsMutation, SaveSettingsMutationVariables>;

/**
 * __useSaveSettingsMutation__
 *
 * To run a mutation, you first call `useSaveSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSettingsMutation, { data, loading, error }] = useSaveSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveSettingsMutation, SaveSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSettingsMutation, SaveSettingsMutationVariables>(SaveSettingsDocument, options);
      }
export type SaveSettingsMutationHookResult = ReturnType<typeof useSaveSettingsMutation>;
export type SaveSettingsMutationResult = Apollo.MutationResult<SaveSettingsMutation>;
export type SaveSettingsMutationOptions = Apollo.BaseMutationOptions<SaveSettingsMutation, SaveSettingsMutationVariables>;
export const GetSkillListDocument = gql`
    query getSkillList($page: Int, $limit: Int, $search: String, $iconFilter: WithWithout, $sortBy: SortBy, $sortByDirection: SortDirection) {
  skills(
    page: $page
    limit: $limit
    search: $search
    iconFilter: $iconFilter
    sortBy: $sortBy
    sortByDirection: $sortByDirection
  ) {
    data {
      ...SkillFragment
    }
    count
  }
}
    ${SkillFragmentFragmentDoc}`;

/**
 * __useGetSkillListQuery__
 *
 * To run a query within a React component, call `useGetSkillListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      iconFilter: // value for 'iconFilter'
 *      sortBy: // value for 'sortBy'
 *      sortByDirection: // value for 'sortByDirection'
 *   },
 * });
 */
export function useGetSkillListQuery(baseOptions?: Apollo.QueryHookOptions<GetSkillListQuery, GetSkillListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSkillListQuery, GetSkillListQueryVariables>(GetSkillListDocument, options);
      }
export function useGetSkillListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSkillListQuery, GetSkillListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSkillListQuery, GetSkillListQueryVariables>(GetSkillListDocument, options);
        }
export type GetSkillListQueryHookResult = ReturnType<typeof useGetSkillListQuery>;
export type GetSkillListLazyQueryHookResult = ReturnType<typeof useGetSkillListLazyQuery>;
export type GetSkillListQueryResult = Apollo.QueryResult<GetSkillListQuery, GetSkillListQueryVariables>;
export const GetSkillDocument = gql`
    query getSkill($id: ID!) {
  skill(id: $id) {
    ...SkillFragment
  }
}
    ${SkillFragmentFragmentDoc}`;

/**
 * __useGetSkillQuery__
 *
 * To run a query within a React component, call `useGetSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSkillQuery(baseOptions: Apollo.QueryHookOptions<GetSkillQuery, GetSkillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSkillQuery, GetSkillQueryVariables>(GetSkillDocument, options);
      }
export function useGetSkillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSkillQuery, GetSkillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSkillQuery, GetSkillQueryVariables>(GetSkillDocument, options);
        }
export type GetSkillQueryHookResult = ReturnType<typeof useGetSkillQuery>;
export type GetSkillLazyQueryHookResult = ReturnType<typeof useGetSkillLazyQuery>;
export type GetSkillQueryResult = Apollo.QueryResult<GetSkillQuery, GetSkillQueryVariables>;
export const CreateSkillDocument = gql`
    mutation createSkill($input: SkillInput!) {
  createSkill(input: $input) {
    ...SkillFragment
  }
}
    ${SkillFragmentFragmentDoc}`;
export type CreateSkillMutationFn = Apollo.MutationFunction<CreateSkillMutation, CreateSkillMutationVariables>;

/**
 * __useCreateSkillMutation__
 *
 * To run a mutation, you first call `useCreateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillMutation, { data, loading, error }] = useCreateSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSkillMutation(baseOptions?: Apollo.MutationHookOptions<CreateSkillMutation, CreateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSkillMutation, CreateSkillMutationVariables>(CreateSkillDocument, options);
      }
export type CreateSkillMutationHookResult = ReturnType<typeof useCreateSkillMutation>;
export type CreateSkillMutationResult = Apollo.MutationResult<CreateSkillMutation>;
export type CreateSkillMutationOptions = Apollo.BaseMutationOptions<CreateSkillMutation, CreateSkillMutationVariables>;
export const UpdateSkillDocument = gql`
    mutation updateSkill($id: ID!, $input: SkillInput!) {
  updateSkill(id: $id, input: $input) {
    ...SkillFragment
  }
}
    ${SkillFragmentFragmentDoc}`;
export type UpdateSkillMutationFn = Apollo.MutationFunction<UpdateSkillMutation, UpdateSkillMutationVariables>;

/**
 * __useUpdateSkillMutation__
 *
 * To run a mutation, you first call `useUpdateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkillMutation, { data, loading, error }] = useUpdateSkillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSkillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSkillMutation, UpdateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSkillMutation, UpdateSkillMutationVariables>(UpdateSkillDocument, options);
      }
export type UpdateSkillMutationHookResult = ReturnType<typeof useUpdateSkillMutation>;
export type UpdateSkillMutationResult = Apollo.MutationResult<UpdateSkillMutation>;
export type UpdateSkillMutationOptions = Apollo.BaseMutationOptions<UpdateSkillMutation, UpdateSkillMutationVariables>;
export const DeleteSkillDocument = gql`
    mutation deleteSkill($id: ID!) {
  deleteSkill(id: $id)
}
    `;
export type DeleteSkillMutationFn = Apollo.MutationFunction<DeleteSkillMutation, DeleteSkillMutationVariables>;

/**
 * __useDeleteSkillMutation__
 *
 * To run a mutation, you first call `useDeleteSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillMutation, { data, loading, error }] = useDeleteSkillMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSkillMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSkillMutation, DeleteSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSkillMutation, DeleteSkillMutationVariables>(DeleteSkillDocument, options);
      }
export type DeleteSkillMutationHookResult = ReturnType<typeof useDeleteSkillMutation>;
export type DeleteSkillMutationResult = Apollo.MutationResult<DeleteSkillMutation>;
export type DeleteSkillMutationOptions = Apollo.BaseMutationOptions<DeleteSkillMutation, DeleteSkillMutationVariables>;
export const GetSortCategoriesSizeDocument = gql`
    query getSortCategoriesSize($type: screenSizeType!, $onlyIds: [ID!]) {
  screenSizeV5(type: $type, onlyIds: $onlyIds, includeDisabled: true) {
    ...CategoryFragment
  }
}
    ${CategoryFragmentFragmentDoc}`;

/**
 * __useGetSortCategoriesSizeQuery__
 *
 * To run a query within a React component, call `useGetSortCategoriesSizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSortCategoriesSizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSortCategoriesSizeQuery({
 *   variables: {
 *      type: // value for 'type'
 *      onlyIds: // value for 'onlyIds'
 *   },
 * });
 */
export function useGetSortCategoriesSizeQuery(baseOptions: Apollo.QueryHookOptions<GetSortCategoriesSizeQuery, GetSortCategoriesSizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSortCategoriesSizeQuery, GetSortCategoriesSizeQueryVariables>(GetSortCategoriesSizeDocument, options);
      }
export function useGetSortCategoriesSizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSortCategoriesSizeQuery, GetSortCategoriesSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSortCategoriesSizeQuery, GetSortCategoriesSizeQueryVariables>(GetSortCategoriesSizeDocument, options);
        }
export type GetSortCategoriesSizeQueryHookResult = ReturnType<typeof useGetSortCategoriesSizeQuery>;
export type GetSortCategoriesSizeLazyQueryHookResult = ReturnType<typeof useGetSortCategoriesSizeLazyQuery>;
export type GetSortCategoriesSizeQueryResult = Apollo.QueryResult<GetSortCategoriesSizeQuery, GetSortCategoriesSizeQueryVariables>;
export const UpdateScreenSizeDocument = gql`
    mutation updateScreenSize($type: screenSizeType!, $input: [[SortCategoryV4!]]!) {
  updateScreenSizeV5(type: $type, input: $input)
}
    `;
export type UpdateScreenSizeMutationFn = Apollo.MutationFunction<UpdateScreenSizeMutation, UpdateScreenSizeMutationVariables>;

/**
 * __useUpdateScreenSizeMutation__
 *
 * To run a mutation, you first call `useUpdateScreenSizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScreenSizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScreenSizeMutation, { data, loading, error }] = useUpdateScreenSizeMutation({
 *   variables: {
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScreenSizeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScreenSizeMutation, UpdateScreenSizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScreenSizeMutation, UpdateScreenSizeMutationVariables>(UpdateScreenSizeDocument, options);
      }
export type UpdateScreenSizeMutationHookResult = ReturnType<typeof useUpdateScreenSizeMutation>;
export type UpdateScreenSizeMutationResult = Apollo.MutationResult<UpdateScreenSizeMutation>;
export type UpdateScreenSizeMutationOptions = Apollo.BaseMutationOptions<UpdateScreenSizeMutation, UpdateScreenSizeMutationVariables>;
export const SyncItemsInSortScreenDocument = gql`
    mutation syncItemsInSortScreen($type: screenSizeType!) {
  syncItemsInSortScreenV2(type: $type)
}
    `;
export type SyncItemsInSortScreenMutationFn = Apollo.MutationFunction<SyncItemsInSortScreenMutation, SyncItemsInSortScreenMutationVariables>;

/**
 * __useSyncItemsInSortScreenMutation__
 *
 * To run a mutation, you first call `useSyncItemsInSortScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncItemsInSortScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncItemsInSortScreenMutation, { data, loading, error }] = useSyncItemsInSortScreenMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSyncItemsInSortScreenMutation(baseOptions?: Apollo.MutationHookOptions<SyncItemsInSortScreenMutation, SyncItemsInSortScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncItemsInSortScreenMutation, SyncItemsInSortScreenMutationVariables>(SyncItemsInSortScreenDocument, options);
      }
export type SyncItemsInSortScreenMutationHookResult = ReturnType<typeof useSyncItemsInSortScreenMutation>;
export type SyncItemsInSortScreenMutationResult = Apollo.MutationResult<SyncItemsInSortScreenMutation>;
export type SyncItemsInSortScreenMutationOptions = Apollo.BaseMutationOptions<SyncItemsInSortScreenMutation, SyncItemsInSortScreenMutationVariables>;
export const GetStaffsDocument = gql`
    query getStaffs($search: String) {
  staffs(search: $search) {
    id
    email
    permissions {
      model
      actions
    }
  }
}
    `;

/**
 * __useGetStaffsQuery__
 *
 * To run a query within a React component, call `useGetStaffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetStaffsQuery(baseOptions?: Apollo.QueryHookOptions<GetStaffsQuery, GetStaffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffsQuery, GetStaffsQueryVariables>(GetStaffsDocument, options);
      }
export function useGetStaffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffsQuery, GetStaffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffsQuery, GetStaffsQueryVariables>(GetStaffsDocument, options);
        }
export type GetStaffsQueryHookResult = ReturnType<typeof useGetStaffsQuery>;
export type GetStaffsLazyQueryHookResult = ReturnType<typeof useGetStaffsLazyQuery>;
export type GetStaffsQueryResult = Apollo.QueryResult<GetStaffsQuery, GetStaffsQueryVariables>;
export const GetStaffDocument = gql`
    query getStaff($id: ID!) {
  staff(id: $id) {
    id
    email
    permissions {
      model
      actions
    }
  }
}
    `;

/**
 * __useGetStaffQuery__
 *
 * To run a query within a React component, call `useGetStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStaffQuery(baseOptions: Apollo.QueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
      }
export function useGetStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
        }
export type GetStaffQueryHookResult = ReturnType<typeof useGetStaffQuery>;
export type GetStaffLazyQueryHookResult = ReturnType<typeof useGetStaffLazyQuery>;
export type GetStaffQueryResult = Apollo.QueryResult<GetStaffQuery, GetStaffQueryVariables>;
export const CreateStaffDocument = gql`
    mutation createStaff($input: StaffInput!) {
  createStaff(input: $input) {
    id
    email
    permissions {
      model
      actions
    }
  }
}
    `;
export type CreateStaffMutationFn = Apollo.MutationFunction<CreateStaffMutation, CreateStaffMutationVariables>;

/**
 * __useCreateStaffMutation__
 *
 * To run a mutation, you first call `useCreateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffMutation, { data, loading, error }] = useCreateStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStaffMutation(baseOptions?: Apollo.MutationHookOptions<CreateStaffMutation, CreateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStaffMutation, CreateStaffMutationVariables>(CreateStaffDocument, options);
      }
export type CreateStaffMutationHookResult = ReturnType<typeof useCreateStaffMutation>;
export type CreateStaffMutationResult = Apollo.MutationResult<CreateStaffMutation>;
export type CreateStaffMutationOptions = Apollo.BaseMutationOptions<CreateStaffMutation, CreateStaffMutationVariables>;
export const UpdateStaffDocument = gql`
    mutation updateStaff($id: ID!, $input: StaffInput!) {
  updateStaff(id: $id, input: $input) {
    id
    email
    permissions {
      model
      actions
    }
  }
}
    `;
export type UpdateStaffMutationFn = Apollo.MutationFunction<UpdateStaffMutation, UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffMutation, UpdateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(UpdateStaffDocument, options);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = Apollo.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<UpdateStaffMutation, UpdateStaffMutationVariables>;
export const DeleteStaffDocument = gql`
    mutation deleteStaff($id: ID!) {
  deleteStaff(id: $id)
}
    `;
export type DeleteStaffMutationFn = Apollo.MutationFunction<DeleteStaffMutation, DeleteStaffMutationVariables>;

/**
 * __useDeleteStaffMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMutation, { data, loading, error }] = useDeleteStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStaffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStaffMutation, DeleteStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStaffMutation, DeleteStaffMutationVariables>(DeleteStaffDocument, options);
      }
export type DeleteStaffMutationHookResult = ReturnType<typeof useDeleteStaffMutation>;
export type DeleteStaffMutationResult = Apollo.MutationResult<DeleteStaffMutation>;
export type DeleteStaffMutationOptions = Apollo.BaseMutationOptions<DeleteStaffMutation, DeleteStaffMutationVariables>;
export const SetStaffPasswordDocument = gql`
    mutation setStaffPassword($token: String!, $password: String!) {
  staffSetPassword(token: $token, password: $password) {
    token
    me {
      id
      email
    }
  }
}
    `;
export type SetStaffPasswordMutationFn = Apollo.MutationFunction<SetStaffPasswordMutation, SetStaffPasswordMutationVariables>;

/**
 * __useSetStaffPasswordMutation__
 *
 * To run a mutation, you first call `useSetStaffPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStaffPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStaffPasswordMutation, { data, loading, error }] = useSetStaffPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetStaffPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetStaffPasswordMutation, SetStaffPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStaffPasswordMutation, SetStaffPasswordMutationVariables>(SetStaffPasswordDocument, options);
      }
export type SetStaffPasswordMutationHookResult = ReturnType<typeof useSetStaffPasswordMutation>;
export type SetStaffPasswordMutationResult = Apollo.MutationResult<SetStaffPasswordMutation>;
export type SetStaffPasswordMutationOptions = Apollo.BaseMutationOptions<SetStaffPasswordMutation, SetStaffPasswordMutationVariables>;
export const GetSubmittedToolsDocument = gql`
    query getSubmittedTools($page: Int, $limit: Int, $search: String) {
  submittedTools(page: $page, limit: $limit, search: $search) {
    count
    data {
      ...SubmittedTool
    }
  }
}
    ${SubmittedToolFragmentDoc}`;

/**
 * __useGetSubmittedToolsQuery__
 *
 * To run a query within a React component, call `useGetSubmittedToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmittedToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmittedToolsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetSubmittedToolsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubmittedToolsQuery, GetSubmittedToolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmittedToolsQuery, GetSubmittedToolsQueryVariables>(GetSubmittedToolsDocument, options);
      }
export function useGetSubmittedToolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmittedToolsQuery, GetSubmittedToolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmittedToolsQuery, GetSubmittedToolsQueryVariables>(GetSubmittedToolsDocument, options);
        }
export type GetSubmittedToolsQueryHookResult = ReturnType<typeof useGetSubmittedToolsQuery>;
export type GetSubmittedToolsLazyQueryHookResult = ReturnType<typeof useGetSubmittedToolsLazyQuery>;
export type GetSubmittedToolsQueryResult = Apollo.QueryResult<GetSubmittedToolsQuery, GetSubmittedToolsQueryVariables>;
export const GetSubmittedToolDocument = gql`
    query getSubmittedTool($id: ID!) {
  submittedTool(id: $id) {
    ...SubmittedTool
  }
}
    ${SubmittedToolFragmentDoc}`;

/**
 * __useGetSubmittedToolQuery__
 *
 * To run a query within a React component, call `useGetSubmittedToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmittedToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmittedToolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubmittedToolQuery(baseOptions: Apollo.QueryHookOptions<GetSubmittedToolQuery, GetSubmittedToolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmittedToolQuery, GetSubmittedToolQueryVariables>(GetSubmittedToolDocument, options);
      }
export function useGetSubmittedToolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmittedToolQuery, GetSubmittedToolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmittedToolQuery, GetSubmittedToolQueryVariables>(GetSubmittedToolDocument, options);
        }
export type GetSubmittedToolQueryHookResult = ReturnType<typeof useGetSubmittedToolQuery>;
export type GetSubmittedToolLazyQueryHookResult = ReturnType<typeof useGetSubmittedToolLazyQuery>;
export type GetSubmittedToolQueryResult = Apollo.QueryResult<GetSubmittedToolQuery, GetSubmittedToolQueryVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  me {
    id
    email
    firstName
    lastName
    avatar {
      size
      value
      id
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const SignInDocument = gql`
    mutation signIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password) {
    token
    me {
      email
    }
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($email: String, $firstName: String, $lastName: String, $avatar: PictureDictionaryInput) {
  updateProfile(
    email: $email
    firstName: $firstName
    lastName: $lastName
    Avatar: $avatar
  )
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!) {
  createUser(input: $input) {
    id
    email
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;