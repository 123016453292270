import s from './styles.module.scss';
import { ActiveState } from '../Common/ActiveState/ActiveState';
import { ItemSelects } from './ItemSelects/ItemSelects';
import React from 'react';
import { ItemEditCustomSlug } from './ItemEditCustomSlug';
import { ItemApproveTool } from './ItemApproveTool';
import { ItemToolToCategory } from './ItemToolToCategory';
import { useRouter } from 'hooks/useRouter';

export const ItemEditSideContent = ({
  categoryApproved,
  active,
  approved,
  free,
  platform,
  price,
  link,
  company,
  skills,
  categories,
  twitter,
  facebook,
  youtube,
  modelFields,
  onChangeEnums,
  onInputChange,
  onActiveChange,
  onSelectChange,
  approve,
  slug,
  slugCustom,
  mainCategory,
}) => {
  const { query } = useRouter();
  const isShowBlock = typeof query['approved'] !== 'undefined';
  return (
    <div className={s.sideContent}>
      {/*{approve.approvedAt && !approve.active && (*/}
      {/*  <h3 className={s.approveTitle}>Need to approve</h3>*/}
      {/*)}*/}
      {isShowBlock && !approved && <ItemApproveTool />}
      {approved && !categoryApproved && (
        <ItemToolToCategory categoryApproved={categoryApproved} />
      )}
      <ActiveState
        active={active}
        onChange={checked => onActiveChange(checked, 'active')}
      />
      <ItemEditCustomSlug
        slug={slug}
        slugCustom={slugCustom}
        onChange={onInputChange}
      />
      <ItemSelects
        link={link}
        currentCompany={company}
        currentSkills={skills}
        currentCategories={categories}
        currentMainCategory={mainCategory}
        onSelectChange={onSelectChange}
        onInputChange={onInputChange}
        models={modelFields}
      />
    </div>
  );
};
