import _isEmpty from 'lodash/isEmpty';
import { Company, Item, ItemInput, ItemReview, Platform } from 'graphql/types';
import { filterTypeName } from 'utils/apollo';
import { getIds } from 'utils/getData';
import { getDynamicModelsPayload } from '../DynamicFieldsComponent/helpers';

export const getInitialState = ({
  title = '',
  shortDescription = '',
  longDescription = '',
  categoryApproved,
  free,
  categories,
  thumbnail,
  images,
  icon,
  features = [],
  slug,
  slugCustom,
  platform = [],
  iconSetManually,
  company = {} as Company,
  seoCustom = {},
  skills = [],
  cons = [],
  pros = [],
  link,
  tags,
  price,
  reviews,
  active,
  dynamic,
  approved,
  approvedAt,
  twitter,
  facebook,
  youtube,
  faq,
  mainCategory = { id: '0', title: '' },
}: Item) => {
  return {
    title: title || '',
    shortDescription: shortDescription || '',
    longDescription: longDescription || '',
    free: free || [],
    price: price || 0,
    categories: (categories || []).filter(item => !!item.title),
    thumbnail: thumbnail || [],
    images: images || [],
    icon: icon || [],
    platform: (platform || []) as Platform[],
    company: company || {},
    seoCustom: seoCustom || {},
    skills: skills || [],
    cons: cons || [],
    pros: pros || [],
    tags: tags || [],
    categoryApproved,
    link: link || {
      url: '',
      alternativeUrl: '',
      forceNoFollow: true,
      forceNoIndex: false,
      forceNoOpenner: true,
      targetBlank: true,
    },
    reviews: (reviews || []).map(review => ({
      ...review,
      id: Math.random(),
    })),
    features,
    active: active !== false ? true : active,
    dynamic: dynamic || [],
    iconSetManually,
    approved,
    approvedAt,
    twitter,
    facebook,
    youtube,
    slug,
    slugCustom,
    faq: faq || [],
    mainCategory,
  };
};

export const getUpdateItemPayload = (
  state: Item
): ItemInput & { id: string } => {
  return {
    id: state.id,
    title: state.title,
    shortDescription: state.shortDescription,
    longDescription: state.longDescription,
    icon: state.icon,
    free: state.free,
    platform: state.platform,
    pros: state.pros,
    cons: state.cons,
    link: state.link,
    active: state.active,
    features: getIds(state.features || []),
    iconSetManually: state.iconSetManually || false,
    dynamic: getDynamicModelsPayload(state.dynamic || []) as any,
    price: Number(state.price),
    company: (!_isEmpty(state.company)
      ? getIds(state.company as any)
      : undefined) as string,
    skills: state.skills ? (getIds(state.skills) as string[]) : undefined,
    categories: getIds(state.categories || []) as string[],
    reviews: state.reviews
      ? (state.reviews.map(filterTypeName) as ItemReview[])
      : [],
    seoCustom: filterTypeName(state.seoCustom || {}),
    thumbnail: state.thumbnail,
    images: state.images,
    twitter: state.twitter,
    facebook: state.facebook,
    youtube: state.youtube,
    slug: state.slugCustom ? state.slug : (undefined as any),
    slugCustom: state.slugCustom,
    mainCategory: state.mainCategory,
  };
};
